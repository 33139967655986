import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  Table,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getItemOrder,
  pesanBarang,
  getKategoryCS,
  importStock,
  getStockGudangLt,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import moment from "moment";
function ReactTables() {
  const dispatch = useDispatch();
  const consumable = useSelector((state) => state.consumableReducer);

  const [data, setData] = React.useState([]);
  const [dataStockLt, setDataStockLt] = React.useState([]);
  const [orderedItem, setOrderedItem] = React.useState([]);
  const [listKategori, setListKategori] = React.useState([]);
  const [choosenKategori, setChoosenKategori] = React.useState({
    value: "",
    label: "Choose the Category",
    isDisabled: true,
  });
  const [modal, setModal] = React.useState(false);
  const [dataStock, setDataStock] = React.useState(null);

  function onsubmit(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("file", dataStock);
    importStock(fd);
  }

  React.useEffect(() => {
    let tmp = consumable.items.map((val, index) => {
      return {
        code: val.kode,
        item: val.nama,
        // qty: val.stock,
        satuan: val.satuan,
        brand: val.brand,
        kategori: val.kategori,
        speck: val.spek,
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                let tmp2 = [
                  ...orderedItem,
                  {
                    idItem: val._id,
                    kode: val.kode,
                    nama: val.nama,
                    unit: 1,
                    harga: 0,
                    totalHarga: 0,
                    satuan: val.satuan,
                    kategori: val.kategori,
                    max: val.stock,
                  },
                ];
                setOrderedItem(tmp2);
              }}
              variant="info"
              size="md"
              className="btn-link remove text-info"
            >
              <i className="fas fa-plus fa-lg"></i>
            </Button>{" "}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [consumable.items, orderedItem]);

  React.useEffect(() => {
    let tmp = consumable.stockGudangLt.map((val, index) => {
      return {
        code: val.kode,
        item: val.nama,
        // qty: val.stock,
        satuan: val.satuan,
        brand: val.brand,
        kategori: val.kategori,
        speck: val.spek,
        sisa: val.minStock - val.stock,
        actions: (
          <div className="actions-right">
            {val.status == "Process" ? (
              <Button
                onClick={() => {}}
                variant="success"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fas fa-info-circle fa-lg"></i>
              </Button>
            ) : (
              <Button
                onClick={() => {}}
                variant="dager"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fas fa-info-circle fa-lg"></i>
              </Button>
            )}{" "}
            {/* <Button
              onClick={() => {
                let tmp2 = [
                  ...orderedItem,
                  {
                    idItem: val._id,
                    kode: val.kode,
                    nama: val.nama,
                    unit: 1,
                    harga: 1,
                    totalHarga: 1,
                    satuan: val.satuan,
                    kategori: val.kategori,
                    max: val.stock,
                  },
                ];
                setOrderedItem(tmp2);
              }}
              variant="info"
              size="md"
              className="btn-link remove text-info"
            >
              <i className="fas fa-plus fa-lg"></i>
            </Button>{" "} */}
          </div>
        ),
      };
    });
    setDataStockLt(tmp);
  }, [consumable.stockGudangLt, orderedItem]);

  React.useEffect(() => {
    let tmp = consumable.kategori.map((val, index) => {
      return {
        label: val.nama,
        value: val.nama,
      };
    });
    setListKategori(tmp);
  }, [consumable.kategori]);

  React.useEffect(() => {
    getItemOrder(dispatch);
    getKategoryCS(dispatch);
    getStockGudangLt(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Uploading the Stock
        </Button> */}
        <Row>
          <Col md="12">
            <h4 className="title">Order</h4>
          </Col>
        </Row>
        <Row>
          {dataStockLt.length == 0 ? null : (
            <Col md="6">
              <Card>
                <Card.Body>
                  <ReactTable
                    data={dataStockLt}
                    columns={[
                      {
                        Header: "Code",
                        accessor: "code",
                      },
                      {
                        Header: "Item",
                        accessor: "item",
                      },
                      {
                        Header: "Speck",
                        accessor: "speck",
                      },
                      // {
                      //   Header: "Stock",
                      //   accessor: "qty",
                      // },
                      {
                        Header: "Unit",
                        accessor: "satuan",
                      },
                      {
                        Header: "Category",
                        accessor: "kategori",
                      },
                      {
                        Header: "Must Order",
                        accessor: "sisa",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          )}
          <Col md={dataStockLt.length == 0 ? "12" : "6"}>
            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Code",
                      accessor: "code",
                    },
                    {
                      Header: "Item",
                      accessor: "item",
                    },
                    {
                      Header: "Speck",
                      accessor: "speck",
                    },
                    // {
                    //   Header: "Stock",
                    //   accessor: "qty",
                    // },
                    {
                      Header: "Unit",
                      accessor: "satuan",
                    },
                    {
                      Header: "Category",
                      accessor: "kategori",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>

          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Ordering Form</Card.Title>
              </Card.Header>
              <Card.Body className="table-responsive p-0">
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Qty.</th>
                      <th>Unit</th>
                      <th>Price</th>
                      {/* <th>Total</th>
                      <th>Category</th> */}
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderedItem.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{val.kode}</td>
                          <td>{val.nama}</td>
                          <td>
                            <Form action="#" method="#">
                              <Form.Group>
                                <Form.Control
                                  onChange={(e) => {
                                    setOrderedItem([
                                      ...orderedItem.slice(0, index),
                                      {
                                        ...val,
                                        unit: e.target.value,
                                        totalHarga: parseInt(
                                          orderedItem[index].harga *
                                            e.target.value
                                        ),
                                      },
                                      ...orderedItem.slice(
                                        index + 1,
                                        index.length
                                      ),
                                    ]);
                                  }}
                                  value={val.unit}
                                  type="number"
                                  min={1}
                                  max={Number(val.max)}
                                ></Form.Control>
                              </Form.Group>
                            </Form>
                          </td>
                          <td>{val.satuan}</td>
                          {/* <td>
                            <Form action="#" method="#">
                              <Form.Group>
                                <Form.Control
                                  onChange={(e) => {
                                  
                                    setOrderedItem([
                                      ...orderedItem.slice(0, index),
                                      {
                                        ...val,
                                        harga: e.target.value,
                                        totalHarga: parseInt(
                                          orderedItem[index].unit *
                                            e.target.value
                                        ),
                                      },
                                      ...orderedItem.slice(
                                        index + 1,
                                        index.length
                                      ),
                                    ]);
                                  }}
                                  value={val.harga}
                                  type="number"
                                  min={1}
                                  max={Number(val.max)}
                                ></Form.Control>
                              </Form.Group>
                            </Form>
                          </td> */}
                          {/* <td>
                            {`Rp ${val.totalHarga}`} */}
                          {/* <Form action="#" method="#">
                              <Form.Group>
                                <Form.Control
                                  onChange={(e) => {
                                    setOrderedItem([
                                      ...orderedItem.slice(0, index),
                                      {
                                        ...val,
                                        totalHarga: e.target.value,
                                      },
                                      ...orderedItem.slice(
                                        index + 1,
                                        index.length
                                      ),
                                    ]);
                                  }}
                                  value={val.totalHarga}
                                  type="number"
                                  min={1}
                                  max={Number(val.max)}
                                ></Form.Control>
                              </Form.Group>
                            </Form> */}
                          {/* </td> */}
                          <td>{val.kategori}</td>
                          <td>
                            <Button
                              onClick={() => {
                                setOrderedItem([
                                  ...orderedItem.slice(0, index),
                                  ...orderedItem.slice(index + 1, index.length),
                                ]);
                              }}
                              variant="danger"
                              size="md"
                              className="btn-link remove text-danger"
                            >
                              <i className="fas fa-times fa-lg"></i>
                            </Button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            {orderedItem.length > 0 && (
              <>
                {/* <Select
                  className="react-select primary pb-2"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  onChange={(value) => {
                    setChoosenKategori(value);
                  }}
                  value={choosenKategori}
                  options={[
                    {
                      value: "",
                      label: "Choose the Category",
                      isDisabled: true,
                    },
                    ...listKategori,
                  ]}
                  placeholder="Single Select"
                /> */}
                <Button
                  className="btn-wd mr-1"
                  onClick={() => {
                    let tmp = orderedItem.map((val) => {
                      return {
                        idItem: val.idItem,
                        qty: val.unit,
                        harga: parseInt(val.harga),
                        totalHarga: val.totalHarga,
                      };
                    });
                    pesanBarang({
                      items: JSON.stringify(tmp),
                      // kategori: choosenKategori.value,
                    });
                  }}
                  variant="primary"
                >
                  Submit
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Uploading the Stock
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          {/* <label>File EXCEL</label> */}
                          <Form.Control
                            onChange={(e) => {
                              setDataStock(e.target.files[0]);
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            type="file"
                            id="inputFile"
                            // multiple
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={onsubmit}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
