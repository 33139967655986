import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function createCsVisit(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEnterKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/csvisit/createcsvisit", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      });
    })
    .then(() => {
      window.location.reload();
    })
    .catch(errorHandler);
}

export async function getCsVisit(dispatch, data) {
  try {
    let response = await baseAxios.get(
      `/csvisit/getcsvisit?page=${data.page}&limit=${data.limit}&search=${data.search}&cabang=${data.idCabangWeb}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    );
    dispatch({ type: "SET_GETCSVISIT", data: response.data });
    return response.status;
  } catch (error) {
    return error.response;
  }
}
export async function getCsVisitBelow(dispatch, data) {
  try {
    let response = await baseAxios.get(
      `/csvisit/getcsvisitbelow?page=${data.page}&limit=${data.limit}&search=${data.search}&cabang=${data.idCabangWeb}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    );
    dispatch({ type: "SET_GETCSVISITBELOW", data: response.data });
    return response.status;
  } catch (error) {
    return error.response;
  }
}

export function grafikCsVisit(dispatch, data) {
  baseAxios
    .get(
      `/csvisit/grafikcsvisit?dari=${data.dariTgl}&sampai=${data.smpTgl}&idCabangWeb=${data.idCabangWeb}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_GRAFIKCSVISIT", data: respon.data });
    })
    .catch(errorHandler);
}

export async function followUpCsVisit(data) {
  try {
    const response = await baseAxios.patch("/csvisit/followupcsvisit", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    });

    return response.data;
  } catch (err) {
    return err.response;
  }
}
export async function editFollowUpCsVisit(data) {
  try {
    const response = await baseAxios.patch(
      "/csvisit/editfollowupcsvisit",
      data,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    );

    return response.data;
  } catch (err) {
    return err.response;
  }
}
export async function approvalfollowup(data) {
  try {
    const response = await baseAxios.patch("/csvisit/approvalfollowup", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    });

    return response.data;
  } catch (err) {
    return err.response;
  }
}
