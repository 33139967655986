import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";

import "react-vertical-timeline-component/style.min.css";

// Loading
import { ColorRing } from "react-loader-spinner";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getTraining,
  getCabangAktif,
  getMonthlyReport,
  createMonthlyReport,
  getClient,
  showUrlDokumen,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Swal from "sweetalert2";
import { compose } from "redux";

function ReactTables() {
  const dispatch = useDispatch();
  const date = new Date();
  const monthlyReport = useSelector((state) => state.monthlyReportReducer);
  const monitoringReducer = useSelector((state) => state.monitoringReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const [modal, setModal] = React.useState(false);
  const [modalTraining, setModalTraining] = React.useState(false);
  const [dataReport, setDataReport] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  const [dariTgl, setDariTgl] = React.useState(new Date().setHours(0, 0, 0, 0));
  const [modalPreviewFoto, setModalPreviewFoto] = React.useState(false);
  const [gambar, setGambar] = React.useState([]);

  const [client, setClient] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });

  const [listCabang, setListCabang] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  function submitMonthyReport(e) {
    e.preventDefault();
    if (
      !client.idClient ||
      !gambar ||
      !deskripsi ||
      gambar.length === 0
      //  ||
      // !file ||
      // file.length === 0
    ) {
      Swal.fire({
        title: "Please complete the form!",
        icon: "error",
      });
    } else {
      const maxFileSize = 4 * 1024 * 1024;

      let idCabang = client.idCabang;
      let fd = new FormData();
      fd.append("idCabang", idCabang);
      fd.append("idClient", client.idClient);
      fd.append("deskripsi", deskripsi);
      fd.append("tanggalLaporan", new Date(dariTgl).toISOString());
      gambar.map((val, index) => [fd.append("buktiLaporan", val)]);
      // file.map((val, index) => [fd.append("fileMonthly", val)]);
      createMonthlyReport(fd);
    }
  }

  function handleOpenModal(e) {
    setClient({
      value: "",
      label: "Choose the Client",
      isDisabled: true,
    });
    setGambar([]);
    setDariTgl(new Date());
  }
  React.useEffect(() => {
    getCabangAktif(dispatch);
    getMonthlyReport(dispatch);
    getClient(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = cabang.getClient.map((val, index) => {
      return {
        ...val,
        label: `${val.namaClient}`,
        value: val.idClient,
      };
    });
    setListCabang(tmp);
  }, [cabang.getClient]);
  React.useEffect(() => {
    let tmp = monthlyReport.getReport.map((val, index) => {
      return {
        ...val,
        tanggalLaporan: moment(val.solvedDate).format("DD MMM YYYY"),
        actions: (
          <div className="actions-right">
            <Button
              onClick={async () => {
                setModal(true);
                setDataReport(val);

                // Show Image
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 2000);
                // Map buktiLaporan untuk mendapatkan URL gambar
                const urlImageData = val.buktiLaporan.map((bukti) => ({
                  imageUrl: bukti, // Sesuaikan jika key-nya berbeda
                }));

                try {
                  const response = await showUrlDokumen(dispatch, {
                    urlImage: urlImageData,
                  });

                  const newResponse = response?.data?.url.map(
                    (newImg) => newImg.url
                  );
                  if (newResponse) {
                    // Perbarui dataReport dengan URL dari respons
                    setDataReport((prevData) => ({
                      ...prevData,
                      urlPreview: newResponse, // Sesuaikan key jika berbeda
                    }));
                  }
                } catch (error) {
                  console.error("Error fetching document URL:", error);
                }
              }}
              variant="danger"
              size="md"
              className="btn-link remove text-success"
            >
              <i className="fa fa-info-circle fa-lg" />
            </Button>
          </div>
        ),
      };
    });
    setData(tmp);
  }, [monthlyReport.getReport]);

  const history = useHistory();

  React.useEffect(() => {
    getTraining(dispatch);
  }, []);

  return (
    <>
      <div
        id="previewImage"
        style={{
          display: modalPreviewFoto ? "block" : "none",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: "9999",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalPreviewFoto}
          alt="Preview"
          style={{
            borderRadius: "5px",
            boxShadow: "4px 4px 8px 0px rgba(0,0,0,0.75)",
            maxHeight: "80%",
            maxWidth: "80%",
            objectFit: "contain",
            background: "transparent",
            position: "absolute",
            top: "50%", // Posisi vertikal ke tengah
            left: "50%", // Posisi horizontal ke tengah
            transform: "translate(-50%, -50%)",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => setModalPreviewFoto("")}
        >
          &times;
        </button>
      </div>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalTraining(true);
            handleOpenModal();
          }}
          variant="primary"
        >
          Add Report
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">Monthly Report Recapitulation</h4>
            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Submitter",
                      accessor: "dilaporkanOleh",
                    },
                    {
                      Header: "Reported Date",
                      accessor: "tanggalLaporan",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },

                    // {
                    //   Header: "Branch Name",
                    //   accessor: "namaCabang",
                    // },
                    {
                      Header: "Client Name",
                      accessor: "namaClient",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setStatus("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <div style={{ maxHeight: "800px", overflowY: "scroll" }}>
          <Modal.Body>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Submitter
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.dilaporkanOleh}</Col>
                      </Row>

                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Reported Date
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {moment(dataReport.solvedDate).format("DD MMM YYYY")}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Status
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.status}</Col>
                      </Row>
                      {/* <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Branch
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.namaCabang}</Col>
                      </Row> */}
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Client
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.namaClient}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Description
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.deskripsi}</Col>
                      </Row>
                      {/* <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          File Monthly Report
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {dataReport.fileMonthly?.map((val, index) => {
                            return (
                              <>
                                <div className="clearfix"></div>
                                <Button
                                  className="btn-fill pull-right btn-sm"
                                  type="submit"
                                  variant="info"
                                  href={val}
                                >
                                  <i
                                    className="fa fa-download"
                                    aria-hidden="true"
                                    style={{ marginRight: 5 }}
                                  ></i>
                                  Download File
                                </Button>
                                <div className="clearfix"></div>
                              </>
                            );
                          })}
                        </Col>
                      </Row> */}
                      <Row style={{ marginTop: 40 }}>
                        <Col
                          sm="12"
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}
                        >
                          Documentation
                        </Col>
                      </Row>
                      <Row>
                        {!dataReport.urlPreview ||
                        dataReport.urlPreview.length == 0 ? (
                          <Col sm="12" style={{ textAlign: "center" }}>
                            Final Documentation is Not Available
                          </Col>
                        ) : loading ? (
                          <div
                            className="loader-container"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            <ColorRing
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="color-ring-loading"
                              wrapperStyle={{}}
                              wrapperClass="color-ring-wrapper"
                              colors={[
                                "#6c757d",
                                "#6c757d",
                                "#6c757d",
                                "#6c757d",
                                "#6c757d",
                              ]}
                            />
                            <p
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: 19,
                              }}
                            ></p>
                          </div>
                        ) : (
                          dataReport.urlPreview.map((val, index) => {
                            return (
                              <>
                                <Col sm="4">
                                  <Image
                                    src={val}
                                    style={{
                                      width: 200,
                                      height: 250,
                                      margin: 20,
                                    }}
                                    thumbnail
                                    onClick={() => {
                                      setModalPreviewFoto(val);
                                    }}
                                  />
                                </Col>
                              </>
                            );
                          })
                        )}
                      </Row>
                      {/* <Row>
                        {!dataReport.dokumentasi ||
                        dataReport.dokumentasi.length == 0 ? (
                          <Col sm="12" style={{ textAlign: "center" }}>
                            Final Documentation is Not Available
                          </Col>
                        ) : (
                          dataReport.dokumentasi.map((val, index) => {
                            return (
                              <>
                                <Col sm="4">
                                  <Image
                                    src={val}
                                    style={{
                                      width: 200,
                                      height: 250,
                                      margin: 20,
                                    }}
                                    thumbnail
                                    onClick={() => {
                                      setModalPreviewFoto(val);
                                    }}
                                  />
                                </Col>
                              </>
                            );
                          })
                        )}
                      </Row> */}
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        size="lg"
        show={modalTraining}
        onHide={() => {
          setModalTraining(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Add Monthly Report</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Client's Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setClient(value);
                            }}
                            value={client}
                            options={[
                              {
                                value: "",
                                label: "Choose the Client",
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Reported Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate());
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group>
                      <label>Description</label>
                      <Form.Control
                        onChange={(e) => {
                          setDeskripsi(e.target.value);
                          // setProject({
                          //   ...project,
                          //   deskripsi: e.target.value,
                          // });
                        }}
                        // placeholder="Tuliskan Deskripsi Project"
                        as="textarea"
                      ></Form.Control>
                    </Form.Group>

                    <label>Documentation</label>
                    <br />
                    {gambar.length === 0 ? (
                      <>
                        <label
                          className="label-input-file btn btn-danger text-white mb-4"
                          for="import2"
                        >
                          Add Photo
                          <input
                            type="file"
                            id="import2"
                            onClick={(e) => {
                              e.target.value = "";
                            }}
                            onChange={(e) => {
                              setGambar([
                                ...gambar,
                                ...Array.from(e.target.files),
                              ]);
                            }}
                            style={{ display: "none" }}
                            accept="image/*"
                            multiple
                          />
                        </label>
                      </>
                    ) : null}
                    {gambar.map((val, index) => {
                      return (
                        <div className="d-flex align-items-start">
                          <p>{val.name}</p>
                          <Button
                            onClick={() => {
                              setGambar([
                                ...gambar.slice(0, index),
                                ...gambar.slice(index + 1, gambar.length),
                              ]);
                            }}
                            variant="danger"
                            size="sm"
                            className="btn-link remove text-danger"
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </div>
                      );
                    })}
                    <div className="clearfix"></div>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitMonthyReport}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
