import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function createCsReview(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEnterKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/csreview/createcsreview", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      });
    })
    .then(() => {
      window.location.reload();
    })
    .catch(errorHandler);
}

export function getCsReview(dispacth, data) {
  baseAxios
    .get("csreview/getcsreview", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispacth({ type: "SET_GETCSREVIEW", data: respon.data });
    });
}

export function grafikCsReview(dispatch, data) {
  baseAxios
    .get(
      `/csreview/grafikcsreview?dari=${data.dariTgl}&sampai=${data.smpTgl}&idClientWeb=${data.idClientWeb}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_GRAFIKCSREVIEW", data: respon.data });
    })
    .catch(errorHandler);
}
