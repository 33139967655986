import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getAbsenAll,
  tambahAbsensi,
  importAbsenKaryawan,
  getCabangAktif,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function ReactTables() {
  const dispatch = useDispatch();
  const absen = useSelector((state) => state.absenReducer);
  const [data, setData] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [dataAbsen, setDataAbsen] = React.useState(null);
  const [masuk, setMasuk] = React.useState(0);
  const [tidakMasuk, setTidakMasuk] = React.useState(0);
  const [dariTgl, setDariTgl] = React.useState(0);
  const [validationDate, setValidationDate] = React.useState(false);
  const [validationFile, setValidationFile] = React.useState(false);
  const [lisCabang, setListCabang] = React.useState([]);
  const history = useHistory();
  const [idCabang, setIdCabang] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  React.useEffect(() => {
    let tmp = absen.listAbsen.map((val, index) => {
      return {
        ...val,
        deskripsi: val.keterangan ? val.keterangan : "-",
        tanggalOut: val.tanggalOut ? val.tanggalOut : "-",
        checkOut: val.checkOut ? val.checkOut : "-",
        // tgl: moment(val.tanggal).format("DD MMM YYYY"),
        // masuk: val.masuk.toString(),
        // tidakMasuk: val.tidakMasuk.toString(),
      };
    });
    setData(tmp);
  }, [absen.listAbsen]);

  React.useEffect(() => {
    getAbsenAll(dispatch);
  }, []);

  function onsubmit(e) {
    e.preventDefault();
    if (dariTgl === 0) {
      setValidationDate(true);
    } else {
      setValidationDate(false);
    }
    if (dataAbsen === null || dataAbsen === undefined) {
      setValidationFile(true);
    } else {
      setValidationFile(false);
    }

    if (dariTgl != 0 && (dataAbsen != null || dataAbsen != undefined)) {
      let fd = new FormData();
      fd.append("file", dataAbsen);

      importAbsenKaryawan(fd);
    }
  }
  // function submit(e) {
  //   e.preventDefault();
  //   tambahAbsensi({
  //     masuk: masuk,
  //     tidakMasuk: tidakMasuk,
  //     tanggal: moment(new Date()).format("DD MMM YYYY"),
  //   });
  // }
  return (
    <>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Uploading the Attendance
        </Button> */}
        <Row>
          <Col md="12">
            <h4 className="title">Attendance</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Nopeg",
                      accessor: "nopeg",
                    },
                    {
                      Header: "Name",
                      accessor: "nama",
                    },
                    {
                      Header: "Check-In Date",
                      accessor: "tanggalIn",
                    },
                    {
                      Header: "Check-In Time",
                      accessor: "checkIn",
                    },
                    {
                      Header: "Check-Out Date",
                      accessor: "tanggalOut",
                    },
                    {
                      Header: "Check-Out Time",
                      accessor: "checkOut",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Client Name",
                      accessor: "client",
                    },
                    {
                      Header: "Branch Name",
                      accessor: "cabang",
                    },
                    {
                      Header: "Description",
                      accessor: "deskripsi",
                    },
                    {
                      Header: "",
                      accessor: "action",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setValidationDate(false);
          setValidationFile(false);
          setDariTgl(0);
          setDataAbsen(null);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Uploading the Attendance
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <h5 className="title">Select Date Attendance</h5>
                          <Form.Group>
                            <ReactDatetime
                              inputProps={{
                                className: "form-control",
                                placeholder: "Date Picker Here",
                              }}
                              onChange={(e) => {
                                setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                              }}
                              value={dariTgl}
                              initialValue={dariTgl}
                              timeFormat={false}
                            ></ReactDatetime>
                            {validationDate ? (
                              <div style={{ fontSize: 12, color: "red" }}>
                                *Required
                              </div>
                            ) : null}
                          </Form.Group>
                          <h5 className="title">Select File Attendance</h5>
                          <Form.Control
                            onChange={(e) => {
                              setDataAbsen(e.target.files[0]);
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            type="file"
                            id="inputFile"
                            // accept=".csv"
                            // multiple
                          ></Form.Control>
                          {validationFile ? (
                            <div style={{ fontSize: 12, color: "red" }}>
                              *Required
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col>
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={onsubmit}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal> */}

      {/* <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add Presence</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Presence</label>
                          <Form.Control
                            onChange={(e) => {
                              setMasuk(e.target.value);
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="number"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Absence</label>
                          <Form.Control
                            onChange={(e) => {
                              setTidakMasuk(e.target.value);
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="number"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submit}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default ReactTables;
