import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getSidak(dispatch) {
  baseAxios
    .get(`/sidak/getsidakall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTSIDAK", data: respon.data });
    })
    .catch(errorHandler);
}

export function getIssue(dispatch) {
  baseAxios
    .get(`/responindustrial/getissueall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTISSUE", data: respon.data });
    })
    .catch(errorHandler);
}

export function getKpi1Sidak(dispatch, data) {
  baseAxios
    .get(
      `/sidak/getsidakgrafik?dari=${data.dariTgl}&sampai=${data.smpTgl}&idCabangWeb=${data.idCabangWeb}&idClientWeb=${data.idClientWeb}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI1SIDAK", data: respon.data });
    })
    .catch(errorHandler);
}
// export function (dispatch, data) {
//   baseAxios
//     .get(
//       `/sidak/ceksidak?dari=${data.dariTgl}&idCabangWeb=${data.idCabangWeb}`,
//       {
//         headers: { token: localStorage.getItem("tokenjti") },
//       }
//     );
//     tr
//     then(async (respon) => {
//       dispatch({ type: "SET_CEKSIDAK", data: respon.data });
//     })
//     .catch(errorHandler);
// }
export async function cekSidak(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `/sidak/ceksidak?dari=${data.dariTgl}&sampai=${data.smpTgl}&idCabangWeb=${data.idCabangWeb}`,

      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    );
    dispatch({ type: "SET_CEKSIDAK", data: response.data });

    return response;
  } catch (err) {
    return err.response;
  }
}

export function getKpi2ResponIssue(dispatch, data) {
  baseAxios
    .get(
      `/responindustrial/getresponissuegrafik?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI2RESPONISSUE", data: respon.data });
    })
    .catch(errorHandler);
}

export async function tambahSidak(data) {
  try {
    const response = await baseAxios.post("/sidak/ajukansidak", data, {
      headers: {
        token: localStorage.getItem("tokenjti"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export function editSidak(data) {
  baseAxios
    .patch(`/sidak/ajukansidakulang`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export async function followUpSidak(data) {
  try {
    const response = await baseAxios.patch("/sidak/followupsidak", data, {
      headers: {
        token: localStorage.getItem("tokenjti"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export function tambahIssue(data) {
  baseAxios
    .post(`/responindustrial/ajukanissue`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getCalendarSidak(dispatch) {
  baseAxios
    .get("/sidak/getcalendarsidak", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_CALENDAR", data: respon.data });
    })
    .catch(errorHandler);
}

export function kirimReportInspection(data) {
  if (!data.noteInspection || data.gambar.length === 0) {
    Swal.fire({
      title: "Please complete the form!",
      icon: "error",
    });
  } else {
    Swal.fire({
      title: "Loading ...",
      didOpen() {
        Swal.showLoading();
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
    let formData = new FormData();

    formData.append("keterangan", data.noteInspection);
    formData.append("sidakId", data.sidakId);
    formData.append("respon", data.respon);
    formData.append("tglPelaksanaan", data.tglPelaksanaan);
    data.gambar.map((val, index) => {
      formData.append("buktiSelesai", val);
    });
    baseAxios
      .patch(`/sidak/responsidak`, formData, {
        headers: { token: localStorage.getItem("tokenjti") },
      })
      .then(async (respon) => {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(errorHandler);
  }
}
