import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Modal,
  Table,
  Container,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { addShift, getAllReportShift } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import TagsInput from "components/TagsInput/TagsInput.js";
import moment from "moment";

function RegularTables() {
  const dispatch = useDispatch();
  const [modal, setModal] = React.useState(false);
  const [dataReport, setDataReport] = React.useState([]);
  const [data, setData] = React.useState([]);
  const listShift = useSelector((state) => state.shiftReportReducer);

  React.useEffect(() => {
    let tmp = listShift.listReportShift.map((val, index) => {
      return {
        ...val,
        tanggalCreate: moment(val._id.tanggalRoomDibuat).format("DD MMM YYYY "),
        nama: val.data[0].namaKaryawan,
        client: val.data[0].namaClient,
        cabang: val.data[0].namaCabang,
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                setModal(true);
                setDataReport(val.data);
              }}
              variant="info"
              size="md"
              className="btn-link remove text-info"
            >
              <i className="fa fa-info-circle fa-lg" />
            </Button>{" "}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [listShift.listReportShift]);
  React.useEffect(() => {
    getAllReportShift(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Report Shift</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Shift",
                      accessor: "_id.namaShift",
                    },
                    {
                      Header: "Name",
                      accessor: "nama",
                    },
                    {
                      Header: "Date",
                      accessor: "tanggalCreate",
                    },
                    {
                      Header: "Client Name",
                      accessor: "client",
                    },
                    {
                      Header: "Branch Name",
                      accessor: "cabang",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
            {/* {dataReport[0].namaShift} */}
            {/* <br /> */}
            {/* {moment(dataReport[0].tanggalDibuat).format("DD MMM YYYY ")} */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  {/* <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Detail Problem</Card.Title>
                    </Card.Header>
                  </Card.Header> */}
                  <Card.Body>
                    {dataReport.map((val, index) => {
                      return (
                        <>
                          {/* <Row>
                            <Col sm="2">Branch Name</Col>
                            <Col sm="0.5">:</Col>
                            <Col sm="9">{val.namaLokasi}</Col>
                          </Row> */}
                          <Row>
                            <Col sm="2">Description</Col>
                            <Col sm="0.5">:</Col>
                            <Col sm="9">{val.deskripsi}</Col>
                          </Row>
                          <Row>
                            <Col sm="2">Time</Col>
                            <Col sm="0.5">:</Col>
                            <Col sm="9">
                              {moment(new Date(val.tanggalLaporan)).format(
                                "hh:mm"
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="2">Validation</Col>
                            <Col sm="0.5">:</Col>
                            <Col sm="9">
                              {val.validasi === true ? "Valid" : "Not Valid"}
                            </Col>
                          </Row>
                          <Row style={{ marginTop: 20 }}>
                            <Col
                              sm="12"
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              User Documentation
                            </Col>
                          </Row>
                          <Row>
                            {val.dokumentasiAwalArr.map((data, index) => {
                              return (
                                <>
                                  <Col sm="4">
                                    {/* <h5>{data}</h5> */}
                                    <Image
                                      src={data}
                                      style={{
                                        width: 200,
                                        height: 250,
                                        margin: 20,
                                      }}
                                      thumbnail
                                    />
                                  </Col>
                                </>
                              );
                            })}
                          </Row>
                          <hr />
                        </>
                      );
                    })}
                    {/* <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Building
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTemuan.gedung}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Area
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTemuan.area}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Jenis Temuan
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTemuan.jenisTemuan}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTemuan.deskripsi}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Submitter
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTemuan.diajukanOleh}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Report Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataTemuan.startDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Deadline
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataTemuan.deadlineDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataTemuan.status}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        On Schedule
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {dataTemuan.status != "Done"
                          ? "-"
                          : dataTemuan.solvedInTime
                          ? "Ya"
                          : "Tidak"}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Previous Documentation
                      </Col>
                    </Row>
                    <Row>
                      {!dataTemuan.dokumentasiAwalArr ||
                      dataTemuan.dokumentasiAwalArr.length == 0 ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Previous Documentation is Not Available
                        </Col>
                      ) : (
                        dataTemuan.dokumentasiAwalArr.map((val, index) => {
                          return (
                            <>
                              <Col sm="4">
                                <Image
                                  src={val}
                                  style={{
                                    width: 200,
                                    height: 250,
                                    margin: 20,
                                  }}
                                  thumbnail
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>
                    {dataTemuan.status == "On Progress" ? null : (
                      <>
                        <hr />
                        <Row style={{ marginTop: 30 }}>
                          <Col
                            sm="12"
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            Final Documentation
                          </Col>
                        </Row>
                        <Row>
                          {!dataTemuan.dokumentasiAkhirArr ||
                          dataTemuan.dokumentasiAkhirArr.length == 0 ? (
                            <Col sm="12" style={{ textAlign: "center" }}>
                              Final Documentation is Not Available
                            </Col>
                          ) : (
                            dataTemuan.dokumentasiAkhirArr.map((val, index) => {
                              return (
                                <>
                                  <Col sm="4">
                                    <Image
                                      src={val}
                                      style={{
                                        width: 200,
                                        height: 250,
                                        margin: 20,
                                      }}
                                      thumbnail
                                    />
                                  </Col>
                                </>
                              );
                            })
                          )}
                        </Row>
                      </>
                    )}
                    <div className="clearfix"></div> */}
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RegularTables;
