import React from "react";
// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
// react component used to create alerts
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { getPersonnel, getCabangAktif } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";

const localizer = momentLocalizer(moment);

const today = new Date();
const y = today.getFullYear();
const m = today.getMonth();
const d = today.getDate();

function Calendar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.authReducer);
  const absen = useSelector((state) => state.absenReducer);
  const cabangAktif = useSelector((state) => state.cabangReducer);
  const [alert, setAlert] = React.useState(null);
  const [events, setEvents] = React.useState([
    // {
    //   title: "All Day Event",
    //   allDay: true,
    //   start: new Date(y, m, 1),
    //   end: new Date(y, m, 1),
    //   color: "default",
    // },
    // {
    //   title: "Meeting",
    //   start: new Date(y, m, d - 1, 10, 30),
    //   end: new Date(y, m, d - 1, 11, 30),
    //   allDay: false,
    //   color: "green",
    // },
    // {
    //   title: "Lunch",
    //   start: new Date(y, m, d + 7, 12, 0),
    //   end: new Date(y, m, d + 7, 14, 0),
    //   allDay: false,
    //   color: "red",
    // },
    // {
    //   title: "Nud-pro Launch",
    //   start: new Date(y, m, d - 2),
    //   end: new Date(y, m, d - 2),
    //   allDay: true,
    //   color: "azure",
    // },
    // {
    //   title: "Birthday Party",
    //   start: new Date(y, m, d + 1, 19, 0),
    //   end: new Date(y, m, d + 1, 22, 30),
    //   allDay: false,
    //   color: "azure",
    // },
    // {
    //   title: "Click for Creative Tim",
    //   start: new Date(y, m, 21),
    //   end: new Date(y, m, 22),
    //   color: "orange",
    // },
    // {
    //   title: "Click for Google",
    //   start: new Date(y, m, 21),
    //   end: new Date(y, m, 22),
    //   color: "orange",
    // },
  ]);
  const [newCalendar, setNewCalendar] = React.useState([]);
  const [listCabang, setListCabang] = React.useState([]);
  const colorCalendar = ["red", "green", "orange"];

  React.useEffect(() => {
    getPersonnel(dispatch);
    getCabangAktif(dispatch);
    // getCalendarPatroli(dispatch);
  }, []);
  React.useEffect(() => {
    let tmp = cabangAktif.getCabangAktif.map((val, index) => {
      return {
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabangAktif.getCabangAktif]);

  React.useEffect(() => {
    let tmp = absen.getPersonnel.map((val, index) => {
      let date = new Date(val.tgl);
      let y = date.getFullYear();
      let m = date.getMonth();
      let d = date.getDate();
      return {
        id: val.id,
        title: val.jumlah,
        allDay: true,
        start: new Date(val.tgl),
        end: new Date(y, m, d + 1),
        color: colorCalendar[index % colorCalendar.length],
      };
    });
    setNewCalendar(tmp);
  }, [absen.getPersonnel]);

  const selectedEvent = (event) => {
    // window.alert(event.title);
    // deletedJadwalPatroli({
    //   idJadwal: event.id,
    //   nama: event.title,
    // });
  };
  const addNewEventAlert = (slotInfo) => {
    setAlert(
      <SweetAlert
        input
        inputType="number"
        showCancel
        validationMsg="You must enter Employees!"
        style={{ display: "block", marginTop: "-100px" }}
        title="Personnel"
        onConfirm={(e) => addNewEvent(e, slotInfo)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
      />
    );
  };
  const addNewEvent = (e, slotInfo) => {
    let year = new Date(slotInfo.start).getFullYear();
    let month =
      new Date(slotInfo.start).getMonth() + 1 < 10
        ? "0" + (new Date(slotInfo.start).getMonth() + 1)
        : new Date(slotInfo.start).getMonth() + 1;
    let date =
      new Date(slotInfo.start).getDate() < 10
        ? "0" + new Date(slotInfo.start).getDate()
        : new Date(slotInfo.start).getDate();
    createPersonnsel({
      jumlah: e,
      tgl: `${year}-${month}-${date}`,
    });
    // var newEvents = events;
    // newEvents.push({
    //   title: e,
    //   start: slotInfo.start,
    //   end: slotInfo.end,
    // });
    // setEvents(newEvents);
    setAlert(null);
  };
  const eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "rbc-event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };
  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <>
      {alert}
      <Container fluid>
        <div className="header text-center">
          <h3 className="title">Setting Personnel</h3>
        </div>
        <br></br>
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <Card className="card-calendar">
              <Card.Body className="p-0">
                <BigCalendar
                  selectable
                  localizer={localizer}
                  events={newCalendar}
                  defaultView="month"
                  //   scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={(event) => selectedEvent(event)}
                  onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
                  eventPropGetter={eventColors}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Calendar;
