import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getTargetByMonth(dispatch, data) {
  baseAxios
    .get(
      `/target/gettargetbymonth?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_GETTARGETBYMONTH", data: respon.data });
    })
    .catch(errorHandler);
}
export function getTargetByMonthCabang(dispatch, data) {
  baseAxios
    .get(
      `/target/gettargetbymonthcabang?dari=${data.dariTgl}&sampai=${data.smpTgl}&idCabangWeb=${data.idCabang}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_GETTARGETBYMONTHCABANG", data: respon.data });
    })
    .catch(errorHandler);
}

export function editTargetCabang(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .patch("/laporanpatroli/updatetargetpatroli", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getTargetAll(dispatch, data) {
  baseAxios
    .get(`/target/gettarget`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_GETTARGETALL", data: respon.data });
    }).catch;
}

export function createTarget(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/laporanpatroli/createtargetpatroli", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getAchievementPatroli(dispatch, data) {
  baseAxios
    .get(
      `/target/getachievementdashboard?dari=${data.dariTgl}&sampai=${data.smpTgl}&idCabangWeb=${data.idCabang}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_GETACHIEVEMENTPATROLI", data: respon.data });
    })
    .catch(errorHandler);
}
export function getAchievementShift(dispatch, data) {
  baseAxios
    .get(
      `/target/getachievementlaporanshiftdashboard?dari=${data.dariTgl}&sampai=${data.smpTgl}&idCabangWeb=${data.idCabang}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_GETACHIEVEMENTSHIFT", data: respon.data });
    })
    .catch(errorHandler);
}
