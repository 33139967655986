import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  createCsReview,
  getTraining,
  getCabangAktif,
  getMonthlyReport,
  getCsReview,
  getClient,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Swal from "sweetalert2";
import { compose } from "redux";

function ReactTables() {
  const dispatch = useDispatch();
  const date = new Date();
  const csReview = useSelector((state) => state.csReviewReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const [modal, setModal] = React.useState(false);
  const [modalTraining, setModalTraining] = React.useState(false);
  const [dataCsReview, setDataCsReview] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  const [dariTgl, setDariTgl] = React.useState(new Date().setHours(0, 0, 0, 0));
  const [modalPreviewFoto, setModalPreviewFoto] = React.useState(false);
  const [gambar, setGambar] = React.useState([]);
  const [client, setClient] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });

  const [listCabang, setListCabang] = React.useState([]);
  const [nilai, setNilai] = React.useState({
    value: "",
    label: "Choose the Score",
    isDisabled: true,
  });
  const [kategori, setKategori] = React.useState({
    value: "",
    label: "Choose the Category",
    isDisabled: true,
  });
  let tanggalSekarang = new Date();

  function submitCsReview(e) {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure you want to add this CS Review?",
      // text: "Are you sure you want to add this CS Review?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        if (
          !client.idClient ||
          !kategori.value ||
          !kategori.value ||
          !deskripsi
        ) {
          Swal.fire({
            title: "Please complete the form!",
            icon: "error",
          });
        } else {
          // for (let i = 0; i < file.length; i++) {
          //   if (file[i].size > maxFileSize) {
          //     Swal.fire({
          //       title: "Ukuran file terlalu besar, ukuran maksimum 4MB!!!",
          //       icon: "error",
          //     });
          //     return;
          //   }
          // }

          // let fd = new FormData();
          // fd.append("idCabang", idCabang);
          // fd.append("idClient", client.idClient);
          // fd.append("namaCabang", client.namaCabang);
          // fd.append("namaClient", client.namaClient);
          // // fd.append("temuan", JSON.stringify(temuan));
          // fd.append("tanggal", new Date(dariTgl).toISOString());
          createCsReview({
            idClient: client.idClient,
            namaClient: client.namaClient,
            kategori: kategori.value,
            nilai: nilai.value,
            tanggal: new Date(dariTgl).toISOString(),
            deskripsi: deskripsi,
          });
        }
      }
    });
  }

  function handleOpenModal(e) {
    setClient({
      value: "",
      label: "Choose the Client",
      isDisabled: true,
    });
    setGambar([]);
    setDariTgl(new Date());
  }
  React.useEffect(() => {
    getCabangAktif(dispatch);
    getCsReview(dispatch);
    getClient(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = cabang.getClient.map((val, index) => {
      return {
        ...val,
        label: `${val.namaClient}`,
        value: val.idClient,
      };
    });
    setListCabang(tmp);
  }, [cabang.getClient]);
  React.useEffect(() => {
    let tmp = csReview.getCsReview?.map((val, index) => {
      return {
        ...val,
        tanggal: moment(val.tanggalLaporan).format("DD MMM YYYY"),
        status: val.nilai === 3 ? "Exceed" : val.nilai === 2 ? "Meet" : "Below",
        kategori:
          val.kategori === "1"
            ? "Keep it Simple"
            : val.kategori === "2"
            ? "Be Accountable"
            : val.kategori === "3"
            ? "Be Responsive"
            : val.kategori === "4"
            ? "Be Proactive"
            : "Well Trained",
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                setModal(true);
                setDataCsReview(val);
              }}
              variant="danger"
              size="md"
              className="btn-link remove text-success"
            >
              <i className="fa fa-info-circle fa-lg" />
            </Button>{" "}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [csReview.getCsReview]);

  return (
    <>
      <style jsx>{`
        .btn-icon-hover {
          transition: transform 0.3s ease, color 0.3s ease;
        }

        .btn-icon-hover:hover {
          transform: scale(1.2); /* Membesarkan ikon */
          color: #17a2b8; /* Mengubah warna ikon */
        }

        .btn-icon-hover i {
          transition: color 0.3s ease; /* Animasi perubahan warna */
        }

        .btn-icon-hover:hover i {
          color: #0056b3; /* Mengubah warna ikon saat hover */
        }
      `}</style>

      <div
        id="previewImage"
        style={{
          display: modalPreviewFoto ? "block" : "none",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: "9999",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalPreviewFoto}
          alt="Preview"
          style={{
            borderRadius: "5px",
            boxShadow: "4px 4px 8px 0px rgba(0,0,0,0.75)",
            maxHeight: "80%",
            maxWidth: "80%",
            objectFit: "contain",
            background: "transparent",
            position: "absolute",
            top: "50%", // Posisi vertikal ke tengah
            left: "50%", // Posisi horizontal ke tengah
            transform: "translate(-50%, -50%)",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => setModalPreviewFoto("")}
        >
          &times;
        </button>
      </div>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalTraining(true);
            handleOpenModal();
          }}
          variant="primary"
        >
          Add Review
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">CS Review</h4>
            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Category",
                      accessor: "kategori",
                    },
                    {
                      Header: "Submitter",
                      accessor: "dilaporkanOleh",
                    },
                    {
                      Header: "Date",
                      accessor: "tanggal",
                    },
                    {
                      Header: "grade",
                      accessor: "nilai",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },

                    {
                      Header: "Client Name",
                      accessor: "namaClient",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setStatus("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <div style={{ maxHeight: "800px", overflowY: "scroll" }}>
          <Modal.Body>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Category
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {dataCsReview.kategori === "1"
                            ? "Keep it Simple"
                            : dataCsReview.kategori === "2"
                            ? "Be Accountable"
                            : dataCsReview.kategori === "3"
                            ? "Be Responsive"
                            : dataCsReview.kategori === "4"
                            ? "Be Proactive"
                            : "Well Trained"}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Submitter
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataCsReview.dilaporkanOleh}</Col>
                      </Row>

                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Reported Date
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {moment(dataCsReview.tanggalLaporan).format(
                            "DD MMM YYYY"
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Client
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataCsReview.namaClient}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Grade
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataCsReview.nilai}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Status
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {dataCsReview.nilai === 3
                            ? "Exceed"
                            : dataCsReview.nilai === 2
                            ? "Meet"
                            : "Below"}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Description
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {/* <ul> */}
                          {dataCsReview.deskripsi}
                          {/* </ul> */}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        size="lg"
        show={modalTraining}
        onHide={() => {
          setModalTraining(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Add Review</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Client's Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setClient(value);
                            }}
                            value={client}
                            options={[
                              {
                                value: "",
                                label: "Choose the Client",
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate());
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Choose the Category</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setKategori(value);
                            }}
                            value={kategori}
                            options={[
                              {
                                value: "",
                                label: "Choose the Category",
                                isDisabled: true,
                              },

                              {
                                value: "2",
                                label: "Be Accountable",
                              },
                              {
                                value: "4",
                                label: "Be Proactive",
                              },
                              {
                                value: "3",
                                label: "Be Responsive",
                              },

                              {
                                value: "1",
                                label: "Keep it Simple",
                              },
                              {
                                value: "5",
                                label: "Well Trained",
                              },
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Score</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setNilai(value);
                            }}
                            value={nilai}
                            options={[
                              {
                                value: "",
                                label: "Choose the Score",
                                isDisabled: true,
                              },
                              {
                                value: "1",
                                label: "1 (Below)",
                              },
                              {
                                value: "2",
                                label: "2 (Meet)",
                              },
                              {
                                value: "3",
                                label: "3 (Exceed)",
                              },

                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group>
                      <label>Description</label>
                      <Form.Control
                        onChange={(e) => {
                          setDeskripsi(e.target.value);
                          // setProject({
                          //   ...project,
                          //   deskripsi: e.target.value,
                          // });
                        }}
                        // placeholder="Tuliskan Deskripsi Project"
                        as="textarea"
                      ></Form.Control>
                    </Form.Group>

                    <div className="clearfix"></div>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitCsReview}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
