import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getTraining(dispatch) {
  baseAxios
    .get(`/training/gettrainingall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTTRAINING", data: respon.data });
    })
    .catch(errorHandler);
}

export function verifikasiTraining(data) {
  baseAxios
    .patch(`/training/respontraining`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getKpi1Training(dispatch, data) {
  baseAxios
    .get(
      `/training/getgrafiktraining?dari=${data.dariTgl}&sampai=${data.smpTgl}&idCabangWeb=${data.idCabangWeb}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI1TRAINING", data: respon.data });
    })
    .catch(errorHandler);
}

export function getPembinaan(dispatch) {
  baseAxios
    .get(`/pembinaan/getpembinaanall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTPEMBINAAN", data: respon.data });
    })
    .catch(errorHandler);
}

export function verifikasiPembinaan(data) {
  baseAxios
    .patch(`/pembinaan/responpembinaan`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getKpi2Pembinaan(dispatch, data) {
  baseAxios
    .get(
      `/pembinaan/getgrafikpembinaan?dari=${data.dariTgl}&sampai=${data.smpTgl}&idCabangWeb=${data.idCabangWeb}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI2PEMBINAAN", data: respon.data });
    })
    .catch(errorHandler);
}

export function tambahMentoring(data) {
  baseAxios
    .post(`/pembinaan/ajukanpembinaan`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export async function tambahTraining(data) {
  try {
    const response = await baseAxios.post("/training/ajukantraining", data, {
      headers: {
        token: localStorage.getItem("tokenjti"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export function editTraining(data) {
  baseAxios
    .patch(`/training/ajukantrainingulang`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getCalendarTraining(dispatch) {
  baseAxios
    .get("/training/gettrainingcalendar", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_CALENDAR", data: respon.data });
    })
    .catch(errorHandler);
}

export function importAbsensi(data) {
  baseAxios
    .post(`/training/importexcelabsensi`, data, {
      headers: {
        token: localStorage.getItem("tokenjti"),
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) => {
        console.log(
          parseInt(
            Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
          )
        );
      },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getAbsensi(dispatch, data) {
  baseAxios
    .get(`/training/getabsensiall?dari=${data.dariTgl}&sampai=${data.smpTgl}`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTABSENSI", data: respon.data });
    })
    .catch(errorHandler);
}

export function exportAbsensi(data) {
  baseAxios
    .get(
      `/training/exportabsenbydate?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
        responseType: "blob",
      }
    )
    .then(async (respon) => {
      const url = window.URL.createObjectURL(new Blob([respon.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Absensi ${data.dariTgl} sampai ${data.smpTgl}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      Swal.close();
    })
    .catch(errorHandler);
}

export function getKpi3Absensi(dispatch, data) {
  baseAxios
    .get(
      `/training/getgrafikabsensi?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI3ABSENSI", data: respon.data });
    })
    .catch(errorHandler);
}

export function deleteTraining(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .delete("/training/deletetraining", {
      data: { trainingId: data },
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function kirimReportTraining(data) {
  if (!data.noteTraining || data.gambar.length === 0) {
    Swal.fire({
      title: "Please complete the form!",
      icon: "error",
    });
  } else {
    Swal.fire({
      title: "Loading ...",
      didOpen() {
        Swal.showLoading();
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
    let formData = new FormData();

    formData.append("keterangan", data.noteTraining);
    formData.append("trainingId", data.trainingId);
    formData.append("respon", data.respon);
    formData.append("tglPelaksanaan", data.tglPelaksanaan);
    data.gambar.map((val, index) => {
      formData.append("buktiSelesai", val);
    });
    // baseAxios
    //   .patch(`/training/respontraining`, formData, {
    //     headers: { token: localStorage.getItem("tokenjti") },
    //   })
    //   .then(async (respon) => {
    //     Swal.fire({
    //       title: respon.data.message,
    //       icon: "success",
    //     }).then(() => {
    //       window.location.reload();
    //     });
    //   })
    //   .catch(errorHandler);
  }
}
