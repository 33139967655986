const initialState = {
  getCsReview: [],
  grafikCsReview: null,
};
const csReviewReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (action.type) {
    case "SET_GETCSREVIEW":
      return {
        ...state,
        getCsReview: data,
      };
    case "SET_GRAFIKCSREVIEW":
      return {
        ...state,
        grafikCsReview: data,
      };

    default:
      return state;
  }
};
export default csReviewReducer;
