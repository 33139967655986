import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
  Image,
} from "react-bootstrap";
// import Chart from "react-apexcharts";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactDatetime from "react-datetime";
import logoBAP from "../assets/img/image.jpg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import {
  getKpiMonitoringProblem,
  getKpiMonitoringKeluhan,
  getKpiMonitoringProject,
  getKpiMonitoringKunjungan,
  getKpi1Consumable,
  getKpiReportShift,
  getKpi2PembinaanPenampilan,
  getKpi1KunjunganPest,
  getKpi2TemuanPest,
  getKpi1ItProject,
  getKpi1Safety,
  getGrafikAbsen,
  getKpiPatroli,
  getKpi1VisitIssue,
  getKpi2ResponIssue,
  getKpi2Hardware,
  getKpi3Software,
  getKpi3Absensi,
  getKpiMonitoringSurvey,
  getTargetByMonth,
  getTargetByMonthCabang,
  setKirimIdCabang,
  getCabangAktif,

  //Get Grafik
  getGrafikIncident,
  getKpi1Training,
  getKpi2Pembinaan,
  getKpi1Sidak,
  getGrafikMonthlyReport,
  getKpiDashboard,
  grafikCsVisit,
  grafikCsReview,
} from "../stores";
function DashboardBakalan(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  //===================Penilaian Grafik========================
  const incident = useSelector((state) => state.incidentReducer);
  const trainingPersonnel = useSelector((state) => state.trainingReducer);
  const listShift = useSelector((state) => state.shiftReportReducer);
  const pembinaan = useSelector((state) => state.pembinaanReducer);
  const penampilan = useSelector((state) => state.penampilanReducer);
  const safety = useSelector((state) => state.safetyReducer);
  const auth = useSelector((state) => state.authReducer);
  const sidak = useSelector((state) => state.sidakReducer);
  const absen = useSelector((state) => state.absenReducer);
  const monitoring = useSelector((state) => state.monitoringReducer);
  const target = useSelector((state) => state.targetReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const monthlyReport = useSelector((state) => state.monthlyReportReducer);
  const csVisit = useSelector((state) => state.csVisitReducer);
  const csReview = useSelector((state) => state.csReviewReducer);

  // const issue = useSelector((state) => state.industrialReducer);
  // const survey = useSelector((state) => state.surveyReducer);
  // const pestRodent = useSelector((state) => state.pestRodentReducer);
  // const projectIt = useSelector((state) => state.itProjectReducer);
  // const monitoring = useSelector((state) => state.monitoringReducer);
  // const consumable = useSelector((state) => state.consumableReducer);
  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(
    // new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
    props.idCabang.startDate
  );
  const [persenIsmCabang, setPersenIsmCabang] = React.useState("");

  const [smpTgl, setSmpTgl] = React.useState(
    // new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 0, 0)
    props.idCabang.endDate
  );
  const [filter, setFilter] = React.useState({
    tahun: new Date().getFullYear(),
  });
  const [totalKpi, setTotalKpi] = React.useState("");
  //CARD KPI
  const [kpiPatrol, setKpiPatrol] = React.useState(null);
  const [kpiIncident, setKpiIncident] = React.useState(null);
  const [kpiTraining, setKpiTraining] = React.useState(null);
  const [kpiPembinaan, setKpiPembinaan] = React.useState(null);
  const [kpiSidak, setKpiSidak] = React.useState(null);
  const [kpiMonthly, setKpiMonthly] = React.useState(null);
  const [kpiDashboard, setKpiDashboard] = React.useState(null);
  const [kpiCsVisit, setKpiCsVisit] = React.useState(null);
  const [kpiCsReview, setKpiCsReview] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [namaCabang, setNamaCabang] = React.useState([]);
  React.useEffect(() => {
    getCabangAktif(dispatch);
    getKpiPatroli(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang.idCabang,
    });
    getGrafikIncident(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang.idCabang,
    });
    getKpi1Training(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang.idCabang,
    });
    getKpi2Pembinaan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang.idCabang,
    });
    getKpi1Sidak(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang.idCabang,
      idClientWeb: props.idCabang.idClient,
    });
    getGrafikMonthlyReport(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      // idCabangWeb: props.idCabang.idCabang,
      idClientWeb: props.idCabang.idClient,
    });
    grafikCsVisit(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang.idCabang,
    });
    grafikCsReview(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idClientWeb: props.idCabang.idClient,
    });
    setLoading(true);
    getKpiDashboard(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang.idCabang,
      idClientWeb: props.idCabang.idClient,
    }).then((response) => {
      setLoading(false);
    });
    //==========================BELUM DIPAKAI======================

    getKpi1Safety(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });

    getKpi2PembinaanPenampilan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getGrafikAbsen(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang,
    });

    getTargetByMonth(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getTargetByMonthCabang(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabang: props.idCabang,
    });
    // getKpiReportShift(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    //   idCabangWeb: props.idCabang,
    // });
    // getKpiMonitoringProblem(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpiMonitoringKeluhan(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpiMonitoringProject(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpiMonitoringKunjungan(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi1Consumable(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi2Pembinaan(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi1KunjunganPest(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi2TemuanPest(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi1ItProject(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi1VisitIssue(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi2ResponIssue(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi2Hardware(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi3Software(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi3Absensi(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    setPersenIsmCabang(
      Math.round(
        (monitoring.kpiPatroli.pencapaian + listShift.kpi.pencapaian) / 2
      )
        ? Math.round(
            (monitoring.kpiPatroli.pencapaian + listShift.kpi.pencapaian) / 2
          )
        : 0
    );
  });
  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
        cabang: val.namaCabang,
      };
    });
    setNamaCabang(tmp);
  }, [cabang.getCabangAktif]);

  React.useEffect(() => {
    if (target.getTargetByMonth && target.getTargetByMonth.targetCabang) {
      let tmp = target.getTargetByMonth.targetCabang.map((val, index) => {
        return {
          ...val,
        };
      });
    }
  }, [target.getTargetByMonth]);

  // Card KPI
  React.useEffect(() => {
    if (monitoring.kpiPatroli) {
      setKpiPatrol(monitoring.kpiPatroli.data);
    }
  }, [monitoring.kpiPatroli]);
  React.useEffect(() => {
    if (incident.getGrafikIncident) {
      setKpiIncident(incident.getGrafikIncident.data);
    }
  }, [incident.getGrafikIncident]);
  React.useEffect(() => {
    if (trainingPersonnel.kpi1) {
      setKpiTraining(trainingPersonnel.kpi1.data);
    }
  }, [trainingPersonnel.kpi1]);
  React.useEffect(() => {
    if (pembinaan.kpi2) {
      setKpiPembinaan(pembinaan.kpi2.data);
    }
  }, [pembinaan.kpi2]);
  React.useEffect(() => {
    if (sidak.kpi1) {
      setKpiSidak(sidak.kpi1.data);
    }
  }, [sidak.kpi1]);
  React.useEffect(() => {
    if (monthlyReport.getGrafikMonthlyReport) {
      setKpiMonthly(monthlyReport.getGrafikMonthlyReport.data);
    }
  }, [monthlyReport.getGrafikMonthlyReport]);
  React.useEffect(() => {
    if (monitoring.kpiDashboard) {
      setKpiDashboard(monitoring.kpiDashboard);
    }
  }, [monitoring.kpiDashboard]);
  React.useEffect(() => {
    if (csVisit.grafikCsVisit) {
      setKpiCsVisit(csVisit.grafikCsVisit.data);
    }
  }, [csVisit.grafikCsVisit]);
  React.useEffect(() => {
    if (csReview.grafikCsReview) {
      // console.log(csReview.grafikCsReview, "?????");
      setKpiCsReview(csReview.grafikCsReview.statusKategori);
    }
  }, [csReview.grafikCsReview]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card style={{ backgroundColor: "#04293A" }}>
              <Card.Header
                className="d-none d-md-inline"
                style={{ backgroundColor: "white" }}
              >
                <Card.Title as="h3">
                  <Row>
                    <Col md="8" className="p-3">
                      <b>
                        ISM Performance Achievement
                        {namaCabang.map((val) => {
                          if (val.idCabang === props.idCabang.idCabang) {
                            return <> {val.namaCabang}</>;
                          }
                        })}{" "}
                      </b>
                    </Col>
                    <Col md="4" className="p-3 d-flex justify-content-end">
                      <Image
                        src={
                          "https://backoffice.bapguard.com/upload/logo_bap.png"
                        }
                        width="auto"
                        height="50px"
                      />
                      {/* <Image 
                        src={
                          "https://ptbap.net/assets/images/logo/invert-bap.png"
                        }
                        width="auto"
                        height="50px"
                      /> */}
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Header>
              <Card.Body className="d-none d-md-inline">
                {/* <Row>
                  <Col
                    as="h3"
                    //  md="8"
                    // className="p-3"
                    className="d-none d-lg-flex align-items-center "
                    style={{ color: "white" }}
                  >
                    <b>
                      ISM Performance Achievement
                      {namaCabang.map((val) => {
                        if (val.idCabang === props.idCabang.idCabang) {
                          return <> {val.namaCabang}</>;
                        }
                      })}{" "}
                    </b>
                  </Col>

                  <Col md="4" className="p-3 d-flex justify-content-end">
                    <Image
                      src={
                        "https://ptbap.net/assets/images/logo/invert-bap.png"
                      }
                      width="auto"
                      height="50px"
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Col
                    lg="5"
                    className="d-none d-lg-flex align-items-center justify-content-center"
                  >
                    <i
                      className="fas fa-user-shield fa-5x"
                      style={{ color: "white" }}
                    ></i>
                  </Col>
                  <Col lg="7" xs="12">
                    <div className="numbers d-flex justify-content-around">
                      <div className="d-flex flex-column align-items-center">
                        <p className="card-category">
                          <h3>
                            <b style={{ color: "white" }}>Achievement</b>
                          </h3>
                        </p>
                        <Card.Title
                          as="h2"
                          style={{
                            // color: persenIfm < 89 ? "#CD1818" : "#20c400",
                            color:
                              props.idCabang.idClient === "73"
                                ? kpiDashboard <= 54
                                  ? "#CD1818"
                                  : kpiDashboard <= 74
                                  ? "#fcb90a"
                                  : "#20c400"
                                : kpiDashboard <= 59
                                ? "#CD1818"
                                : kpiDashboard <= 79
                                ? "#fcb90a"
                                : "#20c400",
                            // kpiDashboard <= 79 ? "#fcb90a" : "#20c400",
                            fontFamily: "sans-serif",
                          }}
                        >
                          {loading ? (
                            <SkeletonTheme
                              color="#00628f"
                              highlightColor=""
                              baseColor="#04293A"
                            >
                              <Skeleton height={"100%"} width={120} />
                            </SkeletonTheme>
                          ) : (
                            <>
                              <b>{kpiDashboard}%</b>
                            </>
                          )}
                        </Card.Title>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="card-category">
                          <h3>
                            <b style={{ color: "white" }}>Target</b>
                          </h3>
                        </p>
                        <Card.Title
                          as="h2"
                          style={{
                            color: "#20c400",
                            fontFamily: "sans-serif",
                          }}
                        >
                          {loading ? (
                            <SkeletonTheme
                              color="#00628f"
                              highlightColor=""
                              baseColor="#04293A"
                            >
                              <Skeleton height={"100%"} width={120} />
                            </SkeletonTheme>
                          ) : (
                            <>
                              {props.idCabang.idClient === "73" ? (
                                <b>80%</b>
                              ) : (
                                <b>80%</b>
                              )}
                            </>
                          )}
                        </Card.Title>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="card-category">
                          <h3>
                            <b style={{ color: "white" }}>Status</b>
                          </h3>
                        </p>
                        <Card.Title
                          as="h2"
                          style={{
                            color:
                              kpiDashboard <= 70
                                ? "#CD1818"
                                : kpiDashboard <= 92
                                ? "#fcb90a"
                                : "#20c400",
                            fontFamily: "sans-serif",
                          }}
                        >
                          {loading ? (
                            <SkeletonTheme
                              color="#00628f"
                              highlightColor=""
                              baseColor="#04293A"
                            >
                              <Skeleton height={"100%"} width={120} />
                            </SkeletonTheme>
                          ) : (
                            <>
                              <b>
                                {kpiDashboard <= 70
                                  ? "Below"
                                  : kpiDashboard <= 92
                                  ? "Meet"
                                  : "Exceed"}
                              </b>
                            </>
                          )}

                          {/* <b>80%</b> */}
                        </Card.Title>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title mt-2">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                  readOnly: true,
                  style: {
                    cursor: "default",
                    backgroundColor: "white",
                    color: "black !important",
                  },
                }}
                onChange={(e) => {
                  // setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                  setDariTgl(
                    new Date(e.year(), e.month(), 1).setHours(0, 0, 0, 0)
                  );
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
                dateFormat="MMMM YYYY"
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title mt-2">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                  readOnly: true,
                  style: {
                    cursor: "default",
                    backgroundColor: "white",
                    color: "black !important",
                  },
                }}
                onChange={(e) => {
                  // setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                  setSmpTgl(
                    new Date(e.year(), e.month() + 1, 0).setHours(23, 59, 0, 0)
                  );
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
                dateFormat="MMMM YYYY"
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>

        <>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="1">
                      <div className="text-center">
                        <i className="fas fa-shield-alt text-primary fa-2x"></i>
                      </div>
                    </Col>
                    <Card.Title as="h4">Patrol</Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr></hr>
                  <Row className="justify-content-center align-items-center">
                    {kpiPatrol?.map((val, index) => {
                      // Mengatur 3 item per baris
                      return (
                        <Col
                          key={index}
                          xs={12}
                          md={4}
                          className="d-flex justify-content-center mb-3"
                        >
                          <Card style={{ width: "18rem", margin: "2px" }}>
                            {" "}
                            {/* Lebar kotak diperbesar menjadi 18rem */}
                            <Card.Header className="d-flex flex-column align-items-center">
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                                className="card-category"
                              >
                                {val.namaShift}
                              </p>
                            </Card.Header>
                            <hr></hr>
                            {/* <Card.Body> */}
                            <Row noGutters>
                              <Col
                                className="p-1 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  borderRight: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Achievement
                              </Col>
                              <Col
                                className="p-1 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  borderRight: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Target
                              </Col>
                              <Col
                                className="p-1 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Status
                              </Col>
                            </Row>
                            <Row noGutters style={{ marginBottom: 15 }}>
                              <Col
                                className="p-2  d-flex justify-content-center"
                                style={{
                                  borderRight: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  fontWeight: "bold",
                                  fontSize: 15,
                                }}
                              >
                                {val["Data Patroli"]}{" "}
                              </Col>
                              <Col
                                className="p-2 d-flex justify-content-center"
                                style={{
                                  whiteSpace: "nowrap",
                                  borderRight: "1px solid #ddd",
                                  overflow: "hidden",
                                  fontWeight: "bold",
                                  textOverflow: "ellipsis",
                                  fontSize: 15,
                                }}
                              >
                                {val["Target Patroli"]}{" "}
                              </Col>
                              <Col
                                className="p-2 d-flex justify-content-center"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  fontWeight: "bold",
                                  fontSize: 15,
                                  color:
                                    val.hasilPatroli === "Below"
                                      ? "red"
                                      : val.hasilPatroli === "Meet"
                                      ? "orange"
                                      : "green",
                                }}
                              >
                                {val.hasilPatroli}{" "}
                              </Col>
                            </Row>
                            {/* <Row
                                noGutters
                                className="justify-content-center align-items-center"
                                style={{
                                  marginTop: -20,
                                  display: "flex",
                                }}
                              >
                                <Col
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 15,
                                    borderRight: "1px solid #ddd",
                                    padding: "2px 5px",
                                    height: "auto",
                                  }}
                                >
                                  Achievement
                                </Col>
                                <Col
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 15,
                                    padding: "2px 5px",
                                    borderRight: "1px solid #ddd",
                                    height: "100%",
                                  }}
                                >
                                  Target
                                </Col>
                                <Col
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 15,
                                    padding: "2px 5px",
                                    height: "100%",
                                  }}
                                >
                                  Status
                                </Col>
                                <Col
                                  xs={4}
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 20,
                                    padding: "2px 5px",
                                    height: "100%",
                                  }}
                                >
                                  {val["Data Patroli"]}{" "}
                                </Col>
                                <Col
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    borderRight: "1px solid #ddd",
                                    borderLeft: "1px solid #ddd",
                                    fontWeight: "bold",
                                    fontSize: 20,
                                    marginLeft: 15.5,
                                    marginRight: -25,
                                    height: "100%",
                                  }}
                                >
                                  {val["Target Patroli"]}{" "}
                                </Col>
                                <Col
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 15,
                                    marginLeft: 25,
                                    height: "100%",
                                    color:
                                      val.hasilPatroli === "Below"
                                        ? "red"
                                        : val.hasilPatroli === "Meet"
                                        ? "orange"
                                        : "green",
                                  }}
                                >
                                  {val.hasilPatroli}{" "}
                                </Col>
                              </Row> */}
                            {/* </Card.Body> */}
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Card.Body>

                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/detailPatroli");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>

            <Col md="4">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="2">
                      <div className="text-center">
                        <i className="fas fa-user-injured text-danger fa-2x"></i>
                      </div>
                    </Col>
                    <Card.Title as="h4">Incident Report</Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr />
                  <Row className="justify-content-center">
                    <Col
                      md={6}
                      // key={index}
                      className="mb-3 d-flex justify-content-center"
                    >
                      <div
                        className="numbers d-flex flex-column align-items-center p-2"
                        style={{
                          border: "1px solid #ccc", // Garis tepi
                          borderRadius: "8px", // Sudut melengkung
                          width: "100%", // Sesuaikan lebar
                          maxWidth: "200px", // Lebar maksimal
                          padding: "10px", // Padding untuk jarak di dalam kotak
                          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)", // Bayangan halus
                          textAlign: "center", // Teks di tengah horizontal
                        }}
                      >
                        <Row>
                          <div className="d-flex flex-column align-items-center">
                            <p
                              className="card-category"
                              style={{
                                fontWeight: "bold",
                                color: "black",
                                margin: "0 0 5px 0", // Margin bawah untuk jarak antar teks
                              }}
                            >
                              Status:
                            </p>
                            <Card.Title as="h4" className="m-0"></Card.Title>
                          </div>
                          <div className="d-flex flex-column align-items-center ml-2">
                            <p
                              className="card-category"
                              style={{
                                fontWeight: "bold",
                                color:
                                  incident.getGrafikIncident &&
                                  incident.getGrafikIncident.statusAkhir ===
                                    "Below"
                                    ? "red"
                                    : incident.getGrafikIncident &&
                                      incident.getGrafikIncident.statusAkhir ===
                                        "Meet"
                                    ? "orange"
                                    : "green",
                                margin: 0,
                              }}
                            >
                              {incident.getGrafikIncident &&
                                incident.getGrafikIncident.statusAkhir}
                            </p>
                          </div>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="p1 d-flex align-items-center"
                      // style={{ backgroundColor: "red" }}
                    >
                      <Card
                        style={{ width: "8rem", margin: "2px", marginLeft: 1 }}
                      >
                        <Card.Header className="d-flex flex-column align-items-center">
                          <p
                            style={{
                              color: "black",
                              fontWeight: "bold",
                            }}
                            className="card-category"
                          >
                            {kpiIncident?.[0]?.nama}
                          </p>
                        </Card.Header>
                        <hr />
                        <Card.Body>
                          <Col noGutters style={{ marginTop: -20 }}>
                            <Row
                              style={{
                                marginLeft: -18,
                                borderBottom: "1px solid #ddd",
                              }}
                            >
                              <Col
                                sm="7"
                                className="p-1 d-flex justify-content-start"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  color: "green",
                                }}
                              >
                                Exceed
                              </Col>
                              <Col
                                sm="0.5"
                                className="p-1 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                :
                              </Col>
                              <Col
                                sm="2"
                                className="p-2 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {kpiIncident?.[0]?.Exceed}
                              </Col>
                            </Row>
                            <Row
                              style={{
                                marginLeft: -18,
                                borderBottom: "1px solid #ddd",
                              }}
                            >
                              <Col
                                sm="7"
                                className="p-1 d-flex justify-content-start"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  color: "orange",
                                }}
                              >
                                Meet
                              </Col>
                              <Col
                                sm="0.5"
                                className="p-1 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                :
                              </Col>
                              <Col
                                sm="2"
                                className="p-2 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {kpiIncident?.[0]?.Meet}
                              </Col>
                            </Row>
                            <Row
                              style={{
                                marginLeft: -18,
                                borderBottom: "1px solid #ddd",
                              }}
                            >
                              <Col
                                sm="7"
                                className="p-1 d-flex justify-content-start"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  color: "red",
                                }}
                              >
                                Below
                              </Col>
                              <Col
                                sm="0.5"
                                className="p-1 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                :
                              </Col>
                              <Col
                                sm="2"
                                className="p-2 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {kpiIncident?.[0]?.Below}
                              </Col>
                            </Row>
                            <Row
                              style={{
                                marginLeft: -18,
                                // backgroundColor: "red",
                              }}
                            >
                              <Col className="d-flex flex-column align-items-center">
                                <Col
                                  // sm="7"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    // padding: "2px 1px",
                                    // height: "auto",
                                    // width: "auto",
                                    // display: "flex",
                                    // alignItems: "center",
                                  }}
                                >
                                  Status
                                </Col>

                                <Col
                                  // sm="2"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    marginTop: -10,
                                    color:
                                      incident.getGrafikIncident &&
                                      incident.getGrafikIncident.statusMajor ===
                                        "Below"
                                        ? "red"
                                        : incident.getGrafikIncident &&
                                          incident.getGrafikIncident
                                            .statusMajor === "Meet"
                                        ? "orange"
                                        : "green",
                                    // display: "flex",
                                    // alignItems: "center",
                                  }}
                                >
                                  {incident.getGrafikIncident &&
                                    incident.getGrafikIncident.statusMajor}
                                </Col>
                              </Col>
                            </Row>
                          </Col>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col className="p-2 d-flex">
                      <Card style={{ width: "8rem", margin: "2px" }}>
                        <Card.Header className="d-flex flex-column align-items-center">
                          <p
                            style={{
                              color: "black",
                              fontWeight: "bold",
                            }}
                            className="card-category"
                          >
                            {kpiIncident?.[1]?.nama}
                          </p>
                        </Card.Header>
                        <hr />
                        <Card.Body>
                          <Col noGutters style={{ marginTop: -20 }}>
                            <Row
                              style={{
                                marginLeft: -18,
                                borderBottom: "1px solid #ddd",
                              }}
                            >
                              <Col
                                sm="7"
                                className="p-1 d-flex justify-content-start"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  color: "green",
                                }}
                              >
                                Exceed
                              </Col>
                              <Col
                                sm="0.5"
                                className="p-1 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                :
                              </Col>
                              <Col
                                sm="2"
                                className="p-2 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {kpiIncident?.[1]?.Exceed}
                              </Col>
                            </Row>
                            <Row
                              style={{
                                marginLeft: -18,
                                borderBottom: "1px solid #ddd",
                              }}
                            >
                              <Col
                                sm="7"
                                className="p-1 d-flex justify-content-start"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  color: "orange",
                                }}
                              >
                                Meet
                              </Col>
                              <Col
                                sm="0.5"
                                className="p-1 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                :
                              </Col>
                              <Col
                                sm="2"
                                className="p-2 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {kpiIncident?.[1]?.Meet}
                              </Col>
                            </Row>
                            <Row
                              style={{
                                borderBottom: "1px solid #ddd",
                                marginLeft: -18,
                              }}
                            >
                              <Col
                                sm="7"
                                className="p-1 d-flex justify-content-start"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  color: "red",
                                }}
                              >
                                Below
                              </Col>
                              <Col
                                sm="0.5"
                                className="p-1 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                :
                              </Col>
                              <Col
                                sm="2"
                                className="p-2 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {kpiIncident?.[1]?.Below}
                              </Col>
                            </Row>
                            <Row
                              style={{
                                marginLeft: -18,
                                // backgroundColor: "red",
                              }}
                            >
                              <Col className="d-flex flex-column align-items-center">
                                <Col
                                  // sm="7"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    // padding: "2px 1px",
                                    // height: "auto",
                                    // width: "auto",
                                    // display: "flex",
                                    // alignItems: "center",
                                  }}
                                >
                                  Status
                                </Col>

                                <Col
                                  // sm="2"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    marginTop: -10,
                                    color:
                                      incident.getGrafikIncident &&
                                      incident.getGrafikIncident
                                        .statusNonMajor === "Below"
                                        ? "red"
                                        : incident.getGrafikIncident &&
                                          incident.getGrafikIncident
                                            .statusNonMajor === "Meet"
                                        ? "orange"
                                        : "green",
                                    // display: "flex",
                                    // alignItems: "center",
                                  }}
                                >
                                  {incident.getGrafikIncident &&
                                    incident.getGrafikIncident.statusNonMajor}
                                </Col>
                              </Col>
                            </Row>
                          </Col>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/incidentrecapitulation");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>

            <Col md="4">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="2">
                      <div className="text-center">
                        <i className="fas fa-user-tie text-warning fa-2x"></i>
                      </div>
                    </Col>
                    <Card.Title as="h4">Training Matrix</Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr></hr>
                  <Card style={{ margin: "2px" }}>
                    {/* <Card.Body> */}
                    <Row noGutters>
                      <Col
                        className="p1 d-flex align-items-center"
                        // style={{ backgroundColor: "red" }}
                      >
                        {/* <Card
                          style={{
                            width: "8rem",
                            margin: "2px",
                            marginLeft: 1,
                          }}
                        > */}
                        <Card.Body>
                          <Col noGutters style={{ marginTop: -20 }}>
                            <Row
                              style={{
                                marginLeft: -18,
                                borderBottom: "1px solid #ddd",
                              }}
                            >
                              <Col
                                sm="4"
                                className="p-1 d-flex justify-content-start"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Achievement
                              </Col>
                              <Col
                                sm="4"
                                className="p-1 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  // marginLeft: 15,
                                }}
                              >
                                :
                              </Col>
                              <Col
                                sm="2"
                                className="p-2 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {kpiTraining?.[0]?.["On Time"]}
                              </Col>
                            </Row>
                            <Row
                              style={{
                                marginLeft: -18,
                                borderBottom: "1px solid #ddd",
                              }}
                            >
                              <Col
                                sm="4"
                                className="p-1 d-flex justify-content-start"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Target
                              </Col>
                              <Col
                                sm="4"
                                className="p-1 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                :
                              </Col>
                              <Col
                                sm="2"
                                className="p-2 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {kpiTraining?.[0]?.Target}
                              </Col>
                            </Row>
                            <Row
                              style={{
                                marginLeft: -18,
                              }}
                            >
                              <Col
                                sm="4"
                                className="p-1 d-flex justify-content-start"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Status
                              </Col>
                              <Col
                                sm="4"
                                className="p-1 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                :
                              </Col>
                              <Col
                                sm="2"
                                className="p-2 d-flex justify-content-center"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  padding: "2px 5px",
                                  height: "auto",
                                  width: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  color:
                                    trainingPersonnel.kpi1.pencapaian ===
                                    "Below"
                                      ? "red"
                                      : trainingPersonnel.kpi1.pencapaian ===
                                        "Meet"
                                      ? "orange"
                                      : "green",
                                }}
                              >
                                {trainingPersonnel.kpi1.pencapaian}
                              </Col>
                            </Row>
                          </Col>
                        </Card.Body>
                        {/* </Card> */}
                      </Col>
                      {/* <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Achievement
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Target
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",

                            fontSize: 12,
                          }}
                        >
                          Status
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            borderRight: "1px solid #ddd",
                            fontWeight: "bold",

                            fontSize: 12,
                          }}
                        >
                          {kpiTraining?.[0]?.["On Time"]}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            borderRight: "1px solid #ddd",
                            fontWeight: "bold",
                            fontSize: 12,
                          }}
                        >
                          {kpiTraining?.[0]?.Target}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            color:
                              trainingPersonnel.kpi1.pencapaian === "Below"
                                ? "red"
                                : trainingPersonnel.kpi1.pencapaian === "Meet"
                                ? "orange"
                                : "green",
                          }}
                        >
                          {trainingPersonnel.kpi1.pencapaian}
                        </Col> */}
                    </Row>
                    {/* </Card.Body> */}
                  </Card>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartTraining");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>

            <Col md="4">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="2">
                      <div className="text-center">
                        <i className="fas fa-file-alt text-info fa-2x"></i>
                      </div>
                    </Col>
                    <Card.Title as="h4">Coaching</Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr></hr>
                  <Card style={{ margin: "2px" }}>
                    <Card.Body>
                      <Row noGutters>
                        <Col
                          className="p1 d-flex align-items-center"
                          // style={{ backgroundColor: "red" }}
                        >
                          {/* <Card
                          style={{
                            width: "8rem",
                            margin: "2px",
                            marginLeft: 1,
                          }}
                        > */}
                          <Card.Body>
                            <Col noGutters style={{ marginTop: -20 }}>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  borderBottom: "1px solid #ddd",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  sm="8"
                                  className="p-1 d-flex justify-content-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "green",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Exceed
                                </Col>
                                <Col
                                  sm="0.5"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  :
                                </Col>
                                <Col
                                  sm="4"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {kpiPembinaan?.[0]?.Exceed}
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  borderBottom: "1px solid #ddd",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  sm="8"
                                  className="p-1 d-flex justify-content-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "orange",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Meet
                                </Col>
                                <Col
                                  sm="0.5"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  :
                                </Col>
                                <Col
                                  sm="4"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {kpiPembinaan?.[0]?.Meet}
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  borderBottom: "1px solid #ddd",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  sm="8"
                                  className="p-1 d-flex justify-content-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "red",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Below
                                </Col>
                                <Col
                                  sm="0.5"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  :
                                </Col>
                                <Col
                                  sm="4"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {kpiPembinaan?.[0]?.Below}
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  borderBottom: "1px solid #ddd",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  sm="8"
                                  className="p-1 d-flex justify-content-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#006bf7",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Waiting for Approval
                                </Col>
                                <Col
                                  sm="0.5"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  :
                                </Col>
                                <Col
                                  sm="4"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {kpiPembinaan?.[0]?.["Waiting for Approval"]}
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  sm="8"
                                  className="p-1 d-flex justify-content-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Status
                                </Col>
                                <Col
                                  sm="0.5"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  :
                                </Col>
                                <Col
                                  sm="4"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    color:
                                      pembinaan.kpi2 &&
                                      pembinaan.kpi2.status === "Below"
                                        ? "red"
                                        : pembinaan.kpi2 &&
                                          pembinaan.kpi2.status === "Meet"
                                        ? "orange"
                                        : "green",
                                  }}
                                >
                                  {pembinaan.kpi2 && pembinaan.kpi2.status}
                                </Col>
                              </Row>
                            </Col>
                          </Card.Body>
                          {/* </Card> */}
                        </Col>
                        {/* <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            // marginLeft: -20,
                            padding: "0.5rem",
                            fontWeight: "bold",
                            fontSize: 12,
                            borderRight: "1px solid #ddd",
                            color: "green",
                          }}
                        >
                          Exceed
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            padding: "0.5rem",
                            // marginLeft: -15,
                            borderRight: "1px solid #ddd",
                            color: "orange",
                          }}
                        >
                          Meet
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            padding: "0.5rem",
                            fontSize: 12,
                            borderRight: "1px solid #ddd",
                            color: "red",
                          }}
                        >
                          Below
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            color: "#006bf7",
                          }}
                        >
                          Waiting for Approval
                        </Col> */}
                      </Row>
                      {/* <Row noGutters>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            // marginLeft: -20,
                            padding: "0.5rem",
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {kpiPembinaan?.[0]?.Exceed}
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            padding: "0.5rem",
                            borderRight: "1px solid #ddd",
                            // marginLeft: -18,
                          }}
                        >
                          {kpiPembinaan?.[0]?.Meet}
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            padding: "0.5rem",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {kpiPembinaan?.[0]?.Below}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            padding: "0.5rem",
                          }}
                        >
                          {kpiPembinaan?.[0]?.["Waiting for Approval"]}
                        </Col>
                      </Row> */}
                    </Card.Body>
                  </Card>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartPembinaan");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>

            <Col md="4">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="2">
                      <div className="text-center">
                        <i className="fas fa-search text-success fa-2x"></i>
                      </div>
                    </Col>
                    <Card.Title as="h4">Inspection</Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr></hr>
                  <Card style={{ margin: "2px" }}>
                    <Card.Body>
                      <Col noGutters style={{ marginTop: -20 }}>
                        <Row
                          style={{
                            marginLeft: -18,
                            borderBottom: "1px solid #ddd",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Col
                            sm="7"
                            className="p-1 d-flex justify-content-start"
                            style={{
                              fontWeight: "bold",
                              fontSize: 12,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Achievement Online
                          </Col>
                          <Col
                            sm="0.5"
                            className="p-1 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                              // marginLeft: 20,
                            }}
                          >
                            :
                          </Col>
                          <Col
                            sm="5"
                            className="p-2 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {kpiSidak?.[0].Online}
                          </Col>
                        </Row>
                        <Row
                          style={{
                            marginLeft: -18,
                            borderBottom: "1px solid #ddd",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Col
                            sm="7"
                            className="p-1 d-flex justify-content-start"
                            style={{
                              fontWeight: "bold",
                              fontSize: 12,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Target Online
                          </Col>
                          <Col
                            sm="0.5"
                            className="p-1 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            :
                          </Col>
                          <Col
                            sm="5"
                            className="p-2 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {kpiSidak?.[0]["Target Online"]}
                          </Col>
                        </Row>
                        <Row
                          style={{
                            marginLeft: -18,
                            borderBottom: "1px solid #ddd",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Col
                            sm="7"
                            className="p-1 d-flex justify-content-start"
                            style={{
                              fontWeight: "bold",
                              fontSize: 12,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Achievement Offline
                          </Col>
                          <Col
                            sm="0.5"
                            className="p-1 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            :
                          </Col>
                          <Col
                            sm="5"
                            className="p-2 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {kpiSidak?.[0].Offline}
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: -18,
                            borderBottom: "1px solid #ddd",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Col
                            sm="7"
                            className="p-1 d-flex justify-content-start"
                            style={{
                              fontWeight: "bold",
                              fontSize: 12,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Target Offline
                          </Col>
                          <Col
                            sm="0.5"
                            className="p-1 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            :
                          </Col>
                          <Col
                            sm="5"
                            className="p-2 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {kpiSidak?.[0]["Target Offline"]}
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: -18,
                            flexWrap: "nowrap",
                          }}
                        >
                          <Col
                            sm="7"
                            className="p-1 d-flex justify-content-start"
                            style={{
                              fontWeight: "bold",
                              fontSize: 12,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Status
                          </Col>
                          <Col
                            sm="0.5"
                            className="p-1 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            :
                          </Col>
                          <Col
                            sm="5"
                            className="p-2 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                              color:
                                sidak.kpi1.pencapaianInspection === "Below"
                                  ? "red"
                                  : sidak.kpi1.pencapaianInspection === "Meet"
                                  ? "orange"
                                  : "green",
                            }}
                          >
                            {sidak.kpi1.pencapaianInspection}
                          </Col>
                        </Row>
                      </Col>
                    </Card.Body>

                    {/* <Card.Body>
                      <Row noGutters>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            marginLeft: -20,
                            fontWeight: "bold",
                            fontSize: 12,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Online
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Offline
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Target
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                          }}
                        >
                          Status
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            marginLeft: -20,
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {kpiSidak?.[0].Online}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",

                            //   color:
                            //     trainingPersonnel.kpi1.pencapaian === "Below"
                            //       ? "red"
                            //       : trainingPersonnel.kpi1.pencapaian === "Meet"
                            //       ? "orange"
                            //       : "green",
                          }}
                        >
                          {kpiSidak?.[0].Offline}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",

                            //   color:
                            //     trainingPersonnel.kpi1.pencapaian === "Below"
                            //       ? "red"
                            //       : trainingPersonnel.kpi1.pencapaian === "Meet"
                            //       ? "orange"
                            //       : "green",
                          }}
                        >
                          {kpiSidak?.[0].Target}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            color:
                              sidak.kpi1.pencapaianInspection === "Below"
                                ? "red"
                                : sidak.kpi1.pencapaianInspection === "Meet"
                                ? "orange"
                                : "green",
                          }}
                        >
                          {" "}
                          {sidak.kpi1.pencapaianInspection}
                        </Col>
                      </Row>
                    </Card.Body> */}
                  </Card>
                </Card.Body>

                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartSidak");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="2">
                      <div className="text-center">
                        <i className="fas fa-user-friends text-secondaray fa-2x"></i>
                      </div>
                    </Col>
                    <Card.Title style={{ marginLeft: 10 }} as="h4">
                      Management Visit
                    </Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr></hr>
                  <Card style={{ margin: "2px" }}>
                    <Card.Body>
                      {/* <Row noGutters> */}
                      <Col noGutters style={{ marginTop: -20 }}>
                        <Row
                          style={{
                            marginLeft: -18,
                            borderBottom: "1px solid #ddd",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Col
                            sm="7"
                            className="p-1 d-flex justify-content-start"
                            style={{
                              fontWeight: "bold",
                              fontSize: 12,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Offline
                          </Col>
                          <Col
                            sm="0.5"
                            className="p-1 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            :
                          </Col>
                          <Col
                            sm="5"
                            className="p-2 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {kpiSidak?.[1].Offline}
                          </Col>
                        </Row>
                        <Row
                          style={{
                            marginLeft: -18,
                            borderBottom: "1px solid #ddd",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Col
                            sm="7"
                            className="p-1 d-flex justify-content-start"
                            style={{
                              fontWeight: "bold",
                              fontSize: 12,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Target
                          </Col>
                          <Col
                            sm="0.5"
                            className="p-1 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            :
                          </Col>
                          <Col
                            sm="5"
                            className="p-2 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {kpiSidak?.[1].Target}
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginLeft: -18,
                            flexWrap: "nowrap",
                          }}
                        >
                          <Col
                            sm="7"
                            className="p-1 d-flex justify-content-start"
                            style={{
                              fontWeight: "bold",
                              fontSize: 12,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Status
                          </Col>
                          <Col
                            sm="0.5"
                            className="p-1 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            :
                          </Col>
                          <Col
                            sm="5"
                            className="p-2 d-flex justify-content-center"
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              padding: "2px 5px",
                              height: "auto",
                              width: "auto",
                              display: "flex",
                              alignItems: "center",
                              color:
                                sidak.kpi1.pencapaianManvis === "Below"
                                  ? "red"
                                  : sidak.kpi1.pencapaianManvis === "Meet"
                                  ? "orange"
                                  : "green",
                            }}
                          >
                            {sidak.kpi1.pencapaianManvis}
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            marginLeft: -32,
                            fontWeight: "bold",
                            fontSize: 12,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Offline
                        </Col>

                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Target
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                          }}
                        >
                          Status
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",
                            marginLeft: -32,

                            //   color:
                            //     trainingPersonnel.kpi1.pencapaian === "Below"
                            //       ? "red"
                            //       : trainingPersonnel.kpi1.pencapaian === "Meet"
                            //       ? "orange"
                            //       : "green",
                          }}
                        >
                          {kpiSidak?.[1].Offline}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",

                            //   color:
                            //     trainingPersonnel.kpi1.pencapaian === "Below"
                            //       ? "red"
                            //       : trainingPersonnel.kpi1.pencapaian === "Meet"
                            //       ? "orange"
                            //       : "green",
                          }}
                        >
                          {kpiSidak?.[1].Target}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            color:
                              sidak.kpi1.pencapaianManvis === "Below"
                                ? "red"
                                : sidak.kpi1.pencapaianManvis === "Meet"
                                ? "orange"
                                : "green",
                          }}
                        >
                          {" "}
                          {sidak.kpi1.pencapaianManvis}
                        </Col> */}
                      {/* </Row> */}
                    </Card.Body>
                  </Card>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartSidak");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="2">
                      <div className="text-center">
                        <i
                          className="fas fa-calendar-alt  fa-2x"
                          style={{ color: "#8000ff" }}
                        ></i>
                      </div>
                    </Col>
                    <Card.Title style={{ marginLeft: 10 }} as="h4">
                      Monthly Report
                    </Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr></hr>
                  <Card style={{ margin: "2px" }}>
                    <Card.Body>
                      <Row noGutters>
                        <Col
                          className="p1 d-flex align-items-center"
                          // style={{ backgroundColor: "red" }}
                        >
                          {/* <Card
                          style={{
                            width: "8rem",
                            margin: "2px",
                            marginLeft: 1,
                          }}
                        > */}
                          <Card.Body>
                            <Col noGutters style={{ marginTop: -20 }}>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  borderBottom: "1px solid #ddd",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  sm="7"
                                  className="p-1 d-flex justify-content-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "green",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Exceed
                                </Col>
                                <Col
                                  sm="0.5"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  :
                                </Col>
                                <Col
                                  sm="5"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {kpiMonthly?.[0]?.Exceed}
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  borderBottom: "1px solid #ddd",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  sm="7"
                                  className="p-1 d-flex justify-content-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "orange",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Meet
                                </Col>
                                <Col
                                  sm="0.5"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  :
                                </Col>
                                <Col
                                  sm="5"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {kpiMonthly?.[0]?.Meet}
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  borderBottom: "1px solid #ddd",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  sm="7"
                                  className="p-1 d-flex justify-content-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "red",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Below
                                </Col>
                                <Col
                                  sm="0.5"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  :
                                </Col>
                                <Col
                                  sm="5"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {kpiMonthly?.[0]?.Below}
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  sm="7"
                                  className="p-1 d-flex justify-content-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Status
                                </Col>
                                <Col
                                  sm="0.5"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  :
                                </Col>
                                <Col
                                  sm="5"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    color:
                                      monthlyReport.getGrafikMonthlyReport
                                        ?.status === "Below"
                                        ? "red"
                                        : monthlyReport.getGrafikMonthlyReport
                                            ?.status === "Meet"
                                        ? "orange"
                                        : "green",
                                  }}
                                >
                                  {monthlyReport.getGrafikMonthlyReport?.status}
                                </Col>
                              </Row>
                            </Col>
                          </Card.Body>
                          {/* </Card> */}
                        </Col>
                        {/* <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            // marginLeft: -20,
                            padding: "0.5rem",
                            fontWeight: "bold",
                            fontSize: 12,
                            borderRight: "1px solid #ddd",
                            color: "green",
                          }}
                        >
                          Exceed
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            padding: "0.5rem",
                            // marginLeft: -15,
                            borderRight: "1px solid #ddd",
                            color: "orange",
                          }}
                        >
                          Meet
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            padding: "0.5rem",
                            fontSize: 12,
                            borderRight: "1px solid #ddd",
                            color: "red",
                          }}
                        >
                          Below
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            color: "#006bf7",
                          }}
                        >
                          Waiting for Approval
                        </Col> */}
                      </Row>
                      {/* <Row noGutters> */}

                      {/* <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            marginLeft: -20,
                            fontWeight: "bold",
                            fontSize: 12,
                            borderRight: "1px solid #ddd",
                            color: "green",
                          }}
                        >
                          Exceed
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            // marginLeft: -15,
                            borderRight: "1px solid #ddd",
                            color: "orange",
                          }}
                        >
                          Meet
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            borderRight: "1px solid #ddd",
                            color: "red",
                          }}
                        >
                          Below
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            marginLeft: -20,
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {kpiMonthly?.[0]?.Exceed}
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",
                            // marginLeft: -2,
                          }}
                        >
                          {kpiMonthly?.[0]?.Meet}
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {kpiMonthly?.[0]?.Below}
                        </Col> */}
                      {/* </Row> */}
                    </Card.Body>
                  </Card>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartmonthlyreport");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="2">
                      <div className="text-center">
                        <i
                          className="fas fa-clipboard-list  fa-2x"
                          style={{ color: "#fcb90a" }}
                        ></i>
                      </div>
                    </Col>
                    <Card.Title style={{ marginLeft: 10 }} as="h4">
                      CS Visit & Findings
                    </Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr></hr>
                  <Card style={{ margin: "2px" }}>
                    <Card.Body>
                      <Row noGutters>
                        <Col className="p1 d-flex align-items-center">
                          <Card.Body>
                            <Col noGutters style={{ marginTop: -20 }}>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  borderBottom: "1px solid #ddd",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  sm="7"
                                  className="p-1 d-flex justify-content-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "green",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Exceed
                                </Col>
                                <Col
                                  sm="0.5"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  :
                                </Col>
                                <Col
                                  sm="5"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {kpiCsVisit?.[0]?.Exceed}
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  borderBottom: "1px solid #ddd",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  sm="7"
                                  className="p-1 d-flex justify-content-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "orange",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Meet
                                </Col>
                                <Col
                                  sm="0.5"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  :
                                </Col>
                                <Col
                                  sm="5"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {kpiCsVisit?.[0]?.Meet}
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  borderBottom: "1px solid #ddd",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  sm="7"
                                  className="p-1 d-flex justify-content-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "red",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Below
                                </Col>
                                <Col
                                  sm="0.5"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  :
                                </Col>
                                <Col
                                  sm="5"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {kpiCsVisit?.[0]?.Below}
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  flexWrap: "nowrap",
                                }}
                              >
                                <Col
                                  sm="7"
                                  className="p-1 d-flex justify-content-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Status
                                </Col>
                                <Col
                                  sm="0.5"
                                  className="p-1 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  :
                                </Col>
                                <Col
                                  sm="5"
                                  className="p-2 d-flex justify-content-center"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    padding: "2px 5px",
                                    height: "auto",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    color:
                                      csVisit.grafikCsVisit?.status === "Below"
                                        ? "red"
                                        : csVisit.grafikCsVisit?.status ===
                                          "Meet"
                                        ? "orange"
                                        : "green",
                                  }}
                                >
                                  {csVisit.grafikCsVisit?.status}
                                </Col>
                              </Row>
                            </Col>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartcsvisit");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="2">
                      <div className="text-center">
                        <i
                          className="fas fa-file-signature  fa-2x"
                          style={{ color: "#673DD3" }}
                        ></i>
                      </div>
                    </Col>
                    <Card.Title style={{ marginLeft: 10 }} as="h4">
                      CS Review
                    </Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr></hr>
                  <Card style={{ margin: "2px" }}>
                    <Card.Body>
                      <Row noGutters>
                        <Col className="p1 d-flex align-items-center">
                          <Card.Body>
                            <Col noGutters style={{ marginTop: -20 }}>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  // borderBottom: "1px solid #ddd",
                                  flexDirection: "column", // Tampilan vertikal untuk semua elemen
                                }}
                              >
                                {kpiCsReview?.map((val, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      borderBottom: "1px solid #ddd", // Garis bawah setiap elemen
                                      padding: "2px 5px", // Memberikan jarak antar baris
                                    }}
                                  >
                                    <Col
                                      sm="7"
                                      className="p-1 d-flex justify-content-start"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 12,
                                        padding: "2px 5px",
                                        height: "auto",
                                        width: "auto",
                                        display: "flex",
                                        alignItems: "center",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {val.namaKategori}
                                    </Col>
                                    <Col
                                      sm="0.5"
                                      className="p-1 d-flex justify-content-center"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 14,
                                        padding: "2px 5px",
                                        height: "auto",
                                        width: "auto",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      :
                                    </Col>
                                    <Col
                                      sm="5"
                                      className="p-2 d-flex justify-content-center"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 12,
                                        padding: "2px 5px",
                                        height: "auto",
                                        width: "auto",
                                        display: "flex",
                                        alignItems: "center",
                                        color:
                                          val.nilaiAkhir === "Below"
                                            ? "red"
                                            : val.nilaiAkhir === "Meet"
                                            ? "orange"
                                            : "green",
                                      }}
                                    >
                                      {val.nilaiAkhir}
                                    </Col>
                                  </div>
                                ))}
                              </Row>
                              <Row
                                style={{
                                  marginLeft: -18,
                                  flexDirection: "column", // Tampilan vertikal untuk semua elemen
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderBottom: "1px solid #ddd", // Garis bawah setiap elemen
                                    padding: "2px 5px", // Memberikan jarak antar baris
                                  }}
                                >
                                  <Col
                                    sm="7"
                                    className="p-1 d-flex justify-content-start"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 12,
                                      padding: "2px 5px",
                                      height: "auto",
                                      width: "auto",
                                      display: "flex",
                                      alignItems: "center",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Status
                                  </Col>
                                  <Col
                                    sm="0.5"
                                    className="p-1 d-flex justify-content-center"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 14,
                                      padding: "2px 5px",
                                      height: "auto",
                                      width: "auto",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    :
                                  </Col>
                                  <Col
                                    sm="5"
                                    className="p-2 d-flex justify-content-center"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 14,
                                      padding: "2px 5px",
                                      height: "auto",
                                      width: "auto",
                                      display: "flex",
                                      alignItems: "center",
                                      color:
                                        csReview?.grafikCsReview?.status ===
                                        "Below"
                                          ? "red"
                                          : csReview?.grafikCsReview?.status ===
                                            "Meet"
                                          ? "orange"
                                          : "green",
                                    }}
                                  >
                                    {csReview?.grafikCsReview?.status}
                                  </Col>
                                </div>
                              </Row>
                            </Col>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartcsreview");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row></Row>
        </>
      </Container>
    </>
  );
}

export default DashboardBakalan;
