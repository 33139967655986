import axios from "axios";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reduxSoldierMiddleware } from "redux-soldier";

// reducer
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import monitoringReducer from "./monitoring";
import surveyReducer from "./survey";
import trainingReducer from "./training";
import pestRodentReducer from "./pestRodent";
import safetyReducer from "./safety";
import sidakReducer from "./sidak";
import itProjectReducer from "./itProject";
import consumableReducer from "./consumable";
import shiftReportReducer from "./shiftReport";
import pembinaanReducer from "./pembinaan";
import penampilanReducer from "./pembinaanPenampilan";
import absenReducer from "./absen";
import targetReducer from "./target";
import cabangReducer from "./cabang";
import incidentReducer from "./incident";
import uniformChecklistReducer from "./UniformChecklist";
import monthlyReportReducer from "./MonthlyReport";
import coordinationReducer from "./coordination";
import csVisitReducer from "./csVisit";
import csReviewReducer from "./csReview";
// function
export * from "./auth/function";
export * from "./monitoring/function";
export * from "./training/function";
export * from "./pestRodent/function";
export * from "./errorHandler";
export * from "./survey/function";
export * from "./safety/function";
export * from "./sidak/function";
export * from "./itProject/function";
export * from "./consumable/function";
export * from "./shiftReport/function";
export * from "./pembinaan/function";
export * from "./pembinaanPenampilan/function";
export * from "./absen/function";
export * from "./target/function";
export * from "./cabang/function";
export * from "./incident/function";
export * from "./UniformChecklist/function";
export * from "./MonthlyReport/function";
export * from "./coordination/function";
export * from "./csVisit/function";
export * from "./csReview/function";

// server api
// export const baseUrl = process.env.REACT_APP_URL;
export const baseUrl = "https://apijtiism.ptbap.net/";
// export const baseUrl = "http://103.161.185.49:9030/";
// export const baseUrl = "http://localhost:9030/";
// export const baseUrl = "http://192.168.110.242:9030/";

export const baseAxios = axios.create({
  baseURL: baseUrl,
  // timeout: 1000000,
});

const rootReducer = combineReducers({
  authReducer,
  dashboardReducer,
  monitoringReducer,
  surveyReducer,
  trainingReducer,
  pestRodentReducer,
  safetyReducer,
  sidakReducer,
  itProjectReducer,
  consumableReducer,
  shiftReportReducer,
  pembinaanReducer,
  penampilanReducer,
  absenReducer,
  targetReducer,
  cabangReducer,
  incidentReducer,
  uniformChecklistReducer,
  monthlyReportReducer,
  coordinationReducer,
  csVisitReducer,
  csReviewReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxSoldierMiddleware))
);
