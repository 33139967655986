import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Modal,
  Table,
  Container,
  Row,
  Col,
  Rea,
} from "react-bootstrap";
import Select from "react-select";
import ReactTable from "components/ReactTable/ReactTable.js";
import ReactDatetime from "react-datetime";

import {
  getShift,
  daftarShift,
  deleteShift,
  getAllCabang,
  getCabangAktif,
  getTargetPatroli,
  createTarget,
  editTargetCabang,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import TagsInput from "components/TagsInput/TagsInput.js";
import moment from "moment";

function RegularTables() {
  const dispatch = useDispatch();
  const [modal, setModal] = React.useState(false);
  const auth = useSelector((state) => state.authReducer);

  const [durasi, setDurasi] = React.useState("");
  const monitoring = useSelector((state) => state.monitoringReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const [namaShift, setNamaShift] = React.useState("");
  const [jamStr, setJamStr] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [client, setClient] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  const [idShift, setIdShift] = React.useState({
    value: "",
    label: "Choose the Shift",
    isDisabled: true,
  });
  const [listCabang, setListCabang] = React.useState([]);
  const [listShift, setListShift] = React.useState([]);
  const date = new Date();
  const [tanggal, setTanggal] = React.useState(new Date().setHours(7, 0, 0, 0));
  const [target, setTarget] = React.useState("");
  const [modalEditTarget, setModalEditTarget] = React.useState(false);
  const [namaClient, setNamaClient] = React.useState("");
  const [editTarget, setEditTarget] = React.useState("");
  const [shift, setShift] = React.useState("");
  const [tanggalEdit, setTanggalEdit] = React.useState("");
  const [idTarget, setIdTarget] = React.useState("");
  React.useEffect(() => {
    getTargetPatroli(dispatch);
    getShift(dispatch);
    getCabangAktif(dispatch);
    getShift(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabang.getCabangAktif]);
  function submitTarget(e) {
    e.preventDefault();
    createTarget({
      idCabang: client.idCabang,
      idClient: client.idClient,
      target: target,
      tanggal: new Date(tanggal).toISOString(),
      idShift: idShift._id,
    });
  }
  React.useEffect(() => {
    let tglSekarang = moment(new Date()).format("DD MMM YYYY");
    let tmp = monitoring.getTargetPatroli.map((val, index) => {
      let tglTarget = moment(val.tanggal).format("DD MMM YYYY");
      return {
        ...val,
        tanggal: moment(val.tanggal).format("DD MMM YYYY"),

        actions: (
          <div className="actions-right">
            <Button
              disabled={
                val.edited === true || tglSekarang != tglTarget ? true : false
              }
              onClick={() => {
                setModalEditTarget(true);
                setIdTarget(val._id);
                setShift(val.shift);
                // setNamaEdtCabang(`${val.namaCabang} - ${val.namaClient}`);
                setNamaClient(` ${val.cabang} - ${val.client}`);
                setTanggalEdit(moment(val.tanggal).format("DD MMM YYYY"));
                // downloadPDF(val2._id, val2.area);
              }}
              variant="warning"
              size="md"
              className="btn-link remove text-warning"
            >
              <i className="fa fa-edit fa-lg" />
            </Button>
            {/* <Button
              onClick={() => {
                downloadPDF(val._id, val.area);
              }}
              variant="danger"
              size="md"
              className="btn-link remove text-info"
            >
              <i className="fa fa-download fa-lg" />
            </Button> */}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [monitoring.getTargetPatroli]);
  React.useEffect(() => {
    let tmp = monitoring.jamLaporan.map((val, index) => {
      return {
        ...val,
        label: `${val.namaShift} - ${val.namaCabang} - ${val.namaPerusahaan}`,
        value: val.idCabang,
      };
    });
    setListShift(tmp);
  }, [monitoring.jamLaporan]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            {/* {auth.role === "all" ||
            auth.role === "gsc" ||
            auth.role === "market" ? (
              <>
                <Button
                  className="btn-wd mr-1"
                  onClick={() => {
                    setModal(true);
                  }}
                  variant="primary"
                >
                  Add Target
                </Button>
              </>
            ) : (
              ""
            )} */}
          </Col>
        </Row>
        <Row>
          {auth.role === "all" ||
          auth.role === "gsc" ||
          auth.role === "market" ? (
            <>
              <Col md={"12"}>
                <Card>
                  <Card.Body>
                    <ReactTable
                      data={data}
                      columns={[
                        {
                          Header: "Shift",
                          accessor: "shift",
                        },
                        {
                          Header: "Date",
                          accessor: "tanggal",
                        },
                        {
                          Header: "Target",
                          accessor: "target",
                        },

                        {
                          Header: "Client Name",
                          accessor: "client",
                        },
                        {
                          Header: "Branch Name",
                          accessor: "cabang",
                        },

                        {
                          Header: "Actions",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      className="-striped -highlight primary-pagination"
                    />
                  </Card.Body>
                </Card>
              </Col>
            </>
          ) : (
            <>
              <Col md={"12"}>
                <Card>
                  <Card.Body>
                    <ReactTable
                      data={data}
                      columns={[
                        {
                          Header: "Shift",
                          accessor: "shift",
                        },
                        {
                          Header: "Date",
                          accessor: "tanggal",
                        },
                        {
                          Header: "Target",
                          accessor: "target",
                        },

                        {
                          Header: "Client Name",
                          accessor: "client",
                        },
                        {
                          Header: "Branch Name",
                          accessor: "cabang",
                        },

                        // {
                        //   Header: "Actions",
                        //   accessor: "actions",
                        //   sortable: false,
                        //   filterable: false,
                        // },
                      ]}
                      className="-striped -highlight primary-pagination"
                    />
                  </Card.Body>
                </Card>
              </Col>
            </>
          )}
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Card className="stacked-form">
                <Card.Header>
                  <Card.Title as="h4">Add Target Patroli</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form action="#" method="#">
                    <Form.Group>
                      <label>Client's Name</label>
                      <Select
                        className="react-select primary pb-2"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        onChange={(value) => {
                          setClient(value);
                        }}
                        value={client}
                        options={[
                          {
                            value: "",
                            label: "Choose the Client",
                            isDisabled: true,
                          },
                          ...listCabang,
                          "-",
                        ]}
                        placeholder="Single Select"
                      />
                    </Form.Group>
                    <Form.Group>
                      <label>Select Date</label>
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Date Picker Here",
                          timeZone: "Asia/Jakarta",
                        }}
                        onChange={(e) => {
                          setTanggal(e.toDate().setHours(7, 0, 0, 0));
                        }}
                        value={tanggal}
                        initialValue={tanggal}
                        timeFormat={false}
                      ></ReactDatetime>
                    </Form.Group>
                    <Form.Group>
                      <label>Shift</label>
                      <Select
                        className="react-select primary pb-2"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        onChange={(value) => {
                          setIdShift(value);
                        }}
                        value={idShift}
                        options={[
                          {
                            value: "",
                            label: "Choose the Shift",
                            isDisabled: true,
                          },
                          ...listShift,
                          "-",
                        ]}
                        placeholder="Single Select"
                      />
                    </Form.Group>
                    <Form.Group>
                      <label>Target</label>
                      <Form.Control
                        // placeholder="Durasi pengerjaan"
                        type="number"
                        onChange={(e) => {
                          setTarget(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Card.Body>
                <Card.Footer>
                  <Button
                    onClick={submitTarget}
                    className="btn-fill"
                    type="submit"
                    variant="info"
                  >
                    Submit
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={modalEditTarget}
        onHide={() => {
          setModalEditTarget(false);
        }}
        onClick={() => {}}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Edit Target</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Client's Name</label>
                          <br></br>
                          <h5>{namaClient}</h5>
                          <label>Tanggal Target</label>
                          <br></br>
                          <h5>{tanggalEdit}</h5>
                          <label>Shift</label>
                          <br></br>
                          <h5>{shift}</h5>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label>Target</label>
                          <Form.Control
                            // value={tampilKodeRuang}
                            onChange={(e) => {
                              setEditTarget(e.target.value);
                            }}
                            placeholder="Masukan target sesuai jumlah titik patroli"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        e.preventDefault();
                        editTargetCabang({
                          _id: idTarget,
                          target: editTarget,
                        });
                      }}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RegularTables;
