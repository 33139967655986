import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Modal,
  Tab,
  Image,
} from "react-bootstrap";
import Select from "react-select";
// import Chart from "react-apexcharts";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactDatetime from "react-datetime";
import logoBAP from "../assets/img/image.jpg";
import Swal from "sweetalert2";
import {
  getKpi1Training,
  getKpiReportShift,
  getKpi2Pembinaan,
  getKpi2PembinaanPenampilan,
  getKpi1Safety,
  getKpi1Sidak,
  getGrafikAbsen,
  getKpiPatroli,
  getKpiMonitoringSurvey,
  getTargetByMonth,
  getTargetByMonthCabang,
  getCabangAktif,
  getCabangDashboard,
  // getAchievementPatroli,
  getAchievementShift,
  getClient,
  exportAllData,
  exportKpi,
  getKpiDashboard,
} from "../stores";
import DashboardBakalan from "./DashboardBakalan";
import { CardHeader } from "reactstrap";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Panels() {
  const history = useHistory();
  const dispatch = useDispatch();
  const trainingPersonnel = useSelector((state) => state.trainingReducer);
  const listShift = useSelector((state) => state.shiftReportReducer);
  const pembinaan = useSelector((state) => state.pembinaanReducer);
  const penampilan = useSelector((state) => state.penampilanReducer);
  const safety = useSelector((state) => state.safetyReducer);
  const auth = useSelector((state) => state.authReducer);
  const sidak = useSelector((state) => state.sidakReducer);
  const absen = useSelector((state) => state.absenReducer);
  const monitoring = useSelector((state) => state.monitoringReducer);
  const target = useSelector((state) => state.targetReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  // const issue = useSelector((state) => state.industrialReducer);
  // const survey = useSelector((state) => state.surveyReducer);
  // const pestRodent = useSelector((state) => state.pestRodentReducer);
  // const projectIt = useSelector((state) => state.itProjectReducer);
  // const monitoring = useSelector((state) => state.monitoringReducer);
  // const consumable = useSelector((state) => state.consumableReducer);
  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
  );
  const [persenIsmCabang, setPersenIsmCabang] = React.useState("");
  const [smpTgl, setSmpTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 0, 0)
  );
  const [filter, setFilter] = React.useState({
    tahun: new Date().getFullYear(),
  });
  const [totalKpi, setTotalKpi] = React.useState("");
  const [dashboardUtama, setDashboardUtama] = React.useState(true);
  const [openBakalan, setOpenBakalan] = React.useState(false);
  const [cardUtama, setCardUtama] = React.useState(false);
  const [openCard, setOpenCard] = React.useState(false);
  const [kirimIdCabang, setKirimIdCabang] = React.useState("");
  const [client, setClient] = React.useState([]);
  const [listCabang, setListCabang] = React.useState([]);
  const [rekap, setRekap] = React.useState([]);
  const [targetGsc, setTargetGsc] = React.useState(0);
  const [targetMarket, setTargetMarket] = React.useState(0);
  const [persenTotal, setPersenTotal] = React.useState(0);
  const [persenGsc, setPersenGsc] = React.useState(0);
  const [persenMarket, setPersenMarket] = React.useState(0);
  const [cardGsc, setCardGsc] = React.useState(false);
  const [cardMarket, setCardMarket] = React.useState(false);
  // Card Patrol
  const [kpiPatrol, setKpiPatrol] = React.useState(null);

  const [modalExport, setModalExport] = React.useState(false);
  const [clientDropdown, setClientDropdown] = React.useState({
    value: "",
    label: "Choose the client",
    isDisabled: true,
  });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getCabangAktif(dispatch);
    getClient(dispatch);

    getCabangDashboard(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    // getAchievementPatroli(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    getAchievementShift(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi1Training(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpiReportShift(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi1Safety(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi2Pembinaan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi2PembinaanPenampilan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi1Sidak(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getGrafikAbsen(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpiPatroli(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: auth.idCabang,
    });
    getTargetByMonth(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getTargetByMonthCabang(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getKpiMonitoringSurvey(dispatch, filter);
  }, [filter]);

  React.useEffect(() => {
    // monitoring.kpi1 &&
    // monitoring.kpi2 &&
    // monitoring.kpi4 &&
    // monitoring.kpi3 &&
    trainingPersonnel.kpi1 &&
    listShift.kpi &&
    safety.kpi1 &&
    pembinaan.kpi2 &&
    penampilan.kpiPenampilan &&
    // sidak.kpi1 &&
    absen.kpiAbsen &&
    monitoring.kpiPatroli
      ? // &&
        // trainingPersonnel.kpi2 &&
        // consumable.kpi1 &&
        // pestRodent.kpi1 &&
        // pestRodent.kpi2 &&
        // projectIt.kpi1 &&
        // safety.kpi1 &&
        // issue.kpi1 &&
        // issue.kpi2 &&
        // projectIt.kpi2 &&
        // projectIt.kpi3 &&
        // trainingPersonnel.kpi3 &&
        // survey.variablesurvey
        (setPersenIsmCabang(
          Math.round(
            (trainingPersonnel.kpi1.pencapaian +
              listShift.kpi.pencapaian +
              (safety.kpi1.F2 +
                safety.kpi1.FAC2 +
                safety.kpi1.LTI2 +
                safety.kpi1.MTC2 +
                safety.kpi1.NM2 +
                safety.kpi1.RWC2) /
                6 +
              pembinaan.kpi2.kejadian +
              penampilan.kpiPenampilan.kejadian +
              absen.kpiAbsen.pencapaian +
              monitoring.kpiPatroli.pencapaian) /
              7
          )
          // Math.round(
          //   (monitoring.kpi1.pencapaian +
          //     monitoring.kpi2.pencapaian +
          //     monitoring.kpi3.pencapaian +
          //     monitoring.kpi4.pencapaian +
          //     consumable.kpi1.pencapaian +
          //     trainingPersonnel.kpi1.pencapaian +
          //     trainingPersonnel.kpi2.pencapaian +
          //     pestRodent.kpi1.pencapaian +
          //     pestRodent.kpi2.pencapaian +
          //     projectIt.kpi1.pencapaian +
          //     safety.kpi1.pencapaian +
          //     issue.kpi1.pencapaian +
          //     issue.kpi2.pencapaian +
          //     projectIt.kpi2.pencapaian +
          //     projectIt.kpi3.pencapaian +
          //     trainingPersonnel.kpi3.pencapaian +
          //     survey.variablesurvey.pencapaian) /
          //     17
          // )
        ),
        setTotalKpi(
          trainingPersonnel.kpi1.pencapaian +
            listShift.kpi.pencapaian +
            safety.kpi1.F2 +
            safety.kpi1.FAC2 +
            safety.kpi1.LTI2 +
            safety.kpi1.MTC2 +
            safety.kpi1.NM2 +
            safety.kpi1.RWC2 +
            pembinaan.kpi2.kejadian +
            penampilan.kpiPenampilan.kejadian +
            absen.kpiAbsen.pencapaian +
            monitoring.kpiPatroli.pencapaian
          // monitoring.kpi1.pencapaian +
          //   monitoring.kpi2.pencapaian +
          //   monitoring.kpi3.pencapaian +
          //   monitoring.kpi4.pencapaian +
          //   consumable.kpi1.pencapaian +
          //   trainingPersonnel.kpi1.pencapaian +
          //   trainingPersonnel.kpi2.pencapaian +
          //   pestRodent.kpi1.pencapaian +
          //   pestRodent.kpi2.pencapaian +
          //   projectIt.kpi1.pencapaian +
          //   safety.kpi1.pencapaian +
          //   issue.kpi1.pencapaian +
          //   issue.kpi2.pencapaian +
          //   projectIt.kpi2.pencapaian +
          //   projectIt.kpi3.pencapaian +
          //   trainingPersonnel.kpi3.pencapaian +
          //   survey.variablesurvey.pencapaian
        ))
      : setPersenIsmCabang("100");
  });
  // React.useEffect(() => {
  //   setPersenIsmCabang(
  //     Math.round(
  //       (monitoring.kpiPatroli.pencapaian + listShift.kpi.pencapaian) / 2
  //     )
  //       ? Math.round(
  //           (monitoring.kpiPatroli.pencapaian + listShift.kpi.pencapaian) / 2
  //         )
  //       : 0
  //   );
  // });
  // React.useEffect(() => {
  //   if (
  //     target.getTargetByMonth.targetCabang &&
  //     target.getTargetByMonth.targetCabang
  //   ) {
  //     let tmp = target.getTargetByMonth.targetCabang.map((val, index) => {
  //       return {
  //         ...val,
  //       };
  //     });
  //     // setCabang(tmp); // pastikan ini tidak di-comment
  //   }
  // }, [target.getTargetByMonth]);
  React.useEffect(() => {
    let client = cabang.getClient.map((val, index) => {
      return { ...val, idClient: val.idClient };
    });
    setClient(client);
  }, [cabang.getClient]);
  React.useEffect(() => {
    // let tmp = cabang.getCabangDashboard.map((val, index) => {
    //   return {
    //     ...val,
    //   };
    // });
    setLoading(true);

    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
      };
    });
    setListCabang(tmp);

    let targetGsc = 0;
    let targetMarket = 0;

    tmp.map((val) => {
      if (val.idClient === "73") {
        targetGsc += val.totalTarget;
      } else {
        targetMarket += val.totalTarget;
      }
    });
    setTargetGsc(targetGsc);
    setTargetMarket(targetMarket);

    let tmpShift = [];
    let tmpPatroli = [];
    let promises = [];
    let tmpKpi = [];

    for (let i = 0; i < tmp.length; i++) {
      let promise = getKpiDashboard(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
        idCabangWeb: tmp[i].idCabang,
        idClientWeb: tmp[i].idClient,
      }).then((response) => {
        tmpKpi.push({
          idCabang: tmp[i].idCabang,
          idClient: tmp[i].idClient,
          kpiCabang: response,
        });
      });
      // let promise = getKpiReportShiftDashboard(dispatch, {
      //   dariTgl: dariTgl,
      //   smpTgl: smpTgl,
      //   idCabangWeb: tmp[i].idCabang,
      // }).then((results) => {
      //   tmpShift.push({
      //     idCabang: tmp[i].idCabang,
      //     pencapaianShift: results.data.pencapaian,
      //     targetShift: results.data.target,
      //   });
      // });
      // let promise2 = getKpiPatroliDashboard(dispatch, {
      //   dariTgl: dariTgl,
      //   smpTgl: smpTgl,
      //   idCabangWeb: tmp[i].idCabang,
      // }).then((results) => {
      //   tmpPatroli.push({
      //     idCabang: tmp[i].idCabang,
      //     pencapaianPatroli: results.data.pencapaian,
      //     targetPatroli: results.data.target,
      //   });
      // });
      promises.push(promise);
      // promises.push(promise2);
      // promises2.push(promise2);
    }

    Promise.all(promises).then((resul) => {
      let gabungan = [];
      tmpKpi.map((val) => {
        setLoading(false);
        gabungan.push({
          idClient: val.idClient,
          idCabang: val.idCabang,
          kpiCabang: val.kpiCabang,
        });
      });
      // tmpShift.map((val) => {
      //   tmpPatroli.map((val2) => {
      //     if (val.idCabang === val2.idCabang) {
      //       const idClient = tmp.find(
      //         (c) => c.idCabang === val.idCabang
      //       )?.idClient;
      //       gabungan.push({
      //         idClient: idClient,
      //         idCabang: val.idCabang,
      //         capaianShift: val.pencapaianShift,
      //         targetShift: val.targetShift,
      //         capaianPatroli: val2.pencapaianPatroli,
      //         targetPatroli: val2.targetPatroli,
      //         persenCapaian: Math.round(
      //           (val.pencapaianShift + val2.pencapaianPatroli) / 2
      //         ),
      //         persenTarget: Math.round(
      //           (val.targetShift + val2.targetPatroli) / 2
      //         ),
      //       });
      //     }
      //   });
      // });
      setRekap(gabungan);

      // let totalPersenCapaian = 0;
      // gabungan.map((val) => {
      //   totalPersenCapaian += val.kpiCabang;
      // });
      // setPersenTotal(Math.round(totalPersenCapaian / tmp.length));

      let capaianGsc = 0;
      let capaianMarket = 0;
      gabungan.map((val) => {
        if (val.idClient === "73") {
          capaianGsc += val.kpiCabang;
        } else {
          capaianMarket += val.kpiCabang;
        }
      });
      setPersenGsc(Math.round(capaianGsc / 3));
      setPersenMarket(Math.round(capaianMarket / 6));
      setPersenTotal(
        Math.round(
          (Math.round(capaianGsc / 3) + Math.round(capaianMarket / 6)) / 2
        )
      );
    });
  }, [cabang.getCabangDashboard]);
  React.useEffect(() => {
    if (monitoring.kpiPatroli) {
      setKpiPatrol(monitoring.kpiPatroli.data);
    }
  }, [monitoring.kpiPatroli]);
  React.useEffect(() => {
    if (monitoring.kpiDashboard) {
      // setKpiDashboard(monitoring.kpiDashboard);
    }
  }, [monitoring.kpiDashboard]);

  function openModalExport(e) {
    if (auth.role === "all") {
      if (clientDropdown.value === "") {
        e.preventDefault();
        Swal.fire({
          title: "Please choose the client!",
          icon: "warning",
        });
      } else {
        e.preventDefault();
        Swal.fire({
          title: "Apakah anda yakin akan mengekspor data ISM?",
          html: `
          <table style="width: 100%;">
               <tr>
                 <td style="text-align: left; width:25%;">Start Date</td>
                 <td>:</td>
                 <td style="text-align: left;">${moment(dariTgl).format(
                   "MMMM YYYY"
                 )}</td>
               </tr>
               <tr>
                 <td style="text-align: left;">End Date</td>
                 <td>:</td>
                 <td style="text-align: left;">${moment(smpTgl).format(
                   "MMMM YYYY"
                 )}</td>
               </tr>
               <tr>
                 <td style="text-align: left;">Client</td>
                 <td>:</td>
                 <td style="text-align: left;">${
                   auth.role === "all"
                     ? clientDropdown.label
                     : auth.role === "gsc"
                     ? "PT. Karyadibya Mahardhika (JTI - GSC)"
                     : "PT. Karyadibya Mahardhika (JTI - Market)"
                 }</td>
               </tr>
    
             </table> `,
          icon: "question",
          allowOutsideClick: false,
          showDenyButton: true,
          showCancelButton: true,
          showLoaderOnConfirm: true,
          showLoaderOnDeny: true,
          denyButtonText: "Export KPI",
          confirmButtonText: "Export Monthly Report",
          allowOutsideClick: () => !Swal.isLoading(),
          preConfirm: async () => {
            try {
              const data = await exportAllData({
                dariTgl: dariTgl,
                smpTgl: smpTgl,
                idClientWeb: clientDropdown.value,
                idCabang: "",
              });
              const url = window.URL.createObjectURL(new Blob([data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `Data Monthly Report ${
                  auth.role === "all"
                    ? clientDropdown.label
                    : auth.role === "gsc"
                    ? "PT. Karyadibya Mahardhika (JTI - GSC)"
                    : "PT. Karyadibya Mahardhika (JTI - Market)"
                } ${moment(dariTgl).format("MMMM YYYY")} - ${moment(
                  smpTgl
                ).format("MMMM YYYY")} .xlsx`
              );
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              // window.location.reload();
              Swal.fire({
                icon: "success",
                title: "Data berhasil diexport",
                timer: 1500,
                showConfirmButton: false,
                willClose: () => Swal.hideLoading(),
              });
            } catch (error) {
              Swal.fire({
                title: "Terjadi kesalahan",
                text: error.message,
                icon: "error",
                willClose: () => Swal.hideLoading(),
              });
            }
          },
          preDeny: async () => {
            try {
              const data = await exportKpi({
                dariTgl: dariTgl,
                smpTgl: smpTgl,
                idClientWeb: clientDropdown.value,
                idCabang: "",
              });
              const url = window.URL.createObjectURL(new Blob([data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `Data KPI ${
                  auth.role === "all"
                    ? clientDropdown.label
                    : auth.role === "gsc"
                    ? "PT. Karyadibya Mahardhika (JTI - GSC)"
                    : "PT. Karyadibya Mahardhika (JTI - Market)"
                } ${moment(dariTgl).format("MMMM YYYY")} - ${moment(
                  smpTgl
                ).format("MMMM YYYY")}.xlsx`
              );
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              // window.location.reload();
              Swal.fire({
                icon: "success",
                title: "Data berhasil diexport",
                timer: 1500,
                showConfirmButton: false,
                willClose: () => Swal.hideLoading(),
              });
            } catch (error) {
              Swal.fire({
                title: "Terjadi kesalahan",
                text: error.message,
                icon: "error",
                willClose: () => Swal.hideLoading(),
              });
            }
          },
        });
      }
    } else {
      e.preventDefault();
      Swal.fire({
        title: "Apakah anda yakin akan mengekspor data ISM?",
        html: `
        <table style="width: 100%;">
             <tr>
               <td style="text-align: left; width:25%;">Start Date</td>
               <td>:</td>
               <td style="text-align: left;">${moment(dariTgl).format(
                 "MMMM YYYY"
               )}</td>
             </tr>
             <tr>
               <td style="text-align: left;">End Date</td>
               <td>:</td>
               <td style="text-align: left;">${moment(smpTgl).format(
                 "MMMM YYYY"
               )}</td>
             </tr>
             <tr>
               <td style="text-align: left;">Client</td>
               <td>:</td>
               <td style="text-align: left;">${
                 auth.role === "all"
                   ? clientDropdown.label
                   : auth.role === "gsc"
                   ? "PT. Karyadibya Mahardhika (JTI - GSC)"
                   : "PT. Karyadibya Mahardhika (JTI - Market)"
               }</td>
             </tr>
  
           </table> `,
        icon: "question",
        allowOutsideClick: false,
        showDenyButton: true,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        showLoaderOnDeny: true,
        denyButtonText: "Export KPI",
        confirmButtonText: "Export Monthly Report",
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async () => {
          try {
            const data = await exportAllData({
              dariTgl: dariTgl,
              smpTgl: smpTgl,
              idClientWeb: clientDropdown.value,
              idCabang: "",
            });
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Data Monthly Report ${
                auth.role === "all"
                  ? clientDropdown.label
                  : auth.role === "gsc"
                  ? "PT. Karyadibya Mahardhika (JTI - GSC)"
                  : "PT. Karyadibya Mahardhika (JTI - Market)"
              } ${moment(dariTgl).format("MMMM YYYY")} - ${moment(
                smpTgl
              ).format("MMMM YYYY")} .xlsx`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // window.location.reload();
            Swal.fire({
              icon: "success",
              title: "Data berhasil diexport",
              timer: 1500,
              showConfirmButton: false,
              willClose: () => Swal.hideLoading(),
            });
          } catch (error) {
            Swal.fire({
              title: "Terjadi kesalahan",
              text: error.message,
              icon: "error",
              willClose: () => Swal.hideLoading(),
            });
          }
        },
        preDeny: async () => {
          try {
            const data = await exportKpi({
              dariTgl: dariTgl,
              smpTgl: smpTgl,
              idClientWeb: clientDropdown.value,
              idCabang: "",
            });
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Data KPI ${
                auth.role === "all"
                  ? clientDropdown.label
                  : auth.role === "gsc"
                  ? "PT. Karyadibya Mahardhika (JTI - GSC)"
                  : "PT. Karyadibya Mahardhika (JTI - Market)"
              } ${moment(dariTgl).format("MMMM YYYY")} - ${moment(
                smpTgl
              ).format("MMMM YYYY")}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // window.location.reload();
            Swal.fire({
              icon: "success",
              title: "Data berhasil diexport",
              timer: 1500,
              showConfirmButton: false,
              willClose: () => Swal.hideLoading(),
            });
          } catch (error) {
            Swal.fire({
              title: "Terjadi kesalahan",
              text: error.message,
              icon: "error",
              willClose: () => Swal.hideLoading(),
            });
          }
        },
      });
    }
  }
  return (
    <>
      <Modal
        size="lg"
        show={modalExport}
        onHide={() => {
          setModalExport(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Export Data ISM</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        {/* <Row>
                          <Col sm="6">
                            <label>Start Date</label>
                            <Form.Group>
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Date Picker Here",
                                  readOnly: true,
                                  style: {
                                    cursor: "default",
                                    backgroundColor: "white",
                                    color: "black !important",
                                  },
                                }}
                                onChange={(e) => {
                                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                                }}
                                value={dariTgl}
                                initialValue={dariTgl}
                                timeFormat={false}
                                dateFormat="DD MMM YYYY"
                              ></ReactDatetime>
                            </Form.Group>
                          </Col>
                          <Col sm="6">
                            <label>End Date</label>
                            <Form.Group>
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Date Picker Here",
                                  readOnly: true,
                                  style: {
                                    cursor: "default",
                                    backgroundColor: "white",
                                    color: "black !important",
                                  },
                                }}
                                onChange={(e) => {
                                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                                }}
                                value={smpTgl}
                                initialValue={smpTgl}
                                timeFormat={false}
                                dateFormat="DD MMM YYYY"
                              ></ReactDatetime>
                            </Form.Group>
                          </Col>
                        </Row> */}
                        <Form.Group>
                          {auth.role === "all" ? (
                            <>
                              <label>Client's Name</label>
                              <Select
                                className="react-select primary pb-2"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                onChange={(value) => {
                                  setClientDropdown(value);
                                }}
                                value={clientDropdown}
                                options={[
                                  {
                                    value: "",
                                    label: "Chooese the client!",
                                    isDisabled: true,
                                  },
                                  {
                                    value: "73",
                                    label:
                                      "PT. Karyadibya Mahardhika (JTI - GSC)",
                                  },
                                  {
                                    value: "143",
                                    label:
                                      "PT. Karyadibya Mahardhika (JTI - Market)",
                                  },
                                  // ...listCabang,
                                  "-",
                                ]}
                                placeholder="Single Select"
                              />
                            </>
                          ) : null}
                        </Form.Group>
                      </Col>

                      <>
                        <Col>
                          <Button
                            className="btn-fill pull-right"
                            type="submit"
                            variant="info"
                            onClick={openModalExport}
                          >
                            Export Data
                          </Button>
                        </Col>
                      </>
                    </Row>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Container fluid>
        {openBakalan ? (
          <Button
            style={{ backgroundColor: "#04293A" }}
            onClick={() => (setDashboardUtama(true), setOpenBakalan(false))}
          >
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </Button>
        ) : null}

        {dashboardUtama ? (
          <>
            <Row>
              <Col md="12">
                <Card style={{ backgroundColor: "#04293A" }}>
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{ backgroundColor: "white" }}
                  >
                    <Card.Title as="h3">
                      <Row>
                        <Col md="8" className="p-3">
                          <b>
                            {auth.role === "gsc"
                              ? "ISM Performance Achievements GSC"
                              : auth.role === "market"
                              ? "ISM Performance Achievements Market"
                              : auth.role === "cabang"
                              ? "ISM Performance Achievements"
                              : "ISM National Performance Achievements"}
                          </b>
                        </Col>
                        <Col md="4" className="p-3 d-flex justify-content-end">
                          <Image
                            src={
                              "https://backoffice.bapguard.com/upload/logo_bap.png"
                            }
                            width="auto"
                            height="50px"
                          />
                        </Col>
                      </Row>
                    </Card.Title>
                  </Card.Header>
                  {auth.role === "cabang" ? null : (
                    <Card.Body className="d-none d-md-inline">
                      <Row>
                        <Col
                          lg="5"
                          className="d-none d-lg-flex align-items-center justify-content-center"
                        >
                          <i
                            className="fas fa-user-shield fa-5x"
                            style={{ color: "white" }}
                          ></i>
                        </Col>
                        <Col lg="7" xs="12">
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b style={{ color: "white" }}>Achievement</b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  // color: persenIfm < 89 ? "#CD1818" : "#20c400",
                                  color:
                                    auth.role === "all"
                                      ? persenTotal <= 59
                                        ? "#CD1818"
                                        : persenTotal <= 79
                                        ? "#fcb90a"
                                        : "#20c400"
                                      : auth.role === "gsc"
                                      ? persenGsc <= 59
                                        ? "#CD1818"
                                        : persenGsc <= 79
                                        ? "#fcb90a"
                                        : "#20c400"
                                      : persenMarket <= 59
                                      ? "#CD1818"
                                      : persenMarket <= 79
                                      ? "#fcb90a"
                                      : "#20c400",
                                  fontFamily: "sans-serif",
                                }}
                              >
                                {loading ? (
                                  <SkeletonTheme
                                    color="#00628f"
                                    highlightColor=""
                                    baseColor="#04293A"
                                  >
                                    <Skeleton height={"100%"} width={120} />
                                  </SkeletonTheme>
                                ) : (
                                  <>
                                    <b>
                                      {auth.role === "all"
                                        ? persenTotal
                                        : auth.role === "gsc"
                                        ? persenGsc
                                        : persenMarket}
                                      %{/* {persenIfm}% */}
                                    </b>
                                  </>
                                )}
                              </Card.Title>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b style={{ color: "white" }}>Target</b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color: "#20c400",
                                  fontFamily: "sans-serif",
                                }}
                              >
                                {loading ? (
                                  <SkeletonTheme
                                    color="#00628f"
                                    highlightColor=""
                                    baseColor="#04293A"
                                  >
                                    <Skeleton height={"100%"} width={120} />
                                  </SkeletonTheme>
                                ) : (
                                  <>
                                    {auth.role === "all" ? (
                                      <b>80%</b>
                                    ) : auth.role === "gsc" ? (
                                      <b>80%</b>
                                    ) : auth.role === "market" ? (
                                      <b>80%</b>
                                    ) : (
                                      <b>80%</b>
                                    )}
                                  </>
                                )}
                              </Card.Title>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b style={{ color: "white" }}>Status</b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    auth.role === "all"
                                      ? persenTotal <= 70
                                        ? "#CD1818"
                                        : persenTotal <= 92
                                        ? "#fcb90a"
                                        : "#20c400"
                                      : auth.role === "gsc"
                                      ? persenGsc <= 70
                                        ? "#CD1818"
                                        : persenGsc <= 92
                                        ? "#fcb90a"
                                        : "#20c400"
                                      : persenMarket <= 70
                                      ? "#CD1818"
                                      : persenMarket <= 92
                                      ? "#fcb90a"
                                      : "#20c400",
                                  fontFamily: "sans-serif",
                                }}
                              >
                                {loading ? (
                                  <SkeletonTheme
                                    color="#00628f"
                                    highlightColor=""
                                    baseColor="#04293A"
                                  >
                                    <Skeleton height={"100%"} width={120} />
                                  </SkeletonTheme>
                                ) : (
                                  <>
                                    <b>
                                      {auth.role === "all"
                                        ? persenTotal <= 70
                                          ? "Below"
                                          : persenTotal <= 92
                                          ? "Meet"
                                          : "Exceed"
                                        : auth.role === "gsc"
                                        ? persenGsc <= 70
                                          ? "Below"
                                          : persenGsc <= 92
                                          ? "Meet"
                                          : "Exceed"
                                        : persenMarket <= 70
                                        ? "Below"
                                        : persenMarket <= 92
                                        ? "Meet"
                                        : "Exceed"}
                                    </b>
                                  </>
                                )}
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {/* <Row>
                    <Col
                      as="h3"
                      className="d-none d-lg-flex align-items-center "
                      style={{ color: "white" }}
                    >
                      {auth.role === "gsc"
                        ? "ISM Performance Achievements GSC"
                        : auth.role === "market"
                        ? "ISM Performance Achievements Market"
                        : auth.role === "cabang"
                        ? "ISM Performance Achievements"
                        : "ISM National Performance Achievements"} */}
                      {/* <i
                        className="fas fa-user-shield fa-5x"
                        style={{ color: "white" }}
                      ></i> */}
                      {/* </Col> */}
                      {/* <Col lg="7" xs="12">
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">
                            <h3>
                              <b style={{ color: "white" }}>Achievement</b>
                            </h3>
                          </p>
                          <Card.Title
                            as="h2"
                            style={{
                              color:
                                persenTotal < target.getTargetByMonth.target
                                  ? "#CD1818"
                                  : "#20c400",
                              fontFamily: "sans-serif",
                            }}
                          >
                            <b>{persenTotal}%</b>
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">
                            <h3>
                              <b style={{ color: "white" }}>Target</b>
                            </h3>
                          </p>
                          <Card.Title
                            as="h2"
                            style={{
                              color: "#20c400",
                              fontFamily: "sans-serif",
                            }}
                          >
                            <b>
                              {target.getTargetByMonth.target
                                ? target.getTargetByMonth.target
                                : 0}
                              %
                            </b>
                          </Card.Title>
                        </div>
                      </div>
                    </Col> */}
                      {/* <Col md="4" className="p-3 d-flex justify-content-end">
                      <Image
                        src={
                          "https://ptbap.net/assets/images/logo/invert-bap.png"
                        }
                        width="auto"
                        height="50px"
                      />
                    </Col>
                  </Row> */}
                    </Card.Body>
                  )}
                </Card>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="3">
                <h4 className="title mt-2">Start Date</h4>
                <Form.Group>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Date Picker Here",
                      readOnly: true,
                      style: {
                        cursor: "default",
                        backgroundColor: "white",
                        color: "black !important",
                      },
                    }}
                    onChange={(e) => {
                      // setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                      setDariTgl(
                        new Date(e.year(), e.month(), 1).setHours(0, 0, 0, 0)
                      );
                    }}
                    value={dariTgl}
                    initialValue={dariTgl}
                    timeFormat={false}
                    dateFormat="MMMM YYYY"
                  ></ReactDatetime>
                </Form.Group>
              </Col>
              <Col md="3">
                <h4 className="title mt-2">End Date</h4>
                <Form.Group>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Date Picker Here",
                      readOnly: true,
                      style: {
                        cursor: "default",
                        backgroundColor: "white",
                        color: "black !important",
                      },
                    }}
                    onChange={(e) => {
                      // setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                      setSmpTgl(
                        new Date(e.year(), e.month() + 1, 0).setHours(
                          23,
                          59,
                          0,
                          0
                        )
                      );
                    }}
                    value={smpTgl}
                    initialValue={smpTgl}
                    timeFormat={false}
                    dateFormat="MMMM YYYY"
                  ></ReactDatetime>
                </Form.Group>
              </Col>
              {auth.role === "cabang" ? null : (
                <Col md="3">
                  <div>
                    <Button
                      // variant="info"
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        backgroundColor: "#4c6fc8",
                        border: 0,
                        textAlign: "center",
                        marginTop: 59,
                      }}
                      onClick={(e) => {
                        auth.role === "all"
                          ? setModalExport(true)
                          : openModalExport(e);
                        setClientDropdown({
                          value: "",
                          label: "Choose the client",
                          isDisabled: true,
                        });
                      }}
                    >
                      <i class="fas fa-file-excel"></i>
                      Export Data
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
            {auth.role === "all" ? (
              <>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Row>
                          <Col md="6">
                            <Card>
                              <Card.Header style={{ marginLeft: 15 }}>
                                <Row>
                                  <div className="text-center">
                                    <i className="fas fa-industry fa-2x"></i>
                                  </div>
                                  <Card.Title
                                    as="h4"
                                    style={{ marginLeft: 30 }}
                                  >
                                    PT. Karyadibya Mahardhika (JTI - GSC)
                                  </Card.Title>
                                </Row>
                              </Card.Header>
                              <Card.Body>
                                <Row>
                                  <Col>
                                    <div className="numbers d-flex justify-content-around">
                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">
                                          Achievement
                                        </p>
                                        <Card.Title
                                          as="h5"
                                          style={{
                                            color:
                                              persenGsc <= 59
                                                ? "#CD1818"
                                                : persenGsc <= 79
                                                ? "#fcb90a"
                                                : "#20c400",
                                            fontFamily: "sans-serif",
                                          }}
                                        >
                                          {loading ? (
                                            <SkeletonTheme
                                              color="#00628f"
                                              highlightColor=""
                                              baseColor="#cbd0d2"
                                            >
                                              <Skeleton
                                                height={"100%"}
                                                width={120}
                                              />
                                            </SkeletonTheme>
                                          ) : (
                                            <>
                                              <b>{persenGsc}%</b>
                                            </>
                                          )}
                                        </Card.Title>
                                      </div>
                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">Target</p>
                                        <Card.Title
                                          as="h5"
                                          style={{
                                            color: "#20c400",
                                            fontFamily: "sans-serif",
                                          }}
                                        >
                                          {loading ? (
                                            <SkeletonTheme
                                              color="#00628f"
                                              highlightColor=""
                                              baseColor="#cbd0d2"
                                            >
                                              <Skeleton
                                                height={"100%"}
                                                width={120}
                                              />
                                            </SkeletonTheme>
                                          ) : (
                                            <>
                                              <b>80%</b>
                                            </>
                                          )}
                                        </Card.Title>
                                      </div>

                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">Status</p>
                                        <Card.Title
                                          as="h5"
                                          style={{
                                            color:
                                              persenGsc <= 70
                                                ? "#CD1818"
                                                : persenGsc <= 92
                                                ? "#fcb90a"
                                                : "#20c400",
                                            fontFamily: "sans-serif",
                                          }}
                                        >
                                          {loading ? (
                                            <SkeletonTheme
                                              color="#00628f"
                                              highlightColor=""
                                              baseColor="#cbd0d2"
                                            >
                                              <Skeleton
                                                height={"100%"}
                                                width={120}
                                              />
                                            </SkeletonTheme>
                                          ) : (
                                            <>
                                              <b>
                                                {persenGsc <= 70
                                                  ? "Below"
                                                  : persenGsc <= 92
                                                  ? "Meet"
                                                  : "Exceed"}
                                              </b>
                                            </>
                                          )}
                                        </Card.Title>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                              <Card.Footer>
                                <hr></hr>
                                <Row>
                                  <Col className="d-flex justify-content-end">
                                    <Button
                                      onClick={() => {
                                        setCardGsc(true);
                                      }}
                                      className="btn-wd btn-outline mr-1"
                                      type="button"
                                      variant="info"
                                    >
                                      Detail
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Footer>
                            </Card>
                          </Col>
                          <Col md="6">
                            <Card>
                              <Card.Header style={{ marginLeft: 15 }}>
                                <Row>
                                  <div className="text-center">
                                    <i className="fas fa-warehouse fa-2x"></i>
                                  </div>
                                  <Card.Title
                                    as="h4"
                                    style={{ marginLeft: 30 }}
                                  >
                                    PT. Karyadibya Mahardhika (JTI - Market)
                                  </Card.Title>
                                </Row>
                              </Card.Header>
                              <Card.Body>
                                <Row>
                                  {/* <Col xs="3">
                                    <div className="text-center">
                                            {val.idClient === "73" ? (
                                              <i className="fas fa-industry fa-2x"></i>
                                            ) : (
                                              <i className="fas fa-warehouse fa-2x"></i>
                                            )}
                                          </div>
                                  </Col> */}
                                  <Col>
                                    <div className="numbers d-flex justify-content-around">
                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">
                                          Achievement
                                        </p>
                                        <Card.Title
                                          as="h5"
                                          style={{
                                            color:
                                              persenMarket <= 59
                                                ? "#CD1818"
                                                : persenMarket <= 79
                                                ? "#fcb90a"
                                                : "#20c400",
                                            fontFamily: "sans-serif",
                                          }}
                                        >
                                          {loading ? (
                                            <SkeletonTheme
                                              color="#00628f"
                                              highlightColor=""
                                              baseColor="#cbd0d2"
                                            >
                                              <Skeleton
                                                height={"100%"}
                                                width={120}
                                              />
                                            </SkeletonTheme>
                                          ) : (
                                            <>
                                              <b>{persenMarket}%</b>
                                            </>
                                          )}
                                        </Card.Title>
                                      </div>
                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">Target</p>
                                        <Card.Title
                                          as="h5"
                                          style={{
                                            color: "#20c400",
                                            fontFamily: "sans-serif",
                                          }}
                                        >
                                          {loading ? (
                                            <SkeletonTheme
                                              color="#00628f"
                                              highlightColor=""
                                              baseColor="#cbd0d2"
                                            >
                                              <Skeleton
                                                height={"100%"}
                                                width={120}
                                              />
                                            </SkeletonTheme>
                                          ) : (
                                            <>
                                              <b>80%</b>
                                            </>
                                          )}
                                        </Card.Title>
                                      </div>

                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">Status</p>
                                        <Card.Title
                                          as="h5"
                                          style={{
                                            color:
                                              persenMarket <= 70
                                                ? "#CD1818"
                                                : persenMarket <= 92
                                                ? "#fcb90a"
                                                : "#20c400",
                                            fontFamily: "sans-serif",
                                          }}
                                        >
                                          {loading ? (
                                            <SkeletonTheme
                                              color="#00628f"
                                              highlightColor=""
                                              baseColor="#cbd0d2"
                                            >
                                              <Skeleton
                                                height={"100%"}
                                                width={120}
                                              />
                                            </SkeletonTheme>
                                          ) : (
                                            <>
                                              <b>
                                                {persenMarket <= 70
                                                  ? "Below"
                                                  : persenMarket <= 92
                                                  ? "Meet"
                                                  : "Exceed"}
                                              </b>
                                            </>
                                          )}
                                        </Card.Title>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                              <Card.Footer>
                                <hr></hr>
                                <Row>
                                  <Col className="d-flex justify-content-end">
                                    <Button
                                      onClick={() => {
                                        setCardMarket(true);
                                      }}
                                      className="btn-wd btn-outline mr-1"
                                      type="button"
                                      variant="info"
                                    >
                                      Detail
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Footer>
                            </Card>
                          </Col>
                          {/* {client.map((val) => {
                            return (
                              <>
                                <Col md="6">
                                  <Card>
                                    <Card.Header style={{ marginLeft: 15 }}>
                                      <Row>
                                        <div className="text-center">
                                          {val.idClient === "73" ? (
                                            <i className="fas fa-industry fa-2x"></i>
                                          ) : (
                                            <i className="fas fa-warehouse fa-2x"></i>
                                          )}
                                        </div>
                                        <Card.Title
                                          as="h4"
                                          style={{ marginLeft: 30 }}
                                        >
                                          {val.namaClient}
                                        </Card.Title>
                                      </Row>
                                    </Card.Header>
                                    <Card.Body>
                                      <Row>
                                        <Col xs="3"> */}
                          {/* <div className="text-center">
                                            {val.idClient === "73" ? (
                                              <i className="fas fa-industry fa-2x"></i>
                                            ) : (
                                              <i className="fas fa-warehouse fa-2x"></i>
                                            )}
                                          </div> */}
                          {/* </Col> */}
                          {/* <Col xs="8">
                                          <div className="numbers d-flex justify-content-around">
                                            <div className="d-flex flex-column align-items-center">
                                              <p className="card-category">
                                                Achievement
                                              </p>
                                              <Card.Title as="h5">
                                                {val.idClient === "73"
                                                  ? persenGsc
                                                  : persenMarket}
                                                %
                                              </Card.Title>
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                              <p className="card-category">
                                                Target
                                              </p>
                                              <Card.Title as="h5">
                                                {val.idClient === "73"
                                                  ? Math.round(targetGsc / 2)
                                                  : Math.round(
                                                      targetMarket / 1
                                                    )}
                                                %
                                              </Card.Title>
                                            </div>
                                          </div>
                                        </Col> */}
                          {/* </Row>
                                    </Card.Body>
                                    <Card.Footer>
                                      <hr></hr>
                                      <Row>
                                        <Col className="d-flex justify-content-end">
                                          <Button
                                            onClick={() => {
                                              if (val.idClient === "73") {
                                                setCardGsc(true);
                                              } else {
                                                setCardMarket(true);
                                              }
                                            }}
                                            className="btn-wd btn-outline mr-1"
                                            type="button"
                                            variant="info"
                                          >
                                            Detail
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Card.Footer>
                                  </Card>
                                </Col>
                              </>
                            );
                          })} */}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  {cardGsc ? (
                    <Col md="6">
                      <Card style={{ border: "0" }}>
                        <Row>
                          <Col>
                            <Row>
                              <Col className="p-3 d-flex justify-content-between align-items-center">
                                <b>GSC</b>
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  onClick={() => {
                                    setCardGsc(false);
                                  }}
                                >
                                  &times;
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row>
                          {listCabang.map((val) => {
                            if (val.idClient === "73") {
                              return (
                                <>
                                  <Col md="6">
                                    <Card>
                                      <Card.Header style={{ marginLeft: 15 }}>
                                        <Row>
                                          <div className="text-center">
                                            <i className="fas fa-industry fa-2x"></i>
                                          </div>
                                          <Card.Title
                                            as="h4"
                                            style={{ marginLeft: 25 }}
                                          >
                                            {val.namaCabang}
                                          </Card.Title>
                                        </Row>
                                      </Card.Header>
                                      <Card.Body>
                                        <Row>
                                          {/* <Col xs="3">
                                            <div className="text-center">
                                              <i className="fas fa-industry fa-2x"></i>
                                            </div>
                                          </Col> */}
                                          <Col>
                                            <div className="numbers d-flex justify-content-around">
                                              <div className="d-flex flex-column align-items-center">
                                                <p className="card-category">
                                                  Achievement
                                                </p>
                                                <Card.Title as="h5">
                                                  {loading ? (
                                                    <SkeletonTheme
                                                      color="#00628f"
                                                      highlightColor=""
                                                      baseColor="#cbd0d2"
                                                    >
                                                      <Skeleton
                                                        height={"100%"}
                                                        width={120}
                                                      />
                                                    </SkeletonTheme>
                                                  ) : (
                                                    rekap.map((val2) => {
                                                      if (
                                                        val.idCabang ===
                                                        val2.idCabang
                                                      ) {
                                                        const kpiCabang =
                                                          val2.kpiCabang;
                                                        const color =
                                                          kpiCabang <= 59
                                                            ? "#CD1818"
                                                            : kpiCabang <= 79
                                                            ? "#fcb90a"
                                                            : "#20c400";

                                                        return (
                                                          <span
                                                            key={val2.idCabang}
                                                            style={{ color }}
                                                          >
                                                            {kpiCabang}%
                                                          </span>
                                                        );
                                                      }
                                                    })
                                                  )}
                                                </Card.Title>
                                              </div>
                                              <div className="d-flex flex-column align-items-center">
                                                <p className="card-category">
                                                  Target
                                                </p>
                                                <Card.Title
                                                  as="h5"
                                                  style={{
                                                    color: "#20c400",
                                                    fontFamily: "sans-serif",
                                                  }}
                                                >
                                                  {/* {" "}
                                                  {Math.round(
                                                    val.totalTarget
                                                  )}{" "} */}
                                                  80%
                                                </Card.Title>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                      <Card.Footer>
                                        <hr></hr>
                                        <Row>
                                          <Col className="d-flex justify-content-end">
                                            <Button
                                              onClick={() => {
                                                setKirimIdCabang({
                                                  idCabang: val.idCabang,
                                                  idClient: val.idClient,
                                                  startDate: dariTgl,
                                                  endDate: smpTgl,
                                                });
                                                setDashboardUtama(false);
                                                setOpenBakalan(true);
                                              }}
                                              className="btn-wd btn-outline mr-1"
                                              type="button"
                                              variant="info"
                                            >
                                              Detail
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Card.Footer>
                                    </Card>
                                  </Col>
                                </>
                              );
                            }
                          })}
                          {/* <Col>
                            <Card>
                              <Card.Header>
                                <Card.Title as="h4">Bakalan</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <Row>
                                  <Col xs="3">
                                    <div className="text-center">
                                      <i className="fas fa-industry fa-2x"></i>
                                    </div>
                                  </Col>
                                  <Col xs="8">
                                    <div className="numbers d-flex justify-content-around">
                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">
                                          Achievement
                                        </p>
                                        <Card.Title as="h5">
                                          {monitoring.kpiPatroli
                                            ? Math.round(
                                                monitoring.kpiPatroli.pencapaian
                                              )
                                            : "100"}{" "}
                                          %
                                        </Card.Title>
                                      </div>
                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">Target</p>
                                        <Card.Title as="h5">
                                          {" "}
                                          {
                                            target.getTargetByMonthCabang
                                              .targetBakalan
                                          }{" "}
                                          %
                                        </Card.Title>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                              <Card.Footer>
                                <hr></hr>
                                <Row>
                                  <Col className="d-flex justify-content-end">
                                    <Button
                                      onClick={() => {
                                        setDashboardUtama(false);
                                        setOpenBakalan(true);
                                      }}
                                      className="btn-wd btn-outline mr-1"
                                      type="button"
                                      variant="info"
                                    >
                                      Detail
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Footer>
                            </Card>
                          </Col>
                          <Col>
                            <Card>
                              <Card.Header>
                                <Card.Title as="h4">Pelem</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <Row>
                                  <Col xs="3">
                                    <div className="text-center">
                                      <i className="fas fa-industry fa-2x"></i>
                                    </div>
                                  </Col>
                                  <Col xs="8">
                                    <div className="numbers d-flex justify-content-around">
                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">
                                          Achievement
                                        </p>
                                        <Card.Title as="h5">
                                          {monitoring.kpiPatroli
                                            ? Math.round(
                                                monitoring.kpiPatroli.pencapaian
                                              )
                                            : "100"}{" "}
                                          %
                                        </Card.Title>
                                      </div>
                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">Target</p>
                                        <Card.Title as="h5">
                                          {
                                            target.getTargetByMonthCabang
                                              .targetPelem
                                          }
                                          %
                                        </Card.Title>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                              <Card.Footer>
                                <hr></hr>
                                <Row>
                                  <Col className="d-flex justify-content-end">
                                    <Button
                                      onClick={() => {
                                        setDashboardUtama(false);
                                        setOpenPelem(true);
                                      }}
                                      className="btn-wd btn-outline mr-1"
                                      type="button"
                                      variant="info"
                                    >
                                      Detail
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Footer>
                            </Card>
                          </Col> */}
                        </Row>
                      </Card>
                    </Col>
                  ) : null}

                  {cardMarket ? (
                    <>
                      {cardGsc ? null : <Col md="6"></Col>}

                      <Col md="6">
                        <Card style={{ border: "0" }}>
                          <Row>
                            <Col>
                              <Row>
                                <Col className="p-3 d-flex justify-content-between align-items-center">
                                  <b>Market</b>
                                  <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={() => {
                                      setCardMarket(false);
                                    }}
                                  >
                                    &times;
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            {/* <Col> */}
                            {listCabang.map((val) => {
                              if (val.idClient === "143") {
                                return (
                                  <>
                                    <Col md="6">
                                      <Card>
                                        <Card.Header style={{ marginLeft: 15 }}>
                                          <Row>
                                            <Col
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <div className="text-center">
                                                <i className="fas fa-warehouse fa-2x"></i>
                                              </div>
                                              <Card.Title
                                                as="h5"
                                                style={{
                                                  marginLeft: 35,
                                                  flex: 1,
                                                  whiteSpace: "normal",
                                                  wordWrap: "break-word",
                                                }}
                                              >
                                                {val.namaCabang}
                                              </Card.Title>
                                            </Col>
                                          </Row>
                                        </Card.Header>

                                        <Card.Body>
                                          <Row>
                                            {/* <Col xs="3">
                                              <div className="text-center">
                                                <i className="fas fa-warehouse fa-2x"></i>
                                              </div>
                                            </Col> */}
                                            <Col>
                                              <div className="numbers d-flex justify-content-around">
                                                <div className="d-flex flex-column align-items-center">
                                                  <p className="card-category">
                                                    Achievement
                                                  </p>
                                                  <Card.Title as="h5">
                                                    {loading ? (
                                                      <SkeletonTheme
                                                        color="#00628f"
                                                        highlightColor=""
                                                        baseColor="#cbd0d2"
                                                      >
                                                        <Skeleton
                                                          height={"100%"}
                                                          width={120}
                                                        />
                                                      </SkeletonTheme>
                                                    ) : (
                                                      rekap.map((val2) => {
                                                        if (
                                                          val.idCabang ===
                                                          val2.idCabang
                                                        ) {
                                                          const kpiCabang =
                                                            val2.kpiCabang;
                                                          const color =
                                                            kpiCabang <= 59
                                                              ? "#CD1818"
                                                              : kpiCabang <= 79
                                                              ? "#fcb90a"
                                                              : "#20c400";

                                                          return (
                                                            <span
                                                              key={
                                                                val2.idCabang
                                                              }
                                                              style={{ color }}
                                                            >
                                                              {kpiCabang}%
                                                            </span>
                                                          );
                                                        }
                                                      })
                                                    )}
                                                  </Card.Title>
                                                </div>
                                                <div className="d-flex flex-column align-items-center">
                                                  <p className="card-category">
                                                    Target
                                                  </p>
                                                  <Card.Title
                                                    as="h5"
                                                    style={{
                                                      color: "#20c400",
                                                      fontFamily: "sans-serif",
                                                    }}
                                                  >
                                                    80%
                                                  </Card.Title>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                        <Card.Footer>
                                          <hr></hr>
                                          <Row>
                                            <Col className="d-flex justify-content-end">
                                              <Button
                                                onClick={() => {
                                                  setKirimIdCabang({
                                                    idCabang: val.idCabang,
                                                    idClient: val.idClient,
                                                    startDate: dariTgl,
                                                    endDate: smpTgl,
                                                  });
                                                  setDashboardUtama(false);
                                                  setOpenBakalan(true);
                                                }}
                                                className="btn-wd btn-outline mr-1"
                                                type="button"
                                                variant="info"
                                              >
                                                Detail
                                              </Button>
                                            </Col>
                                          </Row>
                                        </Card.Footer>
                                      </Card>
                                    </Col>
                                  </>
                                );
                              }
                            })}
                            {/* <Card>
                          <Card.Header>
                            <Card.Title as="h4">Rungkut</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col xs="3">
                                <div className="text-center">
                                  <i className="fas fa-truck fa-2x"></i>
                                </div>
                              </Col>
                              <Col xs="8">
                                <div className="numbers d-flex justify-content-around">
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Achievement</p>
                                    <Card.Title as="h5">
                                      {monitoring.kpiPatroli
                                        ? Math.round(
                                            monitoring.kpiPatroli.pencapaian
                                          )
                                        : "100"}{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Target</p>
                                    <Card.Title as="h5">
                                      {" "}
                                      {
                                        target.getTargetByMonthCabang
                                          .targetRungkut
                                      }{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer>
                            <hr></hr>
                            <Row>
                              <Col className="d-flex justify-content-end">
                                <Button
                                  onClick={() => {
                                    setDashboardUtama(false);
                                    setOpenRungkut(true);
                                  }}
                                  className="btn-wd btn-outline mr-1"
                                  type="button"
                                  variant="info"
                                >
                                  Detail
                                </Button>
                              </Col>
                            </Row>
                          </Card.Footer>
                        </Card> */}
                            {/* </Col> */}
                            {/* <Col>
                        <Card>
                          <Card.Header>
                            <Card.Title as="h4">Jakarta</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col xs="3">
                                <div className="text-center">
                                  <i className="fas fa-truck fa-2x"></i>
                                </div>
                              </Col>
                              <Col xs="8">
                                <div className="numbers d-flex justify-content-around">
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Achievement</p>
                                    <Card.Title as="h5">
                                      {monitoring.kpiPatroli
                                        ? Math.round(
                                            monitoring.kpiPatroli.pencapaian
                                          )
                                        : "100"}{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Target</p>
                                    <Card.Title as="h5">
                                      {" "}
                                      {
                                        target.getTargetByMonthCabang
                                          .targetJakarta
                                      }{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer>
                            <hr></hr>
                            <Row>
                              <Col className="d-flex justify-content-end">
                                <Button
                                  onClick={() => {
                                    setDashboardUtama(false);
                                    setOpenJakarta(true);
                                  }}
                                  className="btn-wd btn-outline mr-1"
                                  type="button"
                                  variant="info"
                                >
                                  Detail
                                </Button>
                              </Col>
                            </Row>
                          </Card.Footer>
                        </Card>
                      </Col> */}
                          </Row>
                          {/* <Row>
                      <Col>
                        <Card>
                          <Card.Header>
                            <Card.Title as="h4">Bandung</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col xs="3">
                                <div className="text-center">
                                  <i className="fas fa-truck fa-2x"></i>
                                </div>
                              </Col>
                              <Col xs="8">
                                <div className="numbers d-flex justify-content-around">
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Achievement</p>
                                    <Card.Title as="h5">
                                      {monitoring.kpiPatroli
                                        ? Math.round(
                                            monitoring.kpiPatroli.pencapaian
                                          )
                                        : "100"}{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Target</p>
                                    <Card.Title as="h5">
                                      {" "}
                                      {
                                        target.getTargetByMonthCabang
                                          .targetBandung
                                      }{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer>
                            <hr></hr>
                            <Row>
                              <Col className="d-flex justify-content-end">
                                <Button
                                  onClick={() => {
                                    setDashboardUtama(false);
                                    setOpenBandung(true);
                                  }}
                                  className="btn-wd btn-outline mr-1"
                                  type="button"
                                  variant="info"
                                >
                                  Detail
                                </Button>
                              </Col>
                            </Row>
                          </Card.Footer>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <Card.Header>
                            <Card.Title as="h4">Yogyakarta</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col xs="3">
                                <div className="text-center">
                                  <i className="fas fa-truck fa-2x"></i>
                                </div>
                              </Col>
                              <Col xs="8">
                                <div className="numbers d-flex justify-content-around">
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Achievement</p>
                                    <Card.Title as="h5">
                                      {monitoring.kpiPatroli
                                        ? Math.round(
                                            monitoring.kpiPatroli.pencapaian
                                          )
                                        : "100"}{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Target</p>
                                    <Card.Title as="h5">
                                      {" "}
                                      {
                                        target.getTargetByMonthCabang
                                          .targetYogyakarta
                                      }{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer>
                            <hr></hr>
                            <Row>
                              <Col className="d-flex justify-content-end">
                                <Button
                                  onClick={() => {
                                    setDashboardUtama(false);
                                    setOpenYogyakarta(true);
                                  }}
                                  className="btn-wd btn-outline mr-1"
                                  type="button"
                                  variant="info"
                                >
                                  Detail
                                </Button>
                              </Col>
                            </Row>
                          </Card.Footer>
                          </Col>
                        </Row> */}
                        </Card>
                      </Col>
                    </>
                  ) : null}
                </Row>
              </>
            ) : auth.role === "gsc" || auth.role === "market" ? (
              <>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Row>
                          {listCabang.map((val) => {
                            if (
                              val.idClient === "73" ||
                              val.idClient === "143"
                            ) {
                              return (
                                <>
                                  <Col md="4">
                                    <Card>
                                      <Card.Header>
                                        <Row>
                                          <Col xs="2">
                                            <div className="text-center">
                                              {val.idClient === "73" ? (
                                                <i className="fas fa-industry fa-2x"></i>
                                              ) : (
                                                <i className="fas fa-warehouse fa-2x"></i>
                                              )}
                                            </div>
                                          </Col>
                                          <Card.Title
                                            as="h4"
                                            style={{
                                              marginLeft: 10,
                                              flex: 1,
                                              whiteSpace: "normal",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {val.namaCabang}
                                          </Card.Title>
                                        </Row>
                                      </Card.Header>
                                      <Card.Body>
                                        <Row>
                                          {/* <Col xs="3">
                                            <div className="text-center">
                                              {val.idClient === "73" ? (
                                                <i className="fas fa-industry fa-2x"></i>
                                              ) : (
                                                <i className="fas fa-warehouse fa-2x"></i>
                                              )}
                                            </div>
                                          </Col> */}
                                          <Col>
                                            <div className="numbers d-flex justify-content-around">
                                              <div className="d-flex flex-column align-items-center">
                                                <p className="card-category">
                                                  Achievement
                                                </p>
                                                <Card.Title as="h5">
                                                  {loading ? (
                                                    <SkeletonTheme
                                                      color="#00628f"
                                                      highlightColor=""
                                                      baseColor="#cbd0d2"
                                                    >
                                                      <Skeleton
                                                        height={"100%"}
                                                        width={120}
                                                      />
                                                    </SkeletonTheme>
                                                  ) : (
                                                    rekap.map((val2) => {
                                                      if (
                                                        val.idCabang ===
                                                        val2.idCabang
                                                      ) {
                                                        const kpiCabang =
                                                          val2.kpiCabang;
                                                        const color =
                                                          auth.role === "gsc"
                                                            ? kpiCabang <= 59
                                                              ? "#CD1818"
                                                              : kpiCabang <= 79
                                                              ? "#fcb90a"
                                                              : "#20c400"
                                                            : kpiCabang <= 59
                                                            ? "#CD1818"
                                                            : kpiCabang <= 79
                                                            ? "#fcb90a"
                                                            : "#20c400";
                                                        // kpiCabang <= 59
                                                        //   ? "#CD1818"
                                                        //   : kpiCabang <= 79
                                                        //   ? "#fcb90a"
                                                        //   : "#20c400";

                                                        return (
                                                          <span
                                                            key={val2.idCabang}
                                                            style={{ color }}
                                                          >
                                                            {kpiCabang}%
                                                          </span>
                                                        );
                                                      }
                                                    })
                                                  )}
                                                </Card.Title>
                                              </div>
                                              <div className="d-flex flex-column align-items-center">
                                                <p className="card-category">
                                                  Target
                                                </p>
                                                <Card.Title
                                                  as="h5"
                                                  style={{
                                                    color: "#20c400",
                                                    fontFamily: "sans-serif",
                                                  }}
                                                >
                                                  {auth.role === "gsc" ? (
                                                    <b>80%</b>
                                                  ) : (
                                                    <b>80%</b>
                                                  )}
                                                </Card.Title>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                      <Card.Footer>
                                        <hr></hr>
                                        <Row>
                                          <Col className="d-flex justify-content-end">
                                            <Button
                                              onClick={() => {
                                                setKirimIdCabang({
                                                  idCabang: val.idCabang,
                                                  idClient: val.idClient,
                                                  startDate: dariTgl,
                                                  endDate: smpTgl,
                                                });
                                                setDashboardUtama(false);
                                                setOpenBakalan(true);
                                              }}
                                              className="btn-wd btn-outline mr-1"
                                              type="button"
                                              variant="info"
                                            >
                                              Detail
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Card.Footer>
                                    </Card>
                                  </Col>
                                </>
                              );
                            }
                          })}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col md="12">
                    <Card>
                      <Card.Header>
                        <Row>
                          <Col xs="1">
                            <div className="text-center">
                              <i className="fas fa-shield-alt text-primary fa-2x"></i>
                            </div>
                          </Col>
                          <Card.Title as="h4">Patrol</Card.Title>
                        </Row>
                      </Card.Header>
                      <Card.Body style={{ marginBlock: -10 }}>
                        <hr></hr>
                        <Row className="justify-content-center align-items-center">
                          {kpiPatrol?.map((val, index) => {
                            // Mengatur 3 item per baris
                            return (
                              <Col
                                key={index}
                                xs={12}
                                md={4}
                                className="d-flex justify-content-center mb-3"
                              >
                                <Card style={{ width: "18rem", margin: "2px" }}>
                                  {" "}
                                  {/* Lebar kotak diperbesar menjadi 18rem */}
                                  <Card.Header className="d-flex flex-column align-items-center">
                                    <p
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                      className="card-category"
                                    >
                                      {val.namaShift}
                                    </p>
                                  </Card.Header>
                                  <hr></hr>
                                  <Card.Body>
                                    <Row
                                      noGutters
                                      className="justify-content-center align-items-center"
                                      style={{
                                        marginTop: -20,
                                        display: "flex",
                                      }}
                                    >
                                      <Col
                                        className="p-1 d-flex justify-content-center"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 15,
                                          borderRight: "1px solid #ddd",
                                          padding: "2px 5px",
                                          height: "auto",
                                        }}
                                      >
                                        Achievement
                                      </Col>
                                      <Col
                                        className="p-1 d-flex justify-content-center"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 15,
                                          padding: "2px 5px",
                                          borderRight: "1px solid #ddd",
                                          height: "100%",
                                        }}
                                      >
                                        Target
                                      </Col>
                                      <Col
                                        className="p-1 d-flex justify-content-center"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 15,
                                          padding: "2px 5px",
                                          height: "100%",
                                        }}
                                      >
                                        Status
                                      </Col>
                                      <Col
                                        xs={4}
                                        className="p-1 d-flex justify-content-center"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 20,
                                          padding: "2px 5px",
                                          height: "100%",
                                        }}
                                      >
                                        {val["Data Patroli"]}{" "}
                                      </Col>
                                      <Col
                                        className="p-1 d-flex justify-content-center"
                                        style={{
                                          borderRight: "1px solid #ddd",
                                          borderLeft: "1px solid #ddd",
                                          fontWeight: "bold",
                                          fontSize: 20,
                                          marginLeft: 15.5,
                                          marginRight: -25,
                                          height: "100%",
                                        }}
                                      >
                                        {val["Target Patroli"]}{" "}
                                      </Col>
                                      <Col
                                        className="p-1 d-flex justify-content-center"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 15,
                                          marginLeft: 25,
                                          height: "100%",
                                          color:
                                            val.hasilPatroli === "Below"
                                              ? "red"
                                              : val.hasilPatroli === "Meet"
                                              ? "orange"
                                              : "green",
                                        }}
                                      >
                                        {val.hasilPatroli}{" "}
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      </Card.Body>

                      <Card.Footer>
                        <hr></hr>
                        <Row>
                          <Col className="d-flex justify-content-end">
                            <Button
                              onClick={() => {
                                history.push("/admin/detailPatroli");
                              }}
                              className="btn-wd btn-outline mr-1"
                              type="button"
                              variant="info"
                            >
                              Detail
                            </Button>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>
                </Row>
                <Row></Row>
              </>
            )}
          </>
        ) : null}
      </Container>
      {openBakalan ? <DashboardBakalan idCabang={kirimIdCabang} /> : null}
    </>
  );
}

export default Panels;
