import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getVisit,
  verifikasiVisit,
  tambahVisit,
  editVisit,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function ReactTables() {
  const dispatch = useDispatch();
  const date = new Date();
  const visit = useSelector((state) => state.pestRodentReducer);
  const [modal, setModal] = React.useState(false);
  const [modalVisit, setModalVisit] = React.useState(false);
  const [modalEditVisit, setModalEditVisit] = React.useState(false);
  const [dataVisit, setDataVisit] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  const [jenis, setJenis] = React.useState("");
  const [visitId, setVisitId] = React.useState("");
  const [deskripsiVisit, setDeskripsiVisit] = React.useState("");
  const [dariTgl, setDariTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
  );
  const [smpTgl, setSmpTgl] = React.useState(new Date().setHours(23, 59, 0, 0));

  function onsubmit(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("visitId", dataVisit._id);
    fd.append("respon", status);
    fd.append("alasan", deskripsi);

    verifikasiVisit(fd);
  }

  function submitVisit(e) {
    e.preventDefault();
    tambahVisit({
      jenisVisit: jenis,
      deskripsi: deskripsiVisit,
      startDate: new Date(dariTgl).toISOString(),
      deadlineDate: new Date(smpTgl).toISOString(),
    });
  }

  function edit(e) {
    e.preventDefault();
    editVisit({
      visitId: visitId,
      startDate: new Date(dariTgl).toISOString(),
      deadlineDate: new Date(smpTgl).toISOString(),
    });
  }
  const history = useHistory();
  React.useEffect(() => {
    let tmp = visit.listVisit.map((val, index) => {
      return {
        ...val,
        tglMulai: moment(val.startDate).format("DD MMM YYYY / h:mm:ss a"),
        tglSelesai: moment(val.deadlineDate).format("DD MMM YYYY / h:mm:ss a"),
        actions:
          // we've added some custom button actions
          val.status == "Received" &&
          new Date(val.deadlineDate).getTime() >= new Date(date).getTime() ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataVisit(val);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-edit fa-lg" />
              </Button>{" "}
              <Button
                onClick={() => {
                  setModal(true);
                  setDataVisit(val);
                }}
                variant="success"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : val.status == "Received" &&
            new Date(val.deadlineDate).getTime() < new Date(date).getTime() ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataVisit(val);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : val.status == "Done" &&
            new Date(val.deadlineDate).getTime() >=
              new Date(val.solvedDate).getTime() ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataVisit(val);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataVisit(val);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ),
        // val.status == "Waiting for Approval" ? (
        //   <div className="actions-right">
        //     <Button
        //       onClick={() => {
        //         setModal(true);
        //         setDataVisit(val);
        //       }}
        //       variant="warning"
        //       size="md"
        //       className="btn-link remove text-warning"
        //     >
        //       <i className="fa fa-info-circle fa-lg" />
        //     </Button>{" "}
        //   </div>
        // ) : val.status == "Rejected" ? (
        //   <div className="actions-right">
        //     <Button
        //       onClick={() => {
        //         setModal(true);
        //         setDataVisit(val);
        //       }}
        //       variant="secondary"
        //       size="md"
        //       className="btn-link remove text-secondary"
        //     >
        //       <i className="fa fa-info-circle fa-lg" />
        //     </Button>{" "}
        //   </div>
        // ) : val.status == "Received" ? (
        //   <div className="actions-right">
        //     <Button
        //       onClick={() => {
        //         setModalEditVisit(true);
        //         setVisitId(val._id);
        //       }}
        //       variant="info"
        //       size="md"
        //       className="btn-link remove text-info"
        //     >
        //       <i className="fa fa-edit fa-lg" />
        //     </Button>
        //     <Button
        //       onClick={() => {
        //         setModal(true);
        //         setDataVisit(val);
        //       }}
        //       variant="success"
        //       size="md"
        //       className="btn-link remove text-success"
        //     >
        //       <i className="fa fa-info-circle fa-lg" />
        //     </Button>{" "}
        //   </div>
        // ) : val.status == "Done" && val.solvedInTime == true ? (
        //   <div className="actions-right">
        //     <Button
        //       onClick={() => {
        //         setModal(true);
        //         setDataVisit(val);
        //       }}
        //       variant="info"
        //       size="md"
        //       className="btn-link remove text-info"
        //     >
        //       <i className="fa fa-info-circle fa-lg" />
        //     </Button>{" "}
        //   </div>
        // ) : (
        //   <div className="actions-right">
        //     <Button
        //       onClick={() => {
        //         setModal(true);
        //         setDataVisit(val);
        //       }}
        //       variant="danger"
        //       size="md"
        //       className="btn-link remove text-danger"
        //     >
        //       <i className="fa fa-info-circle fa-lg" />
        //     </Button>{" "}
        //   </div>
        // ),
      };
    });
    setData(tmp);
  }, [visit.listVisit]);

  React.useEffect(() => {
    getVisit(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalVisit(true);
          }}
          variant="primary"
        >
          Add Visit
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">Visitation</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Visit",
                      accessor: "jenisVisit",
                    },
                    {
                      Header: "Submitter",
                      accessor: "diajukanOleh",
                    },
                    {
                      Header: "Start Date",
                      accessor: "tglMulai",
                    },
                    {
                      Header: "End Date",
                      accessor: "tglSelesai",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setStatus("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataVisit.status == "Waiting for Approval" ? (
            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    <Form.Group>
                      <label>Status</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={status.val}
                        onChange={(val) => setStatus(val.value)}
                        options={[
                          { value: "Received", label: "Received" },
                          { value: "Rejected", label: "Rejected" },
                        ]}
                        placeholder="-Choose-"
                      />
                    </Form.Group>
                    {status == "Rejected" ? (
                      <Form.Group>
                        <label>Description</label>
                        <Form.Control
                          onChange={(e) => {
                            setDeskripsi(e.target.value);
                            // setProject({
                            //   ...project,
                            //   deskripsi: e.target.value,
                            // });
                          }}
                          // placeholder="Tuliskan Deskripsi Project"
                          as="textarea"
                        ></Form.Control>
                      </Form.Group>
                    ) : null}
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={onsubmit}
                    >
                      Submit
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Visit
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataVisit.jenisVisit}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Submitter
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataVisit.diajukanOleh}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Start Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataVisit.startDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        End Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataVisit.deadlineDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataVisit.deskripsi}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataVisit.status}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        On Schedule
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {dataVisit.status != "Done"
                          ? "-"
                          : dataVisit.solvedInTime
                          ? "Ya"
                          : "Tidak"}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Final Documentation
                      </Col>
                    </Row>
                    <Row>
                      {!dataVisit.buktiSelesai ||
                      dataVisit.buktiSelesai.length == 0 ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Final Documentation is Not Available
                        </Col>
                      ) : (
                        dataVisit.buktiSelesai.map((val, index) => {
                          return (
                            <>
                              <Col sm="4">
                                <Image
                                  src={val}
                                  style={{
                                    width: 200,
                                    height: 250,
                                    margin: 20,
                                  }}
                                  thumbnail
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalVisit}
        onHide={() => {
          setModalVisit(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add Visit</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Visit</label>
                          <Form.Control
                            onChange={(e) => {
                              setJenis(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Description</label>
                          <Form.Control
                            onChange={(e) => {
                              setDeskripsiVisit(e.target.value);
                              // setProject({
                              //   ...project,
                              //   deskripsi: e.target.value,
                              // });
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            as="textarea"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <label>Start Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <label>End Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                            }}
                            value={smpTgl}
                            initialValue={smpTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitVisit}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalEditVisit}
        onHide={() => {
          setModalEditVisit(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Edit</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="6">
                        <label>Start Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <label>End Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                            }}
                            value={smpTgl}
                            initialValue={smpTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={edit}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
