import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  createCsVisit,
  getCabangAktif,
  getCsVisit,
  getCsVisitBelow,
  approvalfollowup,
  followUpCsVisit,
  errorHandler,
  showUrlDokumen,
  editFollowUpCsVisit,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Swal from "sweetalert2";
import { compose } from "redux";

import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
// CSS Pagination
import "../css/Pagination.css";
// Loading Table
import { ColorRing } from "react-loader-spinner";

function ReactTables() {
  const dispatch = useDispatch();
  const date = new Date();
  const auth = useSelector((state) => state.authReducer);
  const csVisit = useSelector((state) => state.csVisitReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const [modal, setModal] = React.useState(false);
  const [modalTraining, setModalTraining] = React.useState(false);
  const [modalFollowUp, setModalFollowUp] = React.useState(false);
  const [dataReport, setDataReport] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [dataBelow, setDataBelow] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [dariTgl, setDariTgl] = React.useState(new Date().setHours(0, 0, 0, 0));
  const [tglFollowUp, setTglFollowUp] = React.useState(
    new Date().setHours(0, 0, 0, 0)
  );
  const [modalPreviewFoto, setModalPreviewFoto] = React.useState(false);
  const [gambar, setGambar] = React.useState([]);
  const [client, setClient] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  const [idTemuan, setIdTemuan] = React.useState("");
  const [namaTemuan, setNamaTemuan] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");

  const [listCabang, setListCabang] = React.useState([]);
  const [temuan, setTemuan] = React.useState([""]);
  let tanggalSekarang = new Date();

  const [idCabang, setIdCabang] = React.useState({
    value: "",
    label: "ALL",
    // isDisabled: true,
  });

  const [modalEditFollowUp, setModalEditFollowUp] = React.useState(false);
  // ============================ Pagination===============================
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [totalPerHalamanBelow, setTotalPerHalamanBelow] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [loadingBelow, setLoadingBelow] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageBelow, setPageBelow] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [loadingPreview, setLoadingPreview] = React.useState(false);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const handlePageSizeChangeBelow = (selectedOption) => {
    setTotalPerHalamanBelow(selectedOption.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleFilterChange = () => {
    setLoading(true);
    getPatroliReport(dispatch, {
      page: 1,
      limit: totalPerHalaman,
      search: searchText,
      idCabangWeb: idCabang.value,
    })
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const handleAddGambar = (e) => {
    const newFiles = Array.from(e.target.files); // Gambar baru yang dipilih
    const totalFiles = gambar.length + newFiles.length; // Total gambar jika ditambahkan

    // Cek jika total gambar lebih dari 5
    if (totalFiles <= 5) {
      setGambar([...gambar, ...newFiles]); // Menambahkan gambar baru
    } else {
      const remainingSpace = 5 - gambar.length; // Sisa slot yang tersedia
      const filesToAdd = newFiles.slice(0, remainingSpace); // Ambil gambar yang bisa ditambahkan
      setGambar([...gambar, ...filesToAdd]); // Tambahkan gambar yang bisa ditambahkan

      Swal.fire({
        icon: "warning",
        title: "Alert",
        text: "  Can only send 5 images",
      });
    }
  };

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const optionsBelow = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  // ========================= END PAGINATIN =============================

  //  ======================== TABLE =====================================

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colPencairan = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) => index + csVisit.getVisitReview.pagingCounter,
    },
    {
      name: "Submitter",
      minWidth: "100px",
      center: true,
      selector: (row) => row.dilaporkanOleh,
    },
    {
      name: "Issue Code",
      minWidth: "150px",
      center: true,
      selector: (row) => row.kodeTemuan,
    },
    {
      name: "Date",
      minWidth: "150px",
      center: true,
      selector: (row) => row.tanggal,
    },
    {
      name: "Total Issue",
      minWidth: "150px",
      center: true,
      selector: (row) => row.jumlahTemuan,
    },

    {
      name: "Status",
      minWidth: "100px",
      center: true,
      selector: (row) => row.status,
      cell: (row) => (
        <div
          style={{
            backgroundColor: row.status === "Meet" ? "#FFF1CC" : "#FFCCCC", // Warna berdasarkan status
            color: row.status === "Meet" ? "#FFA200" : "#FF0000", // Warna teks
            padding: "10px", // Jarak dalam
            fontWeight: "bold",
            borderRadius: "5px", // Membuat sudut melengkung
          }}
        >
          {row.status}
        </div>
      ),
    },
    {
      name: "Report Progress",
      minWidth: "150px",
      center: true,
      selector: (row) => row.statusLaporan,
      cell: (row) => (
        <div
          style={{
            backgroundColor:
              row.statusLaporan === "Pending"
                ? "#D3D1D1"
                : row.statusLaporan === "On Progress"
                ? "#B3B8FF"
                : "#ACE9A8", // Warna berdasarkan status
            color:
              row.statusLaporan === "Pending"
                ? "#6C6A6A"
                : row.statusLaporan === "On Progress"
                ? "#007BFF"
                : "#17B60C", // Warna teks
            padding: "10px", // Jarak dalam
            fontWeight: "bold",
            borderRadius: "5px", // Membuat sudut melengkung
          }}
        >
          {row.statusLaporan}
        </div>
      ),
    },
    {
      name: "Branch Name",
      minWidth: "300px",
      center: true,
      selector: (row) => row.namaCabang,
    },
    {
      name: "Client Name",
      minWidth: "300px",
      center: true,
      selector: (row) => row.namaClient,
    },

    {
      name: "Action",
      minWidth: "150px",
      center: true,
      selector: (row) => row.actions,
    },
  ];
  const colPencairanBelow = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) =>
        index + csVisit.getVisitReviewBelow.pagingCounter,
    },
    {
      name: "Submitter",
      minWidth: "100px",
      center: true,
      selector: (row) => row.dilaporkanOleh,
    },
    {
      name: "Issue Code",
      minWidth: "150px",
      center: true,
      selector: (row) => row.kodeTemuan,
    },
    {
      name: "Date",
      minWidth: "150px",
      center: true,
      selector: (row) => row.tanggal,
    },
    {
      name: "Total Issue",
      minWidth: "150px",
      center: true,
      selector: (row) => row.jumlahTemuan,
    },

    {
      name: "Status",
      minWidth: "100px",
      center: true,
      selector: (row) => row.status,
      cell: (row) => (
        <div
          style={{
            backgroundColor: row.status === "Meet" ? "#FFF1CC" : "#FFCCCC", // Warna berdasarkan status
            color: row.status === "Meet" ? "#FFA200" : "#FF0000", // Warna teks
            padding: "10px", // Jarak dalam
            fontWeight: "bold",
            borderRadius: "5px", // Membuat sudut melengkung
          }}
        >
          {row.status}
        </div>
      ),
    },
    {
      name: "Report Progress",
      minWidth: "150px",
      center: true,
      selector: (row) => row.statusLaporan,
      cell: (row) => (
        <div
          style={{
            backgroundColor:
              row.statusLaporan === "Pending"
                ? "#D3D1D1"
                : row.statusLaporan === "On Progress"
                ? "#B3B8FF"
                : "#ACE9A8", // Warna berdasarkan status
            color:
              row.statusLaporan === "Pending"
                ? "#6C6A6A"
                : row.statusLaporan === "On Progress"
                ? "#007BFF"
                : "#17B60C", // Warna teks
            padding: "10px", // Jarak dalam
            fontWeight: "bold",
            borderRadius: "5px", // Membuat sudut melengkung
          }}
        >
          {row.statusLaporan}
        </div>
      ),
    },
    {
      name: "Branch Name",
      minWidth: "300px",
      center: true,
      selector: (row) => row.namaCabang,
    },
    {
      name: "Client Name",
      minWidth: "300px",
      center: true,
      selector: (row) => row.namaClient,
    },

    {
      name: "Action",
      minWidth: "150px",
      center: true,
      selector: (row) => row.actions,
    },
  ];
  // ========================== END TABLE =================================

  const handleAddColom = () => {
    setTemuan([...temuan, ""]);
  };

  const handleChange = (index, event) => {
    const colomBaru = [...temuan];
    colomBaru[index] = { namaTemuan: event.target.value };
    setTemuan(colomBaru);
  };

  const handleRemoveField = (index) => {
    const colomBaru = temuan.filter((_, i) => i !== index);
    setTemuan(colomBaru);
  };
  function submitCsVisit(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to add this finding?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        if (
          !client.idClient ||
          !client.idCabang ||
          Object.values(temuan).some(
            (value) =>
              value === null ||
              value === undefined ||
              value === "" ||
              (typeof value === "object" && !Object.keys(value).length)
          )
        ) {
          Swal.fire({
            title: "Please complete the form!",
            icon: "error",
          });
        } else {
          let idCabang = client.idCabang;
          createCsVisit({
            idCabang: idCabang,
            idClient: client.idClient,
            namaCabang: client.namaCabang,
            namaClient: client.namaClient,
            tanggal: new Date(dariTgl).toISOString(),
            temuan: JSON.stringify(temuan),
          });
        }
      }
    });
  }

  function submitFollowUp(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to follow-up this issue?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        if (!deskripsi || gambar.length === 0) {
          Swal.fire({
            title: "Please complete the form!",
            icon: "error",
          });
        } else {
          let fd = new FormData();
          fd.append("_id", idTemuan);
          fd.append("deskripsi", deskripsi);
          fd.append("tglFollowUp", new Date(tglFollowUp).toISOString());
          gambar.map((val, index) => [fd.append("dokumentasiFollowUp", val)]);
          followUpCsVisit(fd)
            .then((respon) => {
              if (respon.status === 200) {
                Swal.fire({
                  title: respon.message,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                }).then((response) => {
                  getCsVisit(dispatch, {
                    page: page + 1,
                    limit: totalPerHalaman,
                    search: searchText,
                    idCabangWeb: idCabang.value,
                  });

                  getCsVisitBelow(dispatch, {
                    page: pageBelow + 1,
                    limit: totalPerHalamanBelow,
                    search: searchText,
                    idCabangWeb: idCabang.value,
                  });
                  setModalFollowUp(false);
                  setModal(false);
                  setDeskripsi("");
                  setTglFollowUp(new Date().setHours(0, 0, 0, 0));
                  setGambar([]);
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    });
  }

  function submitEditFollowUp(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to edit follow-up this issue?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        if (!deskripsi || !gambar) {
          Swal.fire({
            title: "Please complete the form!",
            icon: "error",
          });
        } else {
          let fd = new FormData();
          fd.append("_id", idTemuan);
          fd.append("deskripsi", deskripsi);
          fd.append("tglFollowUp", new Date(tglFollowUp).toISOString());
          gambar.map((val, index) => [fd.append("dokumentasiFollowUp", val)]);
          editFollowUpCsVisit(fd)
            .then((respon) => {
              if (respon.status === 200) {
                Swal.fire({
                  title: respon.message,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                }).then((response) => {
                  getCsVisit(dispatch, {
                    page: page + 1,
                    limit: totalPerHalaman,
                    search: searchText,
                    idCabangWeb: idCabang.value,
                  });

                  getCsVisitBelow(dispatch, {
                    page: pageBelow + 1,
                    limit: totalPerHalamanBelow,
                    search: searchText,
                    idCabangWeb: idCabang.value,
                  });
                  setModalFollowUp(false);
                  setModalEditFollowUp(false);
                  setModal(false);
                  setDeskripsi("");
                  setTglFollowUp(new Date().setHours(0, 0, 0, 0));
                  setGambar([]);
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    });
  }

  function handleOpenModal(e) {
    setClient({
      value: "",
      label: "Choose the Client",
      isDisabled: true,
    });
    setGambar([]);
    setDariTgl(new Date());
  }

  React.useEffect(() => {
    getCabangAktif(dispatch);
  }, []);

  React.useEffect(() => {
    setLoading(true);
    getCsVisit(dispatch, {
      page: page + 1,
      limit: totalPerHalaman,
      search: searchText,
      idCabangWeb: idCabang.value,
    }).then((response) => {
      setLoading(false);
    });
  }, [totalPerHalaman, page, idCabang]);

  React.useEffect(() => {
    setLoadingBelow(true);
    getCsVisitBelow(dispatch, {
      page: pageBelow + 1,
      limit: totalPerHalamanBelow,
      search: searchText,
      idCabangWeb: idCabang.value,
    }).then((response) => {
      setLoadingBelow(false);
    });
  }, [totalPerHalamanBelow, pageBelow, idCabang]);

  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabang.getCabangAktif]);

  // All Report
  React.useEffect(() => {
    let tmp =
      csVisit.getVisitReview.data &&
      csVisit.getVisitReview.data.map((val, index) => {
        return {
          ...val,
          tanggal: moment(val.tanggal).format("DD MMM YYYY"),
          actions: (
            <div className="actions-right">
              <Button
                style={{ border: 0, outline: "none", boxShadow: "none" }}
                onClick={async () => {
                  setModal(true);
                  setDataReport(val);

                  // Show Image
                  setLoadingPreview(true);
                  setTimeout(() => {
                    setLoadingPreview(false);
                  }, 2000);

                  const urlImageData = val.Temuan.flatMap((temuan) => {
                    // Jika dokumentasiFollowUp ada dan merupakan array, map datanya
                    if (
                      Array.isArray(temuan.dokumentasiFollowUp) &&
                      temuan.dokumentasiFollowUp.length > 0
                    ) {
                      return temuan.dokumentasiFollowUp.map((img) => ({
                        idDokumentasi: temuan._id,
                        imgUrl: img,
                      }));
                    }

                    // Jika dokumentasiFollowUp tidak ada atau kosong, tetap tambahkan data idDokumentasi
                    return [
                      {
                        idDokumentasi: temuan._id,
                        imgUrl: "", // Atau gunakan string kosong jika perlu
                      },
                    ];
                  });

                  // Map buktiLaporan untuk mendapatkan URL gambar

                  try {
                    const filterUrl = urlImageData.filter(
                      (data) => data.imgUrl
                    );
                    const formatUrl = {
                      urlImage: filterUrl.map((data) => ({
                        idDokumentasi: data.idDokumentasi,
                        imageUrl: data.imgUrl,
                      })),
                    };
                    const response = await showUrlDokumen(dispatch, formatUrl);
                    if (response?.data?.url) {
                      // Perbarui dataReport dengan URL dari respons
                      setDataReport((prevData) => ({
                        ...prevData,
                        urlPreview: response.data.url, // Sesuaikan key jika berbeda
                      }));
                    }
                  } catch (error) {
                    console.error("Error fetching document URL:", error);
                  }
                }}
                // variant="danger"
                size="md"
                className="btn-link remove text-success no-border-btn"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ),
        };
      });
    setData(tmp);
  }, [csVisit.getVisitReview]);

  // Report Below
  React.useEffect(() => {
    let tmp =
      csVisit.getVisitReviewBelow.data &&
      csVisit.getVisitReviewBelow.data.map((val, index) => {
        return {
          ...val,
          tanggal: moment(val.tanggal).format("DD MMM YYYY"),
          actions: (
            <div className="actions-right">
              <Button
                style={{ border: 0, outline: "none", boxShadow: "none" }}
                onClick={async () => {
                  setModal(true);
                  setDataReport(val);

                  // Show Image
                  setLoadingPreview(true);
                  setTimeout(() => {
                    setLoadingPreview(false);
                  }, 2000);

                  const urlImageData = val.Temuan.flatMap((temuan) => {
                    // Jika dokumentasiFollowUp ada dan merupakan array, map datanya
                    if (
                      Array.isArray(temuan.dokumentasiFollowUp) &&
                      temuan.dokumentasiFollowUp.length > 0
                    ) {
                      return temuan.dokumentasiFollowUp.map((img) => ({
                        idDokumentasi: temuan._id,
                        imgUrl: img,
                      }));
                    }

                    // Jika dokumentasiFollowUp tidak ada atau kosong, tetap tambahkan data idDokumentasi
                    return [
                      {
                        idDokumentasi: temuan._id,
                        imgUrl: "", // Atau gunakan string kosong jika perlu
                      },
                    ];
                  });

                  // Map buktiLaporan untuk mendapatkan URL gambar

                  try {
                    const filterUrl = urlImageData.filter(
                      (data) => data.imgUrl
                    );
                    const formatUrl = {
                      urlImage: filterUrl.map((data) => ({
                        idDokumentasi: data.idDokumentasi,
                        imageUrl: data.imgUrl,
                      })),
                    };
                    const response = await showUrlDokumen(dispatch, formatUrl);
                    if (response?.data?.url) {
                      // Perbarui dataReport dengan URL dari respons
                      setDataReport((prevData) => ({
                        ...prevData,
                        urlPreview: response.data.url, // Sesuaikan key jika berbeda
                      }));
                    }
                  } catch (error) {
                    console.error("Error fetching document URL:", error);
                  }
                }}
                // variant="danger"
                size="md"
                className="btn-link remove text-success no-border-btn"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ),
        };
      });
    setDataBelow(tmp);
  }, [csVisit.getVisitReviewBelow]);
  // =========================== Approval ======================================
  // function approvalfollowup(data) {

  // }

  // ========================================================================

  return (
    <>
      <style jsx>{`
        .btn-icon-hover {
          transition: transform 0.3s ease, color 0.3s ease;
        }

        .btn-icon-hover:hover {
          transform: scale(1.2); /* Membesarkan ikon */
          color: #17a2b8; /* Mengubah warna ikon */
        }

        .btn-icon-hover i {
          transition: color 0.3s ease; /* Animasi perubahan warna */
        }

        .btn-icon-hover:hover i {
          color: #0056b3; /* Mengubah warna ikon saat hover */
        }
      `}</style>
      <div
        id="previewImage"
        style={{
          display: modalPreviewFoto ? "block" : "none",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: "9999",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalPreviewFoto}
          alt="Preview"
          style={{
            borderRadius: "5px",
            boxShadow: "4px 4px 8px 0px rgba(0,0,0,0.75)",
            maxHeight: "80%",
            maxWidth: "80%",
            objectFit: "contain",
            background: "transparent",
            position: "absolute",
            top: "50%", // Posisi vertikal ke tengah
            left: "50%", // Posisi horizontal ke tengah
            transform: "translate(-50%, -50%)",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => setModalPreviewFoto("")}
        >
          &times;
        </button>
      </div>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        {auth.perusahaan === "JTI" || auth.perusahaan === "all" ? (
          <Button
            className="btn-wd mr-1"
            onClick={() => {
              setModalTraining(true);
              handleOpenModal();
            }}
            variant="primary"
          >
            Add Findings
          </Button>
        ) : null}

        <Row>
          {/* Tabel Status Below */}
          <Col md="12">
            <h4 className="title">CS Visit & Findings Recapitulation</h4>
            <h4 className="title">Report Requires Action</h4>
            <Card>
              <Card.Body>
                {loadingBelow ? (
                  <div
                    className="loader-container"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[
                        "#394978",
                        "#394978",
                        "#394978",
                        "#394978",
                        "#394978",
                      ]}
                    />
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 19,
                      }}
                    ></p>
                  </div>
                ) : (
                  <DataTable
                    columns={colPencairanBelow}
                    data={dataBelow}
                    // {listPencairan.filter(
                    //   (row) =>
                    //     row.id_pinjaman
                    //       .toLowerCase()
                    //       .includes(searchText.toLowerCase()) ||
                    //     row.namaLengkap
                    //       .toLowerCase()
                    //       .includes(searchText.toLowerCase())
                    // )}
                    noDataComponent={
                      <div
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          color: "#888",
                        }}
                      >
                        There are no records to display
                      </div>
                    }
                    customStyles={customStyles}
                    paginationPerPage={totalPerHalamanBelow}
                    progressPending={loadingBelow}
                    persistTableHead
                  />
                )}

                <div style={{ marginTop: 35 }}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col>
                      <Row style={{ alignItems: "center" }}>
                        <div
                          style={{
                            paddingRight: 0,
                            marginLeft: 20,
                            marginRight: 10,
                          }}
                        >
                          <label style={{ fontWeight: "bold" }}>
                            Rows per page
                          </label>
                        </div>
                        <Col sm="3" style={{ paddingLeft: 0 }}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="Pilih Client"
                            value={optionsBelow.find(
                              (option) => option.value === totalPerHalamanBelow
                            )}
                            onChange={handlePageSizeChangeBelow}
                            options={optionsBelow}
                            placeholder="Rows per page"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <ReactPaginate
                        forcePage={pageBelow}
                        containerClassName="paginations justify-content-end"
                        previousClassName="pages-items-containers"
                        previousLinkClassName="pages-links-labels"
                        nextClassName="pages-items-containers"
                        nextLinkClassName="pages-links-labels"
                        breakClassName="pages-items-containers"
                        pageCount={csVisit.getVisitReviewBelow.totalPages}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageLinkClassName="pages-links-labels"
                        pageClassName="pages-items-containers"
                        activeClassName="actives"
                        activeLinkClassName="activess"
                        nextLabel=" > "
                        previousLabel=" < "
                        // initialPage={"page"}
                        disableInitialCallback={true}
                        onPageChange={(val) => {
                          setPageBelow(val.selected);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* ALL Record */}

          <Col md="12">
            <h4 className="title">All Report</h4>
            <Card>
              <Card.Body>
                {loading ? (
                  <div
                    className="loader-container"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[
                        "#394978",
                        "#394978",
                        "#394978",
                        "#394978",
                        "#394978",
                      ]}
                    />
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 19,
                      }}
                    ></p>
                  </div>
                ) : (
                  <DataTable
                    columns={colPencairan}
                    data={data}
                    // {listPencairan.filter(
                    //   (row) =>
                    //     row.id_pinjaman
                    //       .toLowerCase()
                    //       .includes(searchText.toLowerCase()) ||
                    //     row.namaLengkap
                    //       .toLowerCase()
                    //       .includes(searchText.toLowerCase())
                    // )}
                    noDataComponent={
                      <div
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          color: "#888",
                        }}
                      >
                        There are no records to display
                      </div>
                    }
                    customStyles={customStyles}
                    paginationPerPage={totalPerHalaman}
                    progressPending={loading}
                    persistTableHead
                  />
                )}

                <div style={{ marginTop: 35 }}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col>
                      <Row style={{ alignItems: "center" }}>
                        <div
                          style={{
                            paddingRight: 0,
                            marginLeft: 20,
                            marginRight: 10,
                          }}
                        >
                          <label style={{ fontWeight: "bold" }}>
                            Rows per page
                          </label>
                        </div>
                        <Col sm="3" style={{ paddingLeft: 0 }}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="Pilih Client"
                            value={options.find(
                              (option) => option.value === totalPerHalaman
                            )}
                            onChange={handlePageSizeChange}
                            options={options}
                            placeholder="Rows per page"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <ReactPaginate
                        forcePage={page}
                        containerClassName="paginations justify-content-end"
                        previousClassName="pages-items-containers"
                        previousLinkClassName="pages-links-labels"
                        nextClassName="pages-items-containers"
                        nextLinkClassName="pages-links-labels"
                        breakClassName="pages-items-containers"
                        pageCount={csVisit.getVisitReview.totalPages}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageLinkClassName="pages-links-labels"
                        pageClassName="pages-items-containers"
                        activeClassName="actives"
                        activeLinkClassName="activess"
                        nextLabel=" > "
                        previousLabel=" < "
                        // initialPage={"page"}
                        disableInitialCallback={true}
                        onPageChange={(val) => {
                          setPage(val.selected);
                        }}
                      />
                    </Col>
                  </Row>
                </div>

                {/* <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Submitter",
                      accessor: "dilaporkanOleh",
                    },
                    {
                      Header: "Date",
                      accessor: "tanggal",
                    },
                    {
                      Header: "Total Issues",
                      accessor: "jumlahTemuan",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },

                    {
                      Header: "Branch Name",
                      accessor: "namaCabang",
                    },
                    {
                      Header: "Client Name",
                      accessor: "namaClient",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                /> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setStatus("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <div style={{ maxHeight: "800px", overflowY: "scroll" }}>
          <Modal.Body>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Submitter
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.dilaporkanOleh}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Issue Code
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.kodeTemuan}</Col>
                      </Row>

                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Reported Date
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {moment(dataReport.tanggal).format("DD MMM YYYY")}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Total Issue Date
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.jumlahTemuan}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Status
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.status}</Col>
                      </Row>

                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Branch
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.namaCabang}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Client
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataReport.namaClient}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          List Issues
                        </Col>
                      </Row>

                      {dataReport.Temuan?.map((val, index) => {
                        return (
                          <>
                            {auth.perusahaan === "JTI" ? (
                              <>
                                {dataReport.status === "Meet" ? (
                                  <>
                                    <Row key={index}>
                                      <Col
                                        sm="5"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Issues {index + 1}
                                      </Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">{val.namaTemuan}</Col>
                                    </Row>
                                  </>
                                ) : (
                                  <>
                                    <Row
                                      key={index}
                                      style={{
                                        marginTop: index == 0 ? 0 : 25,
                                      }}
                                    >
                                      <Col
                                        sm="5"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Issue {index + 1}
                                      </Col>
                                      <Col sm="0.5"></Col>
                                      <Col sm="6">
                                        {val.approval === "Approved" ||
                                        val.approval ===
                                          "Rejected" ? null : val.followUp ===
                                          false ? null : val.followUp ===
                                          true ? (
                                          <Button
                                            className="btn-sm mr-2"
                                            style={{
                                              backgroundColor: "#DF9007",
                                              border: "#DF9007",
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              Swal.fire({
                                                title:
                                                  "Do you want to approve this request?",
                                                icon: "question",
                                                showCancelButton: true,
                                                showDenyButton: true,
                                                allowOutsideClick: false,
                                                confirmButtonText: "Approve",
                                                denyButtonText: "Reject",
                                                confirmButtonColor: "#1BE600",
                                                denyButtonColor: "#FF0000",
                                                allowOutsideClick: () =>
                                                  !Swal.isLoading(),
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  Swal.fire({
                                                    title: "Loading ...",
                                                    didOpen() {
                                                      Swal.showLoading();
                                                    },
                                                    allowOutsideClick: false,
                                                    allowEscapeKey: false,
                                                    allowEnterKey: false,
                                                  });
                                                  approvalfollowup({
                                                    _id: val._id,
                                                    approval: "Approved",
                                                    revised: "Revised",
                                                  })
                                                    .then((respon) => {
                                                      if (
                                                        respon.status === 200
                                                      ) {
                                                        Swal.fire({
                                                          title: respon.message,
                                                          icon: "success",
                                                          timer: 1500,
                                                          showConfirmButton: false,
                                                        }).then((response) => {
                                                          // getCsVisit(dispatch);
                                                          getCsVisit(dispatch, {
                                                            page: page + 1,
                                                            limit:
                                                              totalPerHalaman,
                                                            search: searchText,
                                                            idCabangWeb:
                                                              idCabang.value,
                                                          });

                                                          getCsVisitBelow(
                                                            dispatch,
                                                            {
                                                              page:
                                                                pageBelow + 1,
                                                              limit:
                                                                totalPerHalamanBelow,
                                                              search:
                                                                searchText,
                                                              idCabangWeb:
                                                                idCabang.value,
                                                            }
                                                          );
                                                          setModal(false);
                                                        });
                                                      }
                                                    })
                                                    .catch((err) => {
                                                      console.log(err);
                                                    });
                                                } else if (result.isDenied) {
                                                  approvalfollowup({
                                                    _id: val._id,
                                                    approval: "Rejected",
                                                    revised: "Need Revision",
                                                  })
                                                    .then((respon) => {
                                                      if (
                                                        respon.status === 200
                                                      ) {
                                                        Swal.fire({
                                                          title: respon.message,
                                                          icon: "success",
                                                          timer: 1500,
                                                          showConfirmButton: false,
                                                        }).then((response) => {
                                                          getCsVisit(dispatch, {
                                                            page: page + 1,
                                                            limit:
                                                              totalPerHalaman,
                                                            search: searchText,
                                                            idCabangWeb:
                                                              idCabang.value,
                                                          });

                                                          getCsVisitBelow(
                                                            dispatch,
                                                            {
                                                              page:
                                                                pageBelow + 1,
                                                              limit:
                                                                totalPerHalamanBelow,
                                                              search:
                                                                searchText,
                                                              idCabangWeb:
                                                                idCabang.value,
                                                            }
                                                          );
                                                          setModal(false);
                                                        });
                                                      }
                                                    })
                                                    .catch((err) => {
                                                      console.log(err);
                                                    });
                                                }
                                              });
                                            }}
                                          >
                                            Approval
                                          </Button>
                                        ) : null}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col sm="5">Issue Status</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        <Col
                                          sm="6"
                                          style={{
                                            backgroundColor:
                                              val.approval === "Initial Report"
                                                ? "#D4D4D4"
                                                : val.approval ===
                                                  "Waiting for Approval"
                                                ? "#FFF1CC"
                                                : val.approval === "Rejected"
                                                ? "#FFCCCC"
                                                : "#ACE9A8", // Warna berdasarkan status
                                            color:
                                              val.approval === "Initial Report"
                                                ? "#707070"
                                                : val.approval ===
                                                  "Waiting for Approval"
                                                ? "#FFAE00"
                                                : val.approval === "Rejected"
                                                ? "#FF0000"
                                                : "#17B60C", // Warna berdasarkan status
                                            padding: "1px", // Jarak dalam
                                            fontWeight: "bold",
                                            borderRadius: "5px", // Membuat sudut melengkung
                                            textAlign: "center",
                                            marginLeft: 0,
                                            fontSize: 14,
                                          }}
                                        >
                                          {val.approval}
                                        </Col>
                                      </Col>

                                      <Col sm="5">Issue Name</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">{val.namaTemuan}</Col>
                                      <Col sm="5">Reviewer</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        {val.reviewer ? val.reviewer : "-"}
                                      </Col>
                                      <Col sm="5">Follow-Up</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        {val.followUp === false ? "No" : "Yes"}
                                      </Col>
                                      <Col sm="5">Follow-Up By</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        {val.followUpBy ? val.followUpBy : "-"}
                                      </Col>
                                      <Col sm="5">Date Follow-Up</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        {val.tanggalFollowUp
                                          ? moment(val.tanggalFollowUp).format(
                                              "DD MMM YYYY"
                                            )
                                          : "-"}
                                      </Col>

                                      <Col sm="5">Description</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        {!val.deskripsi ? "-" : val.deskripsi}
                                      </Col>
                                      <Col sm="5">Documentation</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        {dataReport?.urlPreview?.filter(
                                          (id) => id.idDokumentasi === val._id
                                        ).length == 0 ? (
                                          <Col
                                            sm="12"
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            Final Documentation is Not Available
                                          </Col>
                                        ) : loadingPreview ? (
                                          <div
                                            className="loader-container"
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              textAlign: "center",
                                              width: "100%",
                                            }}
                                          >
                                            <ColorRing
                                              visible={true}
                                              height="80"
                                              width="80"
                                              ariaLabel="color-ring-loading"
                                              wrapperStyle={{}}
                                              wrapperClass="color-ring-wrapper"
                                              colors={[
                                                "#6c757d",
                                                "#6c757d",
                                                "#6c757d",
                                                "#6c757d",
                                                "#6c757d",
                                              ]}
                                            />
                                            <p
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: 19,
                                              }}
                                            ></p>
                                          </div>
                                        ) : (
                                          dataReport.urlPreview
                                            .filter(
                                              (id) =>
                                                id.idDokumentasi === val._id
                                            )
                                            .map((img) => (
                                              <Col
                                                sm="8"
                                                key={img.idDokumentasi}
                                              >
                                                <Image
                                                  src={img.url}
                                                  style={{
                                                    width: 200,
                                                    height: 250,
                                                    margin: 20,
                                                  }}
                                                  thumbnail
                                                  onClick={() => {
                                                    setModalPreviewFoto(
                                                      img.url
                                                    );
                                                  }}
                                                />
                                              </Col>
                                            ))
                                        )}
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {dataReport.status === "Meet" ? (
                                  <>
                                    <Row key={index}>
                                      <Col
                                        sm="5"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Issues {index + 1}
                                      </Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">{val.namaTemuan}</Col>
                                    </Row>
                                  </>
                                ) : (
                                  <>
                                    <Row key={index}>
                                      <Col
                                        sm="5"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Issue {index + 1}
                                      </Col>
                                      <Col sm="0.5"></Col>
                                      <Col sm="6">
                                        {val.approval ===
                                        "Approved" ? null : val.approval ===
                                          "Rejected" ? (
                                          <Button
                                            className="btn-sm mr-2"
                                            style={{
                                              backgroundColor: "#FFB619",
                                              border: "#FFB619",
                                            }}
                                            onClick={() => {
                                              setModalEditFollowUp(true);
                                              setNamaTemuan(val.namaTemuan);
                                              setIdTemuan(val._id);
                                              // setGambar(
                                              //   val.dokumentasiFollowUp
                                              // );
                                              setDeskripsi(val.deskripsi);
                                            }}
                                          >
                                            Edit Follow-Up
                                          </Button>
                                        ) : val.followUp === false ? (
                                          <Button
                                            className="btn-sm mr-2"
                                            onClick={() => {
                                              setModalFollowUp(true);
                                              setNamaTemuan(val.namaTemuan);
                                              setIdTemuan(val._id);
                                            }}
                                            variant="primary"
                                          >
                                            Follow-Up
                                          </Button>
                                        ) : val.followUp ===
                                          true ? null : dataReport.status ===
                                          "Below" ? (
                                          <Button
                                            className="btn-sm mr-2"
                                            onClick={() => {
                                              setModalFollowUp(true);
                                              setNamaTemuan(val.namaTemuan);
                                              setIdTemuan(val._id);
                                            }}
                                            variant="primary"
                                          >
                                            Follow-Up
                                          </Button>
                                        ) : null}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col sm="5">Issue Status</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        <Col
                                          sm="6"
                                          style={{
                                            backgroundColor:
                                              val.approval === "Initial Report"
                                                ? "#D4D4D4"
                                                : val.approval ===
                                                  "Waiting for Approval"
                                                ? "#FFF1CC"
                                                : val.approval === "Rejected"
                                                ? "#FFCCCC"
                                                : "#ACE9A8", // Warna berdasarkan status
                                            color:
                                              val.approval === "Initial Report"
                                                ? "#707070"
                                                : val.approval ===
                                                  "Waiting for Approval"
                                                ? "#FFAE00"
                                                : val.approval === "Rejected"
                                                ? "#FF0000"
                                                : "#17B60C", // Warna berdasarkan status
                                            padding: "1px", // Jarak dalam
                                            fontWeight: "bold",
                                            borderRadius: "5px", // Membuat sudut melengkung
                                            textAlign: "center",
                                            marginLeft: 0,
                                            fontSize: 14,
                                          }}
                                        >
                                          {val.approval}
                                        </Col>
                                      </Col>

                                      <Col sm="5">Issue Name</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">{val.namaTemuan}</Col>
                                      <Col sm="5">Reviewer</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        {val.reviewer ? val.reviewer : "-"}
                                      </Col>
                                      <Col sm="5">Follow-Up</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        {val.followUp === false ? "No" : "Yes"}
                                      </Col>
                                      <Col sm="5">Follow-Up By</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        {val.followUpBy ? val.followUpBy : "-"}
                                      </Col>
                                      <Col sm="5">Date Follow-Up</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        {val.tanggalFollowUp
                                          ? moment(val.tanggalFollowUp).format(
                                              "DD MMM YYYY"
                                            )
                                          : "-"}
                                      </Col>
                                      <Col sm="5">Description</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        {!val.deskripsi ? "-" : val.deskripsi}
                                      </Col>
                                      <Col sm="5">Documentation</Col>
                                      <Col sm="0.5">:</Col>
                                      <Col sm="6">
                                        {dataReport?.urlPreview?.filter(
                                          (id) => id.idDokumentasi === val._id
                                        ).length == 0 ? (
                                          <Col
                                            sm="12"
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            Final Documentation is Not Available
                                          </Col>
                                        ) : loadingPreview ? (
                                          <div
                                            className="loader-container"
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              textAlign: "center",
                                              width: "100%",
                                            }}
                                          >
                                            <ColorRing
                                              visible={true}
                                              height="80"
                                              width="80"
                                              ariaLabel="color-ring-loading"
                                              wrapperStyle={{}}
                                              wrapperClass="color-ring-wrapper"
                                              colors={[
                                                "#6c757d",
                                                "#6c757d",
                                                "#6c757d",
                                                "#6c757d",
                                                "#6c757d",
                                              ]}
                                            />
                                            <p
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: 19,
                                              }}
                                            ></p>
                                          </div>
                                        ) : (
                                          dataReport.urlPreview
                                            .filter(
                                              (id) =>
                                                id.idDokumentasi === val._id
                                            )
                                            .map((img) => (
                                              <Col
                                                sm="8"
                                                key={img.idDokumentasi}
                                              >
                                                <Image
                                                  src={img.url}
                                                  style={{
                                                    width: 200,
                                                    height: 250,
                                                    margin: 20,
                                                  }}
                                                  thumbnail
                                                  onClick={() => {
                                                    setModalPreviewFoto(
                                                      img.url
                                                    );
                                                  }}
                                                />
                                              </Col>
                                            ))
                                        )}
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        );
                      })}
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        size="lg"
        show={modalTraining}
        onHide={() => {
          setModalTraining(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Add Findings</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Client's Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setClient(value);
                            }}
                            value={client}
                            options={[
                              {
                                value: "",
                                label: "Choose the Client",
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate());
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col sm="8">
                        <Form.Group>
                          <label>Issues 1</label>
                          <Form.Control
                            value={temuan[0]?.namaTemuan}
                            onChange={(e) => handleChange(0, e)}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                      <Col sm="4" className="tex-right mt-4">
                        <Button
                          variant="link"
                          className="btn-icon-hover"
                          onClick={handleAddColom}
                        >
                          <i class=" text-info fas fa-plus fa-2x"></i>
                        </Button>
                      </Col>
                    </Row>
                    {temuan.slice(1).map((field, index) => (
                      <Row key={index + 1} className="align-items-center">
                        <Col sm="8">
                          <Form.Group>
                            <label>Issues {index + 2}</label>
                            <Form.Control
                              value={field.namaTemuan}
                              onChange={(e) => handleChange(index + 1, e)}
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col sm="4" className="tex-right mt-4">
                          <Button
                            variant="link"
                            className="btn-icon-hover"
                            onClick={() => handleRemoveField(index + 1)}
                          >
                            <i class=" text-danger fas fa-minus fa-2x fa-2x"></i>
                          </Button>
                        </Col>
                      </Row>
                    ))}

                    <div className="clearfix"></div>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitCsVisit}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={modalFollowUp}
        backdrop="static"
        keyboard={false}
        animation={true}
        onHide={() => {
          setModalFollowUp(false);
          setDeskripsi("");
          setTglFollowUp(new Date().setHours(0, 0, 0, 0));
          setGambar([]);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Follow-Up Issue</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="5" style={{ fontWeight: "bold" }}>
                        Name Issue
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="6">{namaTemuan}</Col>
                    </Row>
                    <Row>
                      <Col sm="5" style={{ fontWeight: "bold" }}>
                        Date Issue
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="6">
                        {moment(dataReport.tanggal).format("DD MMM YYYY")}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="5" style={{ fontWeight: "bold" }}>
                        Date Follow Up
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="6">
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setTglFollowUp(e.toDate());
                            }}
                            value={tglFollowUp}
                            initialValue={tglFollowUp}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="5" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="6">
                        <Form.Control
                          onChange={(e) => {
                            setDeskripsi(e.target.value);
                            // setProject({
                            //   ...project,
                            //   deskripsi: e.target.value,
                            // });
                          }}
                          // placeholder="Tuliskan Deskripsi Project"
                          as="textarea"
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                      <Col sm="5" style={{ fontWeight: "bold" }}>
                        Documentation
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="6">
                        {gambar.length < 5 ? (
                          <label
                            className="label-input-file btn btn-danger text-white mb-4"
                            for="import2"
                          >
                            Add Photo
                            <input
                              type="file"
                              id="import2"
                              onClick={(e) => {
                                e.target.value = "";
                              }}
                              onChange={handleAddGambar}
                              style={{ display: "none" }}
                              accept="image/*"
                              multiple
                            />
                          </label>
                        ) : (
                          <p className="text-muted">
                            Maximum 5 photos have been added.
                          </p>
                        )}

                        {gambar.slice(0, 5).map((val, index) => {
                          return (
                            <div
                              className="d-flex align-items-start"
                              key={index}
                            >
                              <p>{val.name}</p>
                              <Button
                                onClick={() => {
                                  setGambar([
                                    ...gambar.slice(0, index),
                                    ...gambar.slice(index + 1, gambar.length),
                                  ]);
                                }}
                                variant="danger"
                                size="sm"
                                className="btn-link remove text-danger"
                              >
                                <i className="fa fa-times" />
                              </Button>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                    <div className="clearfix"></div>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitFollowUp}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit Follow UP */}
      <Modal
        size="lg"
        show={modalEditFollowUp}
        backdrop="static"
        keyboard={false}
        animation={true}
        onHide={() => {
          setModalEditFollowUp(false);
          setDeskripsi("");
          setTglFollowUp(new Date().setHours(0, 0, 0, 0));
          setGambar([]);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Edit Follow-Up Issue</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="5" style={{ fontWeight: "bold" }}>
                        Name Issue
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="6">{namaTemuan}</Col>
                    </Row>
                    <Row>
                      <Col sm="5" style={{ fontWeight: "bold" }}>
                        Date Issue
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="6">
                        {moment(dataReport.tanggal).format("DD MMM YYYY")}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="5" style={{ fontWeight: "bold" }}>
                        Date Follow Up
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="6">
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setTglFollowUp(e.toDate());
                            }}
                            value={tglFollowUp}
                            initialValue={tglFollowUp}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="5" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="6">
                        <Form.Control
                          onChange={(e) => {
                            setDeskripsi(e.target.value);
                          }}
                          value={deskripsi}
                          as="textarea"
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                      <Col sm="5" style={{ fontWeight: "bold" }}>
                        Documentation
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="6">
                        {gambar.length < 5 ? (
                          <label
                            className="label-input-file btn btn-danger text-white mb-4"
                            for="import2"
                          >
                            Add Photo
                            <input
                              type="file"
                              id="import2"
                              onClick={(e) => {
                                e.target.value = "";
                              }}
                              onChange={handleAddGambar}
                              style={{ display: "none" }}
                              accept="image/*"
                              multiple
                            />
                          </label>
                        ) : (
                          <p className="text-muted">
                            Maximum 5 photos have been added.
                          </p>
                        )}

                        {gambar.slice(0, 5).map((val, index) => {
                          return (
                            <div
                              className="d-flex align-items-start"
                              key={index}
                            >
                              <p>{val.name}</p>
                              <Button
                                onClick={() => {
                                  setGambar([
                                    ...gambar.slice(0, index),
                                    ...gambar.slice(index + 1, gambar.length),
                                  ]);
                                }}
                                variant="danger"
                                size="sm"
                                className="btn-link remove text-danger"
                              >
                                <i className="fa fa-times" />
                              </Button>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                    <div className="clearfix"></div>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitEditFollowUp}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
