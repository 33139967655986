import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { getProposal, verifikasiProposal } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function ReactTables() {
  const dispatch = useDispatch();
  const monitoring = useSelector((state) => state.monitoringReducer);
  const [modal, setModal] = React.useState(false);
  const [proposal, setProposal] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  function onsubmit(e) {
    e.preventDefault();
    // let fd = new FormData();
    // fd.append("idProposal", proposal._id);
    // fd.append("status", status);
    // fd.append("alasan", deskripsi);

    verifikasiProposal({
      idProposal: proposal._id,
      status: status,
    });
  }

  const history = useHistory();
  React.useEffect(() => {
    let tmp = monitoring.proposal.map((val, index) => {
      return {
        ...val,
        actions:
          // we've added some custom button actions
          val.status == "Waiting for Approval" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setProposal(val);
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : val.status == "Rejected" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setProposal(val);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setProposal(val);
                }}
                variant="success"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ),
      };
    });
    setData(tmp);
  }, [monitoring.proposal]);
  //   React.useEffect(() => {
  //     getAreaNonGroup(dispatch);
  //   }, []);

  React.useEffect(() => {
    getProposal(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Row>
          <Col md="12">
            <h4 className="title">Propoal</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Kategori",
                      accessor: "kategory",
                    },
                    {
                      Header: "Judul",
                      accessor: "judul",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setStatus("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail Proposal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {proposal.status == "Waiting for Approval" ? (
            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    <Form.Group>
                      <label>Pilih Status</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={status.val}
                        onChange={(val) => setStatus(val.value)}
                        options={[
                          { value: "Received", label: "Received" },
                          { value: "Rejected", label: "Rejected" },
                        ]}
                        placeholder="Pilih Status"
                      />
                    </Form.Group>
                    {/* {status == "Rejected" ? (
                      <Form.Group>
                        <label>Deskripsi</label>
                        <Form.Control
                          onChange={(e) => {
                            setDeskripsi(e.target.value);
                            // setProject({
                            //   ...project,
                            //   deskripsi: e.target.value,
                            // });
                          }}
                          placeholder="Tuliskan Deskripsi Project"
                          as="textarea"
                        ></Form.Control>
                      </Form.Group>
                    ) : null} */}

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={onsubmit}
                    >
                      Submit
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  {/* <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Detail Problem</Card.Title>
                    </Card.Header>
                  </Card.Header> */}
                  <Card.Body>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Kategori
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{proposal.kategory}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Judul
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{proposal.judul}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{proposal.status}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Deskripsi
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{proposal.deskripsi}</Col>
                    </Row>

                    {/* <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        setModal(false);
                        e.preventDefault();
                      }}
                    >
                      Close
                    </Button> */}
                    {/* <div className="clearfix"></div> */}
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;
